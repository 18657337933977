import { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Render from "../../../../helpers/internal/render";
import Text from "../../../components/text";
import Paragraphs from "../../../components/paragraphs";

const PageComponent = memo(({ data }) => {
  const node = data && data.optionalNode && data.optionalNode("458a4b0b-cc1f-4c62-927f-1b2af8bba88f");
  return (
    <Page className="demo text ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Text</Text>
          <Paragraphs className="paragraphs">
            {/* Usually, this would come from data, but for the demo to be server-independent some demo HTML is inlined here */}
            {node ? node.wantedText("Description") : "Not Connected to Blur.Base"}
          </Paragraphs>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

const protectedPage = Render.protect((c) => c.props.data)(PageComponent);
export default connect((state) => ({ language: state.language, data: state.data }))(protectedPage);
