const FlagsActions = {
  TOGGLE_DEBUG_MODE: "@@local/TOGGLE_DEBUG_MODE",
  toggleDebugMode: function () {
    return { type: FlagsActions.TOGGLE_DEBUG_MODE };
  },
  TOGGLE_BOUNDS_MODE: "@@local/TOGGLE_BOUNDS_MODE",
  toggleBoundsMode: function () {
    return { type: FlagsActions.TOGGLE_BOUNDS_MODE };
  },
  TOGGLE_DATA_AUTO_RELOAD: "@@local/TOGGLE_DATA_AUTO_RELOAD",
  toggleDataAutoReload: function () {
    return { type: FlagsActions.TOGGLE_DATA_AUTO_RELOAD };
  },
  TOGGLE_PATH_BAR: "@@local/TOGGLE_PATH_BAR",
  togglePathBar: function () {
    return { type: FlagsActions.TOGGLE_PATH_BAR };
  },
  ENABLE_EDITING: "@@local/ENABLE_EDITING",
  enableEditing: function () {
    return { type: FlagsActions.ENABLE_EDITING };
  },
  DISABLE_EDITING: "@@local/DISABLE_EDITING",
  disableEditing: function () {
    return { type: FlagsActions.DISABLE_EDITING };
  },
  UPDATE_MODIFIED: "@@local/UPDATE_MODIFIED",
  setModified: function (modified) {
    return { type: FlagsActions.UPDATE_MODIFIED, modified };
  },
};

export default FlagsActions;
