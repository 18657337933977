import { memo, useCallback, useState } from "react";
import PropTypes from "prop-types";

import Page from "../../../components/page";
import Navigator from "../../../../helpers/navigator";
import Button from "../../../components/button";
import Image from "../../../components/image";
import { Stagger, StaggerStep } from "../../../components/stagger";
import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Revealer from "../../../components/revealer";
import resource from "../../../../helpers/resource";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

// NOTE: These examples use StaggerContext and StaggerSteps directly, which makes for quite verbose (and less semantic)
// markup. To avoid the need for <StaggerStep> containers, use the HOC syntax in CSS-in-JS and pass `stepOptions` to
// stagger step-wrapped components. Also, the need for a `StaggerContext` can be eliminated if in a parent that
// already has a stagger context, such as a `Page` or `Revealer`. Check out the Ripple.Base main page for concrete
// examples.

const DemoStaggeredContent = memo(({ staggerOptions, differentTransitions, fixedOrder, simultaneousOrder, delays }) => {
  return (
    <div className="staggered normal-content">
      <Stagger options={staggerOptions}>
        <StaggerStep
          name="image"
          transition={simultaneousOrder ? "slide-in-and-fade-from-left" : differentTransitions ? "photo-drop" : null}
          order={simultaneousOrder ? 1 : null}
          delayAfter={delays ? 1000 : null}
        >
          <Image src={resource("images/demo/stagger-image.jpg")} />
        </StaggerStep>
        <StaggerStep
          name="paragraph"
          transition={simultaneousOrder ? "slide-in-and-fade-from-left" : null}
          order={simultaneousOrder ? 1 : null}
          delayAfter={delays ? 300 : null}
        >
          <p>
            Quae cum dixisset paulumque institisset, Quid est? Atque his de rebus et splendida est eorum et illustris
            oratio non enim iam stirpis bonum.
          </p>
        </StaggerStep>
        <ul>
          <StaggerStep
            name="bullet-1"
            transition={differentTransitions || simultaneousOrder ? "slide-in-and-fade-from-left" : null}
            order={simultaneousOrder ? 2 : fixedOrder ? 3 : null}
          >
            <li>Lorem ipsum dolor sit amet</li>
          </StaggerStep>
          <StaggerStep
            name="bullet-2"
            transition={differentTransitions || simultaneousOrder ? "slide-in-and-fade-from-right" : null}
            order={simultaneousOrder ? 2 : fixedOrder ? 2 : null}
          >
            <li>Bonum negas esse divitias, praeposìtum</li>
          </StaggerStep>
          <StaggerStep
            name="bullet-3"
            transition={differentTransitions || simultaneousOrder ? "slide-in-and-fade-from-left" : null}
            order={simultaneousOrder ? 2 : fixedOrder ? 1 : null}
          >
            <li>Comprehensum, quod cognitum non habet</li>
          </StaggerStep>
        </ul>
      </Stagger>
    </div>
  );
});

DemoStaggeredContent.propTypes = {
  staggerOptions: PropTypes.object.isRequired,
  differentTransitions: PropTypes.bool,
  fixedOrder: PropTypes.bool,
  simultaneousOrder: PropTypes.bool,
  delays: PropTypes.bool,
};

DemoStaggeredContent.defaultProp = {
  differentTransitions: false,
};

const DemoEmbeddedStaggeredContent = memo(({ staggerOptions, customOrdering, random, customInterval }) => {
  return (
    <div className="staggered embedded-content">
      <Stagger options={staggerOptions}>
        <StaggerStep name="A" transition="slide-in-and-out-from-top">
          <div className="panel">
            <StaggerStep
              name="B"
              order={customOrdering ? 1 : null}
              delayBefore={500}
              transition="slide-in-and-fade-from-left"
              sort={random ? "random" : null}
            >
              <div className="section top">
                <StaggerStep name="C" order={customOrdering && !random ? 2 : null}>
                  <div className="pill">1</div>
                </StaggerStep>
                <StaggerStep
                  name="D"
                  order={customOrdering && !random ? 1 : null}
                  delayBefore={customOrdering && !random ? 400 : null}
                >
                  <div className="pill">2</div>
                </StaggerStep>
                <StaggerStep name="E" order={customOrdering && !random ? 2 : null}>
                  <div className="pill">3</div>
                </StaggerStep>
                <StaggerStep name="F" order={customOrdering && !random ? 1 : null}>
                  <div className="pill">4</div>
                </StaggerStep>
              </div>
            </StaggerStep>
            <StaggerStep
              name="G"
              order={customOrdering ? 1 : null}
              transition="slide-in-and-fade-from-right"
              sort={random ? "random" : null}
              interval={customInterval ? 500 : null}
            >
              <div className="section bottom">
                <StaggerStep
                  name="H"
                  order={customOrdering && !random ? 1 : null}
                  delayBefore={customOrdering && !random ? 200 : null}
                >
                  <div className="pill">1</div>
                </StaggerStep>
                <StaggerStep name="I" order={customOrdering && !random ? 2 : null}>
                  <div className="pill">2</div>
                </StaggerStep>
                <StaggerStep name="J" order={customOrdering && !random ? 1 : null}>
                  <div className="pill">3</div>
                </StaggerStep>
                <StaggerStep name="K" order={customOrdering && !random ? 2 : null}>
                  <div className="pill">4</div>
                </StaggerStep>
              </div>
            </StaggerStep>
          </div>
        </StaggerStep>
      </Stagger>
    </div>
  );
});

DemoEmbeddedStaggeredContent.propTypes = {
  staggerOptions: PropTypes.object,
  customOrdering: PropTypes.bool,
  customInterval: PropTypes.bool,
  random: PropTypes.bool,
};

const DemoRevealerContent = memo(({ staggerOptions, reveal }) => {
  return (
    <div className="staggered normal-content">
      <Revealer staggerOptions={staggerOptions} reveal={reveal} transition="slide-in-and-out-from-top">
        <StaggerStep name="image" delayAfter={250}>
          <Image src={resource("images/demo/stagger-image.jpg")} />
        </StaggerStep>
        <StaggerStep name="paragraph">
          <p>
            Quae cum dixisset paulumque institisset, Quid est? Atque his de rebus et splendida est eorum et illustris
            oratio non enim iam stirpis bonum.
          </p>
        </StaggerStep>
        <ul>
          <StaggerStep name="bullet-1">
            <li>Lorem ipsum dolor sit amet</li>
          </StaggerStep>
          <StaggerStep name="bullet-2">
            <li>Bonum negas esse divitias, praeposìtum</li>
          </StaggerStep>
          <StaggerStep name="bullet-3">
            <li>Comprehensum, quod cognitum non habet</li>
          </StaggerStep>
        </ul>
      </Revealer>
    </div>
  );
});

DemoRevealerContent.propTypes = {
  staggerOptions: PropTypes.object.isRequired,
  reveal: PropTypes.bool.isRequired,
};

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const onTransitionDemoLinkClick = useCallback(() => Navigator.navigate({ path: "/transitions-demo" }), []);

  const [refreshContent1, setRefreshContent1] = useState(0);
  const onRefreshContent1 = useCallback(() => setRefreshContent1((r) => r + 1), []);

  const [refreshContent2, setRefreshContent2] = useState(0);
  const onRefreshContent2 = useCallback(() => setRefreshContent2((r) => r + 1), []);

  const [refreshContent3, setRefreshContent3] = useState(0);
  const onRefreshContent3 = useCallback(() => setRefreshContent3((r) => r + 1), []);

  const [refreshContent4, setRefreshContent4] = useState(0);
  const onRefreshContent4 = useCallback(() => setRefreshContent4((r) => r + 1), []);

  const [refreshContent5, setRefreshContent5] = useState(0);
  const onRefreshContent5 = useCallback(() => setRefreshContent5((r) => r + 1), []);

  const [refreshContent6, setRefreshContent6] = useState(0);
  const onRefreshContent6 = useCallback(() => setRefreshContent6((r) => r + 1), []);

  const [refreshContent7, setRefreshContent7] = useState(0);
  const onRefreshContent7 = useCallback(() => setRefreshContent7((r) => r + 1), []);

  const [refreshContent8, setRefreshContent8] = useState(0);
  const onRefreshContent8 = useCallback(() => setRefreshContent8((r) => r + 1), []);

  const [refreshContent9, setRefreshContent9] = useState(0);
  const onRefreshContent9 = useCallback(() => setRefreshContent9((r) => r + 1), []);

  const [refreshContent10, setRefreshContent10] = useState(0);
  const onRefreshContent10 = useCallback(() => setRefreshContent10((r) => r + 1), []);

  const [refreshContent11, setRefreshContent11] = useState(0);
  const onRefreshContent11 = useCallback(() => setRefreshContent11((r) => r + 1), []);

  return (
    <Page className="demo stagger ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          scrollbarSideInset={3}
          startFadeRatio={0.1}
          endFadeRatio={0.04}
        >
          <Text className="title">Stagger</Text>
          <p className="standard description">
            The Stagger API allows automatically animating the appearance of multiple components (of any type) in
            sequence, with minimal effort. Any of the built-in Ripple CSS transitions can be used as stagger step
            transitions (see the <a onClick={onTransitionDemoLinkClick}>Transitions Demo</a>). Each step can use a
            different transition, can have custom before and after delays specified, and more. Steps run in the stagger
            provided by the closest <code>StaggerContext</code>-providing parent (such as a <code>Page</code> or{" "}
            <code>Revealer</code>), which means you can, for example, have a panel whose content is staggered in a page
            that staggers its own children.
          </p>
          <div className="cases">
            <div className="case">
              <h2 className="standard">Default Settings</h2>
              <p className="standard">This is how elements appear when staggered with default settings.</p>
              <DemoStaggeredContent staggerOptions={{ name: "default", debug: true }} key={refreshContent1} />
              <Button className="standard" onClick={onRefreshContent1}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Multiple Transitions</h2>
              <p className="standard">Each step can have a different transition.</p>
              <DemoStaggeredContent
                staggerOptions={{ name: "multiple", debug: true }}
                differentTransitions={true}
                key={refreshContent2}
              />
              <Button className="standard" onClick={onRefreshContent2}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Random Sort</h2>
              <p className="standard">The API supports different sort modes.</p>
              <DemoStaggeredContent
                staggerOptions={{ name: "random", debug: true, sort: "random" }}
                key={refreshContent3}
              />
              <Button className="standard" onClick={onRefreshContent3}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Fixed Order</h2>
              <p className="standard">
                Steps can be ordered through their <code>order</code> prop to customize the order in which they appear.
              </p>
              <DemoStaggeredContent
                staggerOptions={{ name: "fixed", debug: true }}
                fixedOrder={true}
                key={refreshContent4}
              />
              <Button className="standard" onClick={onRefreshContent4}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Simultaneous</h2>
              <p className="standard">Steps can appear simultaneously if they have the same order value.</p>
              <DemoStaggeredContent
                staggerOptions={{ name: "simultaneous", debug: true }}
                simultaneousOrder={true}
                key={refreshContent5}
              />
              <Button className="standard" onClick={onRefreshContent5}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Delays</h2>
              <p className="standard">Each step can specify delays to wait before and after itself in the sequence.</p>
              <DemoStaggeredContent
                staggerOptions={{ name: "delays", debug: true }}
                delays={true}
                key={refreshContent6}
              />
              <Button className="standard" onClick={onRefreshContent6}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Embedded &mdash; Default</h2>
              <p className="standard">
                Stagger steps can be embedded inside other steps for interesting reveal effects.
              </p>
              <DemoEmbeddedStaggeredContent staggerOptions={{ name: "embedded", debug: true }} key={refreshContent7} />
              <Button className="standard" onClick={onRefreshContent7}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Embedded &mdash; Order</h2>
              <p className="standard">Embedded stagger steps can specify custom ordering for even more control.</p>
              <DemoEmbeddedStaggeredContent
                customOrdering={true}
                staggerOptions={{ name: "complex", debug: true, interval: 500 }}
                key={refreshContent8}
              />
              <Button className="standard" onClick={onRefreshContent8}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Embedded &mdash; Sort</h2>
              <p className="standard">
                A custom sort order (<code>random</code> or <code>sequential</code>) can be specified per step, which
                affects the step&apos;s children.
              </p>
              <DemoEmbeddedStaggeredContent
                complex={true}
                random={true}
                staggerOptions={{ name: "complex", debug: true, interval: 250 }}
                key={refreshContent9}
              />
              <Button className="standard" onClick={onRefreshContent9}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Embedded &mdash; Interval</h2>
              <p className="standard">Each step can specify the animation interval for its children.</p>
              <DemoEmbeddedStaggeredContent
                customInterval={true}
                staggerOptions={{ name: "embedded", debug: true }}
                key={refreshContent10}
              />
              <Button className="standard" onClick={onRefreshContent10}>
                Animate
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Revealer</h2>
              <p className="standard">
                The <code>Revealer</code> component can be used to manually reveal or hide content on demand.
              </p>
              <DemoRevealerContent
                staggerOptions={{ name: "revealer", debug: true }}
                reveal={refreshContent11 % 2 === 0}
              />
              <Button className="standard" onClick={onRefreshContent11}>
                Toggle
              </Button>
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
