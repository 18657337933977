import _ from "lodash";

function roundWithDecimalPlaces(value, places) {
  switch (places) {
    case 0:
      return Math.round(value);
    case 1:
      return Math.round(value * 10) / 10;
    case 2:
      return Math.round(value * 100) / 100;
    default:
      throw new Error("roundWithDecimalPlaces: unsupported places value!");
  }
}

const AnalyticsHelper = {
  getSessions: function (events) {
    // - This code assumes that the events are pre-sorted in ascending order based on their time
    // - We also assume that lodash's groupBy function preserves ordering in groups:
    //   https://github.com/lodash/lodash/commit/e120d63ee253021e1d640af086ef8f0c4f4fb64b

    const rawSessions = _.values(_.groupBy(events, (e) => e.sessionId));

    return rawSessions;
  },
  formatAsDuration: function (milliseconds) {
    const seconds = (milliseconds / 1000).toFixed(1);
    const minutes = (milliseconds / (1000 * 60)).toFixed(1);
    const hours = (milliseconds / (1000 * 60 * 60)).toFixed(1);
    const days = (milliseconds / (1000 * 60 * 60 * 24)).toFixed(1);

    if (seconds < 60) {
      return seconds + "s";
    } else if (minutes < 60) {
      return minutes + "m";
    } else if (hours < 24) {
      return hours + "h";
    } else {
      return days + "d";
    }
  },
  formatAsPercentage: function (ratio, places = 0) {
    return roundWithDecimalPlaces(ratio * 100, places) + "%";
  },
  firstPathComponent: function (path) {
    if (!path) return null;
    return path.split("/").filter((c) => c !== "")[0] || null;
  },
};

export default AnalyticsHelper;
