import Data from "../../../model/data";
import DataActions from "../../actions/local/data-actions";

const dataReducer = function (state = null, action) {
  switch (action.type) {
    case DataActions.DATA_RECEIVE:
      return new Data(action.data, action.clientId);
    case DataActions.DATA_LOAD_LOCAL:
      return new Data(action.data, action.clientId);
    case DataActions.DATA_LOAD_STATIC:
      return action.data;
    case DataActions.DATA_LOAD_NONE:
      return {};
    default:
      return state;
  }
};

export default dataReducer;
