import { Button, Media, Revealer, staggerStep, VideoPlayer } from "ripple";
import styled from "styled-components";
import { accent01Color } from "../../common";

export const RootRevealer = styled(Revealer)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  z-index: 100000;

  /* Overriding this to force the revealer to be clickable, */
  /* else it inherits the pointer-events: none of the overlay */
  pointer-events: ${(props) => (props.reveal ? "auto !important" : "none !important")};
`;

export const PositionedImage = staggerStep({})(
  styled(Media)`
    flex-grow: 1;
  `
);

export const PositionedVideoPlayer = staggerStep({})(
  styled(VideoPlayer).attrs((props) => ({ autoPlay: true }))`
    flex-grow: 1;
    background-color: transparent;

    .controls {
      padding: 0;
      margin: 2vmin;
      background-color: transparent;
      border: none;
      .ripple-button {
        margin: 0 2vw;
      }
      .progress-bar {
        margin: 0;
      }
      .track {
        height: 1.5vmin;
        background-color: rgba(0, 0, 0, 0.5);
        border: 0.05vmin solid white;
      }
      .progress {
        background-color: ${accent01Color};
      }
      .play-button {
        font-size: 3vmin;
        color: ${accent01Color};
        .hotspot {
          margin: -4vmin;
        }
      }
      .time-label {
        margin: 0;
        margin-left: 2vw;
        font-size: 2vmin;
      }
    }
  `
);

export const CloseButton = staggerStep({ order: 0 })(
  styled(Button)`
    position: absolute;
    top: 2vh;
    right: 2vh;
    width: 6vh;
    height: 6vh;

    background-color: ${accent01Color};
    border-radius: 50%;
    padding: 1.2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hotspot {
      margin: -2vh;
    }

    &.clicked {
      transition: all 100ms ease-in-out;
      transform: scale3d(0.8, 0.8, 1);
    }
  `
);

export const MediaContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
`;
