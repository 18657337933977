import { transparentize } from "polished";
import { Button, Image, Page as RipplePage, Panorama, resource, Revealer, Text } from "ripple";
import styled from "styled-components";
import { accent01Color, StandardButton } from "../../common";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FullscreenPanorama = styled(Panorama)`
  width: 100%;
  height: 100%;
`;

export const PinContainer = styled.div`
  position: absolute; /* It's positioned from the top left */
  transform: translate3d(-50%, -100%, 0); /* But shifted to align with the pin anchor */
  width: 100vw; /* The absolutely-positioned container has the same width as the screen */
  pointer-events: none; /* But is itself unclickable */

  transition: opacity 200ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PinButton = styled(Button)`
  pointer-events: auto !important;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1vh 2vh;
  border-radius: 1vh;
  transition: border 1s ease-in-out;
  border: 0.5vh solid
    ${(props) => (props.viewed ? transparentize(0.05, "#868780") : transparentize(0.05, accent01Color))};
  outline: 0.5vh solid ${transparentize(0.05, "#d6d1c8")};
  outline-offset: -1vh;
`;

export const PinText = styled(Text)`
  font-family: "RobotoCondensed-Bold";
  font-size: 2vh;
  text-align: center;
  color: white;
`;

export const PinArrow = styled(Image).attrs((props) => ({ src: resource("images/Symbole_Arrow_01.svg") }))`
  transform: translate3d(0, -0.5vh, 0) rotate3d(0, 0, 1, 90deg) scale3d(0.8, 0.8, 1);
  margin-top: 4px;
  height: 30px;
  svg path {
    transition: fill 1s ease-in-out;
    fill: ${(props) => (props.viewed ? "#868780" : accent01Color)};
  }
`;

export const PopupRevealer = styled(Revealer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.73) 0%, rgba(0, 0, 0, 0.63) 100%);
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Tutorial = styled.div`
  position: absolute;
  max-width: 50vmin;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: none;

  z-index: 10000;
  transform: translate3d(0, 0, 0); /* Potential fix for rare z-index issue on Chrome where pins appear above tutorial */
`;

export const BottomLeftButton = styled(StandardButton)`
  position: absolute;
  bottom: calc(3vmin + env(safe-area-inset-bottom));
  left: calc(3vmin + env(safe-area-inset-right));
`;

export const BottomRightButton = styled(StandardButton)`
  position: absolute;
  bottom: calc(3vmin + env(safe-area-inset-bottom));
  right: calc(3vmin + env(safe-area-inset-right));
`;

export const MuteButton = styled(Button)`
  position: absolute;
  top: calc(3vmin + env(safe-area-inset-top));
  right: calc(3vmin + env(safe-area-inset-right));
  width: 5vh;
  height: 5vh;

  .hotspot {
    margin: calc(-3vmin - env(safe-area-inset-top));
  }
`;
