import { Html } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import PropTypes from "prop-types";
import { memo, Suspense, useCallback, useContext, useRef } from "react";
import * as THREE from "three";
import RippleContext from "../../../core/ripple-context";
import Classes from "../../../helpers/classes";
import Clipboard from "../../../helpers/clipboard";
import Coordinates from "../../../helpers/coordinates";
import Toast from "../../../helpers/toast";
import MediaInfo from "../../../logic/info/media-info";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { useDebug } from "../../hooks/use-debug";
import ImageMaterial from "./image-material";
import Pin from "./pin";
import VideoMaterial from "./video-material";

const Dome = ({ src, muted }) => {
  const { gl, camera } = useThree();

  const url = src instanceof MediaInfo ? src.url : src;
  const isVideo =
    (src instanceof MediaInfo && src.type === "video") || (typeof src === "string" && src.endsWith("mp4"));

  const domeMeshRef = useRef(null);

  const onClick = useCallback(
    (event) => {
      const altKey = event.altKey;
      if (!altKey) return;

      const mouse = new THREE.Vector2(
        (event.clientX / gl.domElement.clientWidth) * 2 - 1,
        -(event.clientY / gl.domElement.clientHeight) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObject(domeMeshRef.current);
      if (intersects.length === 0) return;

      const { theta, phi } = Coordinates.cartesianToSpherical(
        intersects[0].point.x,
        intersects[0].point.y,
        intersects[0].point.z
      );

      Clipboard.copy(JSON.stringify({ theta, phi }));
      Toast.info("Position copied to clipboard!");
    },
    [camera, gl.domElement.clientHeight, gl.domElement.clientWidth]
  );

  return (
    <mesh ref={domeMeshRef} onClick={onClick}>
      <sphereBufferGeometry attach="geometry" args={[500, 60, 40]} />
      {isVideo ? <VideoMaterial url={url} muted={muted} /> : <ImageMaterial url={url} />}
    </mesh>
  );
};

Dome.propTypes = {
  src: MediaSrcPropType,
  muted: PropTypes.bool,
};

const Scene = ({ src, muted, children }) => {
  const debug = useDebug();
  const { camera, gl } = useThree();

  return (
    <>
      <orbitControls
        target={[0, 0, 0]}
        args={[camera, gl.domElement]}
        rotateSpeed={-0.25}
        enableZoom={false}
        enablePan={false}
      />
      {debug && <axesHelper />}
      <Dome src={src} muted={muted} />
      {children}
    </>
  );
};

Scene.propTypes = {
  src: MediaSrcPropType,
  muted: PropTypes.bool,
  children: PropTypes.node,
};

const Panorama = memo(({ className, src, muted, children, ...rest }) => {
  const rippleContext = useContext(RippleContext);

  return (
    <div {...rest} className={Classes.build("ripple-panorama", className)}>
      <Canvas camera={{ position: [0, 0, 0.1] }}>
        <RippleContext.Provider value={rippleContext}>
          <Suspense
            fallback={
              <Html center>
                <div className="panorama-spinner" />
              </Html>
            }
          >
            <Scene src={src} muted={muted}>
              {children}
            </Scene>
          </Suspense>
        </RippleContext.Provider>
      </Canvas>
    </div>
  );
});

Panorama.propTypes = {
  className: PropTypes.string,
  src: MediaSrcPropType,
  muted: PropTypes.bool,
  children: PropTypes.node,
};

Panorama.Pin = Pin;

export default Panorama;
