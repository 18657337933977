import ProgressActions from "../../actions/local/progress-actions";

const progressReducer = function (state = null, action) {
  switch (action.type) {
    case ProgressActions.UPDATE_PROGRESS:
      return action.progress;
    default:
      return state;
  }
};

export default progressReducer;
