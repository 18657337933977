import _ from "lodash";
import { memo, useState, useCallback, useRef } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import { useConstant } from "../../../hooks/use-constant";
import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Selector from "../../../components/selector";
import Button from "../../../components/button";
import Classes from "../../../../helpers/classes";
import Text from "../../../components/text";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");
  const selectorRef = useRef(null);
  const [itemIndex, setItemIndex] = useState(0);
  const [itemCount, setItemCount] = useState(10);
  const items = _.range(0, itemCount);
  const [wrap, setWrap] = useState(false);
  const [viewSideItems, setViewSideItems] = useState(false);
  const [springConfig, setSpringConfig] = useState("stiff");

  const scalingFunctions = useConstant(() => ({
    "Unfocused Down": Selector.scale.unfocused(0.1),
    "Unfocused Up": Selector.scale.unfocused(1.5),
    Descending: Selector.scale.descending(0.8),
    Ascending: Selector.scale.ascending(0.8),
    Custom: (scrollIndex, focusedIndex) =>
      // Paste this into Grapher (Mac) to preview the curve: y = 1 - 0.1 sin(20x)
      1 - /* Amplitude */ 0.1 * Math.sin((scrollIndex - focusedIndex) * /* Frequency */ 20),
    Off: Selector.scale.off(),
  }));
  const [currentScalingFunctionKey, setCurrentScalingFunctionKey] = useState(_.keys(scalingFunctions)[0]);

  const rotationFunctions = useConstant(() => ({
    "Asc Y 90": Selector.rotation.ascending(0, 1, 0, 90),
    "Asc Y 360": Selector.rotation.ascending(0, 1, 0, 360),
    "Asc X 80": Selector.rotation.ascending(1, 0, 0, 80),
    "Desc X 80": Selector.rotation.descending(1, 0, 0, 80),
    "Unfocused X 80": Selector.rotation.unfocused(1, 0, 0, 80),
    "Unfocused Y 90": Selector.rotation.unfocused(0, 1, 0, 90),
    "Unfocused X Y 120": Selector.rotation.unfocused(1, 1, 0, 120),
    "Asc Z 180": Selector.rotation.ascending(0, 0, 1, 180),
    "Asc Z 180 Unclamped": Selector.rotation.ascending(0, 0, 1, 180, { clamp: false }),
    Off: Selector.rotation.off(),
  }));
  const [currentRotationFunctionKey, setCurrentRotationFunctionKey] = useState(_.keys(rotationFunctions)[0]);

  const opacifyFunctions = useConstant(() => ({
    "Unfocused 0": Selector.opacity.unfocused(0),
    "Unfocused 0.8": Selector.opacity.unfocused(0.8),
    Off: Selector.opacity.off(),
  }));
  const [currentOpacifyFunctionKey, setCurrentOpacifyFunctionKey] = useState("Off");

  const createOnScalingModeButtonClick = (index) => () => setCurrentScalingFunctionKey(index);
  const createOnRotationModeButtonClick = (index) => () => setCurrentRotationFunctionKey(index);
  const createOnOpacifyModeButtonClick = (index) => () => setCurrentOpacifyFunctionKey(index);
  const createOnButtonClick = (index) => () => alert(`Button ${index} clicked!`);
  const createOnWrapButtonClick = (boolean) => () => setWrap(boolean);
  const createOnJumpButtonClick = (index) => () => selectorRef.current.jumpTo(index);
  const createOnItemCountButtonClick = (count) => () => setItemCount(count);
  const createOnSpringButtonClick = (config) => () => setSpringConfig(config);
  const createViewSideItemsButtonClick = (index) => () => setViewSideItems(index);
  const onIndexChange = useCallback((index) => setItemIndex(index), []);
  const onPreviousClick = useCallback(() => selectorRef.current.previous(), []);
  const onNextClick = useCallback(() => selectorRef.current.next(), []);

  return (
    <Page className="demo selector ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Selector</Text>

          <p className="standard description">
            The <code>Selector</code> component allows swiping between children as distinct &quot;pages&quot;. It
            supports wrapping, jumping to specific items and navigation using external buttons, provides animation
            customization options and supports an unlimited number of items with no performance drop.
          </p>

          <p className="standard description lighter">
            <span className="label">Item:</span> {itemIndex + 1}
          </p>

          <div className="selector-container">
            <Button className="navigation previous" onClick={onPreviousClick} />
            <Selector
              ref={selectorRef}
              className="main"
              items={items}
              sideItemCount={2} // To avoid revealing empty items when swiping too quickly with buttons
              springConfig={springConfig}
              wrap={wrap}
              itemDynamicScale={scalingFunctions[currentScalingFunctionKey]}
              itemDynamicRotation={rotationFunctions[currentRotationFunctionKey]}
              itemDynamicOpacity={opacifyFunctions[currentOpacifyFunctionKey]}
              onIndexChange={onIndexChange}
              style={{ transform: viewSideItems ? "scale3d(0.3, 0.3, 0.3)" : "none" }}
            >
              {(item, index) => (
                <div key={index} className="item">
                  <Button className="standard item-button" onClick={createOnButtonClick(index + 1)}>
                    {`Click Button ${index + 1}`}
                  </Button>
                  <div className="bottom">
                    <Scroller scrollbarInset={10}>
                      {_.map(_.range(0, 10), (index) => (
                        <div key={index} className={`subitem style${(index % 2) + 1}`}>
                          {index + 1}
                        </div>
                      ))}
                    </Scroller>
                    <Selector items={items} initialIndex={4} wrap={true} orientation="vertical" swipeThreshold={50}>
                      {(index) => (
                        <div key={index} className={`subitem style${(index % 2) + 1}`}>
                          {index + 1}
                        </div>
                      )}
                    </Selector>
                  </div>
                </div>
              )}
            </Selector>
            <Button className="navigation next" onClick={onNextClick} />
          </div>
          <div className="buttons">
            {_.map(_.keys(scalingFunctions), (key) => (
              <Button
                key={key}
                className={Classes.build("standard scaling mode", { active: currentScalingFunctionKey === key })}
                onClick={createOnScalingModeButtonClick(key)}
              >
                {key}
              </Button>
            ))}
          </div>
          <div className="buttons">
            {_.map(_.keys(rotationFunctions), (key) => (
              <Button
                key={key}
                className={Classes.build("standard rotation mode", { active: currentRotationFunctionKey === key })}
                onClick={createOnRotationModeButtonClick(key)}
              >
                {key}
              </Button>
            ))}
          </div>
          <div className="buttons">
            {_.map(_.keys(opacifyFunctions), (key) => (
              <Button
                key={key}
                className={Classes.build("standard opacify mode", { active: currentOpacifyFunctionKey === key })}
                onClick={createOnOpacifyModeButtonClick(key)}
              >
                {key}
              </Button>
            ))}
          </div>
          <div className="buttons">
            <Button
              className={Classes.build("standard count mode", { active: itemCount === 1 })}
              onClick={createOnItemCountButtonClick(1)}
            >
              1 item
            </Button>
            <Button
              className={Classes.build("standard count mode", { active: itemCount === 10 })}
              onClick={createOnItemCountButtonClick(10)}
            >
              10 items
            </Button>
            <Button
              className={Classes.build("standard count mode", { active: itemCount === 100 })}
              onClick={createOnItemCountButtonClick(100)}
            >
              100 items
            </Button>
            <Button
              className={Classes.build("standard count mode", { active: itemCount === 10000 })}
              onClick={createOnItemCountButtonClick(10000)}
            >
              10000 items
            </Button>
            <Button
              className={Classes.build("standard count mode", { active: itemCount === 100000000 })}
              onClick={createOnItemCountButtonClick(100000000)}
            >
              100000000 items
            </Button>
          </div>
          <div className="buttons">
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "default" })}
              onClick={createOnSpringButtonClick("default")}
            >
              default
            </Button>
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "gentle" })}
              onClick={createOnSpringButtonClick("gentle")}
            >
              gentle
            </Button>
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "wobbly" })}
              onClick={createOnSpringButtonClick("wobbly")}
            >
              wobbly
            </Button>
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "stiff" })}
              onClick={createOnSpringButtonClick("stiff")}
            >
              stiff
            </Button>
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "slow" })}
              onClick={createOnSpringButtonClick("slow")}
            >
              slow
            </Button>
            <Button
              className={Classes.build("standard spring mode", { active: springConfig === "molasses" })}
              onClick={createOnSpringButtonClick("molasses")}
            >
              molasses
            </Button>
          </div>
          <div className="buttons">
            <Button
              className={Classes.build("standard jumping mode", { active: itemIndex === 0 })}
              onClick={createOnJumpButtonClick(0)}
            >
              Jump to First
            </Button>
            <Button
              className={Classes.build("standard jumping mode", { active: itemIndex === Math.round(itemCount / 2) })}
              onClick={createOnJumpButtonClick(Math.round(itemCount / 2))}
            >
              Jump to Middle
            </Button>
            <Button
              className={Classes.build("standard jumping mode", { active: itemIndex === itemCount - 1 })}
              onClick={createOnJumpButtonClick(itemCount - 1)}
            >
              Jump to Last
            </Button>
          </div>
          <div className="buttons">
            <Button
              className={Classes.build("standard wrapping mode", { active: !wrap })}
              onClick={createOnWrapButtonClick(false)}
            >
              Wrap: false
            </Button>
            <Button
              className={Classes.build("standard wrapping mode", { active: wrap })}
              onClick={createOnWrapButtonClick(true)}
            >
              Wrap: true
            </Button>
          </div>
          <div className="buttons">
            <Button
              className={Classes.build("standard sideitems mode", { active: !viewSideItems })}
              onClick={createViewSideItemsButtonClick(false)}
            >
              View Side Items: false
            </Button>
            <Button
              className={Classes.build("standard sideitems mode", { active: viewSideItems })}
              onClick={createViewSideItemsButtonClick(true)}
            >
              View Side Items: true
            </Button>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
