import { useRef, useEffect, useCallback } from "react";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Use `requestAnimationFrame` in a simpler, hooks-friendly manner.
 * If rendering is too slow (because of async operations or otherwise), frames are dropped.
 *
 * It's important to perform all async operations (if any) before rendering anything, else if
 * interspersed with awaits, rendering steps will occur at different times and artifacts,
 * flashing and disappearing layers and more weird stuff will occur! To help avoid this anti-pattern
 * while still allowing async operations before rendering a frame, we provide a "two-step" API
 * where the async stuff is performed first, then passed to a second, sychronous rendering function.
 */
export const useAnimationFrame = (asyncCallback = async () => {}, renderCallback = () => {}) => {
  const mostRecentAsyncCallbackRef = useLatestRef(asyncCallback);
  const mostRecentRenderCallbackRef = useLatestRef(renderCallback);
  const requestRef = useRef();

  let cancel = false;

  const animate = useCallback(
    (time) => {
      // 1. Run and wait for the async callback to finish
      const mostRecentAsyncCallback = (...args) => mostRecentAsyncCallbackRef.current(...args);
      mostRecentAsyncCallback().then((info) => {
        // 2. Render the current frame with the info returned from the async callback
        const mostRecentRenderCallback = (...args) => mostRecentRenderCallbackRef.current(...args);
        mostRecentRenderCallback(info);
        // 3. Request the next frame
        if (!cancel) requestRef.current = requestAnimationFrame(animate);
      });
    },
    [cancel, mostRecentAsyncCallbackRef, mostRecentRenderCallbackRef]
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      // Here, we silence a react hooks warning, because the described behaviour is *exactly* what we're looking for:
      // "Assignments to the 'cancel' variable from inside React Hook useEffect will be lost after each render."
      // The `cancel` variable's lifetime is the lifetime of the render, which allows `animate` and cleanup to
      // capture that variable and work with it to perform the actual cancel even after `useAnimationFrame`
      // (the hook, not the frame) re-renders. Using a ref would not work because it would be put back in "don't cancel"
      // mode before the async operation of the previous render has a chance to cancel itself.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      cancel = true;
      cancelAnimationFrame(requestRef.current);
    };
  }, [animate]);
};
