import PropTypes from "prop-types";
import { memo } from "react";
import Classes from "../../../helpers/classes";

/**
 * A dumb container (with the proper CSS) for MapContent and friends.
 * Always wrap map contents in a Map element.
 */
const Map = memo(({ className, children, ...rest }) => {
  return (
    <div {...rest} className={Classes.build("ripple-map", className)}>
      {children}
    </div>
  );
});

Map.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Map.Content = require("./map-content").default;
Map.ImageContent = require("./map-image-content").default;
Map.VideoContent = require("./map-video-content").default;
Map.Element = require("./map-element").default;

export default Map;
