import PropTypes from "prop-types";
import { memo, useCallback, useRef } from "react";
import { Image, MediaSrcPropType, resource, useLanguage, useLastTruthy } from "ripple";
import { CloseButton, MediaContainer, PositionedImage, PositionedVideoPlayer, RootRevealer } from "./styled";

/**
 * To use the modal viewer, use a portal:
 *
 *     const [showModalViewer, setShowModalViewer] = useState(true);
 *     const onModalClose = useCallback(() => setShowModalViewer(false), []);
 *     return (
 *       <Page>
 *         {ReactDOM.createPortal(
 *           <ModalViewer
 *             show={showModalViewer}
 *             src={client.wantedMedia("Background", { image: "FullscreenImage", video: "FullscreenVideo" })}
 *             onClose={onModalClose}
 *           />,
 *           document.getElementById("modal-viewer-portal")
 *         )}
 *       </Page>
 *     );
 */
const ModalViewer = memo(({ show, src, onClose, ...rest }) => {
  useLanguage();

  const [lastTruthySrc] = useLastTruthy(src);
  const videoPlayerRef = useRef(null);

  const onCloseButtonClick = useCallback(() => {
    onClose?.();
    videoPlayerRef.current?.pause();
  }, [onClose]);

  return (
    <RootRevealer
      {...rest}
      transition="slide-in-and-out-from-bottom"
      staggerOptions={{ delayBefore: 100, interval: 200 }}
      disableExitStagger={true}
      reveal={show}
    >
      <MediaContainer>
        {(() => {
          const mediaType = lastTruthySrc?.type;
          if (mediaType === "image") return <PositionedImage className="debug-show-area" src={src} scaling="fit" />;
          if (mediaType === "video")
            return <PositionedVideoPlayer ref={videoPlayerRef} className="debug-show-area" src={src} scaling="fit" />;
        })()}
      </MediaContainer>
      <CloseButton onClick={onCloseButtonClick}>
        <Image src={resource("images/Symbole_Close.svg")} />
      </CloseButton>
    </RootRevealer>
  );
});

ModalViewer.propTypes = {
  show: PropTypes.bool,
  src: MediaSrcPropType,
  onClose: PropTypes.func,
};

export default ModalViewer;
