import _ from "lodash";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import Classes from "../../helpers/classes";

class InputOutput extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    shortcuts: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  render() {
    return (
      <div className={Classes.build("input-output", this.props.className)}>
        <div className="top" onClick={this.onChevronClicked}>
          <h1>Keyboard Shortcuts</h1>
          <div className={Classes.build("chevron", { expanded: this.state.expanded })}>
            <i className="fas fa-chevron-up" />
          </div>
        </div>
        <div className={Classes.build("bottom", { expanded: this.state.expanded })}>
          <div className="left side">{this.renderShortcuts()}</div>
          {/* <div className="right side">{this.renderGPIO()}</div> */}
        </div>
      </div>
    );
  }

  renderShortcuts() {
    const shortcuts = _.filter(this.props.shortcuts, (shortcut) => shortcut.type !== "hidden");
    const orderedShortcuts = _.orderBy(shortcuts, ["type", "keyEquivalent"], ["desc", "asc"]);
    return (
      <>
        <div className="key-equivalent-column">
          {_.map(orderedShortcuts, (shortcut, index) => (
            <div className="row" key={`key-equivalent-${shortcut.keyEquivalent}-${index}`}>
              <span
                className={Classes.build("key-equivalent", shortcut.type, {
                  multiple: _.filter(shortcuts, (s) => s.keyEquivalent === shortcut.keyEquivalent).length > 1,
                })}
                onClick={shortcut.handler}
              >
                {shortcut.keyEquivalent}
              </span>
            </div>
          ))}
        </div>
        <div className="description-column">
          {_.map(orderedShortcuts, (shortcut, index) => (
            <div className="row" key={`description-${shortcut.keyEquivalent}-${index}`}>
              <span className="description">{shortcut.description}</span>
            </div>
          ))}
        </div>
      </>
    );
  }

  renderGPIO() {
    return null;
  }

  onChevronClicked = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };
}

export default connect((state) => ({ language: state.language, shortcuts: state.shortcuts }))(InputOutput);
