import _ from "lodash";
import Long from "long";
import Node from "../model/node";
import Text from "../model/text";
import Media from "../model/media";
import FormatMetadata from "../model/format-metadata";
import Visibility from "../enums/visibility";
import MediaType from "../enums/media-type";
import MediaFormat from "../enums/media-format";

function retype(value) {
  if (typeof value !== "string") return value;
  if (value === "true") return true;
  if (value === "false") return false;
  if (value === "null") return null;
  if (!isNaN(value)) return Number(value);
  return value;
}

function required(hash, key) {
  const value = hash[key];
  if (typeof value === "undefined") throw new Error(`Required value '${key}' does not exist in data`);
  return value;
}

function optional(hash, key) {
  const value = hash[key];
  if (typeof value === "undefined") return null;
  return value;
}

/**
 * Depending on the XML parser involved, arrays can get collapsed
 * into a single item if there is only one child. This function ensures
 * that we're going to work with an array of objects in all cases.
 */
function ensureArray(object) {
  if (!object) return [];
  if (Array.isArray(object)) return object;
  return [object];
}

/** Builds a single Text object from a raw text object */
function buildText(nodeId, rawText) {
  const value = optional(rawText, "value");

  // Workaround: Some versions of Simbioz Media Server accumulate
  // empty text instances which shouldn't be kept. We remove them here.
  if (value === null) return null;

  return new Text({
    id: optional(rawText, "id"),
    nodeId: nodeId,
    semantic: required(rawText, "semanticname"),
    language: required(rawText, "languagecode"),
    value: value,
  });
}

/** Builds an array of Text objects from a raw node */
function buildTexts(rawNode) {
  const nodeId = rawNode["id"];
  const textsObject = rawNode["texts"];
  if (typeof textsObject === "undefined") return [];
  const rawTexts = ensureArray(textsObject["text"]);
  return _.filter(
    rawTexts.map((rawText) => buildText(nodeId, rawText)),
    (t) => t !== null
  );
}

/** Builds a single Media object from a raw media object */
function buildMedia(nodeId, rawMedia) {
  const id = optional(rawMedia, "id");
  return new Media(id, {
    nodeId: nodeId,
    semantic: required(rawMedia, "semanticname"),
    language: required(rawMedia, "languagecode"),
    formats: MediaFormat.membersFromBitmask(Long.fromString(required(rawMedia, "formats"))),
    fileSize: parseInt(optional(rawMedia, "filesize")) || 0,
    totalSize: parseInt(optional(rawMedia, "totalsize")) || 0,
    revision: parseInt(required(rawMedia, "revision")),
    contentType: required(rawMedia, "contenttype"),
    type: MediaType.safeFromId(parseInt(required(rawMedia, "type"))),
  });
}

/** Builds an array of Media objects from a raw node */
function buildMedias(rawNode) {
  const nodeId = rawNode["id"];
  const mediasObject = rawNode["medias"];
  if (typeof mediasObject === "undefined") return [];
  const rawMedias = ensureArray(mediasObject["media"]);
  return _.filter(
    rawMedias.map((rawMedia) => buildMedia(nodeId, rawMedia)),
    (t) => t !== null
  );
}

/** Builds an array of Setting objects from a raw node */
function buildSettings(rawNode) {
  const settingsObject = rawNode["settings"];
  if (typeof settingsObject === "undefined") return [];
  const rawSettings = ensureArray(settingsObject["setting"]);
  return _.reduce(
    rawSettings,
    (acc, rawSetting) => {
      acc[required(rawSetting, "semanticname")] = retype(required(rawSetting, "value"));
      return acc;
    },
    {}
  );
}

/** Builds an array of node ids this node is tagged with */
function buildTagPlaceholders(rawNode) {
  let relationsObject = rawNode["relations"];
  if (typeof relationsObject === "undefined") return [];
  let rawRelations = ensureArray(relationsObject["relation"]);
  return rawRelations.map((relation) => relation["relatednode"]);
}

/** Builds a single Node from its raw data */
function buildNode(rawNode) {
  return new Node(rawNode["id"], {
    identifier: optional(rawNode, "identifier"),
    parentId: required(rawNode, "parentid"),
    name: optional(rawNode, "name") || "",
    semantic: required(rawNode, "semanticname"),
    order: parseInt(optional(rawNode, "order")),
    visibility: Visibility.safeFromId(parseInt(required(rawNode, "visibilityid"))),
    defaultMediaSemantic: parseInt(required(rawNode, "defaultmediasemanticid")),
    texts: buildTexts(rawNode),
    medias: buildMedias(rawNode),
    settings: buildSettings(rawNode),
    tagPlaceholders: buildTagPlaceholders(rawNode), // Will be replaced with real nodes afterwards
  });
}

/** Builds a single Format from its raw data */
function buildFormat(rawFormat) {
  return new FormatMetadata({
    id: parseInt(rawFormat["id"]),
    name: rawFormat["name"],
    contentType: required(rawFormat, "contenttype"),
    extension: required(rawFormat, "extension"),
  });
}

/** Builds the storage information that is used to build media URLs */
function buildStorage(rawStorage) {
  if (!rawStorage) return null;
  const storage = _.clone(rawStorage);
  delete storage["$"];
  return storage;
}

function indexNodesBy(nodes, keySelector) {
  return _.reduce(
    nodes,
    (obj, node) => {
      obj[keySelector(node)] = node;
      return obj;
    },
    {}
  );
}

/** Restores parent-child relationships between nodes */
function rebuildHierarchy(indexedNodes, rootId) {
  _.each(indexedNodes, (node) => {
    // The root node has no parent (from the mediaclient's point of view)
    if (node.id === rootId) return;

    const parent = indexedNodes[node.parentId];

    // If we can't find a parent node, the node is orphaned!
    // This shouldn't happen but it can help diagnose server-side issues.
    if (typeof parent === "undefined") {
      // eslint-disable-next-line no-console
      if (window.console) console.warn(`Node with ID '${node.id}' is orphaned!`);
      return;
    }

    // Set the node's parent
    node.parent = parent;

    // Add the node as a child to its parent
    if (node.isVisible()) parent.children.push(node);
    parent.allChildren.push(node);
  });
}

/** Reorders a node's children in-place according to their "order" values */
function reorderChildren(node) {
  node.children = _.sortBy(node.children, (n) => n.order);
  node.allChildren = _.sortBy(node.allChildren, (n) => n.order);
}

/** Freezes a node and its contents to enforce immutability */
function freeze(node) {
  Object.freeze(node);

  Object.freeze(node.medias);
  node.medias.forEach((media) => Object.freeze(media));

  Object.freeze(node.texts);
  node.texts.forEach((text) => Object.freeze(text));
}

const ModelBuilder = {
  /** Reconstructs the node hierarchy from raw data */
  buildFromRaw: function (raw) {
    const rootId = raw["data"]["rootid"];
    const formats = raw["data"]["formats"] || {};
    const rawFormatMetadatas = ensureArray(formats["format"]);
    const formatMetadatas = rawFormatMetadatas.map((rawFormat) => buildFormat(rawFormat));
    const storage = buildStorage(raw["data"]["storage"]);
    const rawNodes = ensureArray(raw["data"]["nodes"]["node"]);
    const nodes = rawNodes.map((rawNode) => buildNode(rawNode));
    const nodesIndexedById = indexNodesBy(nodes, (n) => n.id);

    rebuildHierarchy(nodesIndexedById, rootId);

    nodes.forEach((node) => {
      // Replace tags (which are only ids at this point) with actual nodes

      const tagGroups = _.reduce(
        node.tagPlaceholders,
        (acc, tagId) => {
          const tagNode = nodesIndexedById[tagId];
          if (tagNode.isVisible()) acc.tags.push(tagNode);
          acc.allTags.push(tagNode);
          return acc;
        },
        { tags: [], allTags: [] }
      );

      node.tags = tagGroups.tags;
      node.allTags = tagGroups.allTags;
      delete node.tagPlaceholders;

      reorderChildren(node);
      freeze(node);
    });

    return { nodes: nodes, rootId: rootId, storage: storage, formatMetadatas: formatMetadatas };
  },
};

export default ModelBuilder;
