import PropTypes from "prop-types";
import { createContext, PureComponent } from "react";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Route } from "react-router";
import Paths from "../core/paths";
import Classes from "../helpers/classes";
import Config from "../helpers/config";
import Env from "../helpers/env";
import Navigator from "../helpers/navigator";
import AnalyticsPage from "../react/pages/analytics-page";
import ConfigPage from "../react/pages/config-page";
import ApiDemoPage from "../react/pages/demos/api-demo-page";
import AudioDemoPage from "../react/pages/demos/audio-demo-page";
import BeaconsDemoPage from "../react/pages/demos/beacons-demo-page";
import CameraDemoPage from "../react/pages/demos/camera-demo-page";
import DropDemoPage from "../react/pages/demos/drop-demo-page";
import DropdownDemoPage from "../react/pages/demos/dropdown-demo-page";
import FilePickerDemoPage from "../react/pages/demos/file-picker-demo-page";
import GeolocationDemoPage from "../react/pages/demos/geolocation-demo-page";
import GpioDemoPage from "../react/pages/demos/gpio-demo-page";
import GridDemoPage from "../react/pages/demos/grid-demo-page";
import MailDemoPage from "../react/pages/demos/mail-demo-page";
import MapDemoPage from "../react/pages/demos/map-demo-page";
import MaximizerDemoPage from "../react/pages/demos/maximizer-demo-page";
import PanoramaDemoPage from "../react/pages/demos/panorama-demo-page";
import ScrollerDemoPage from "../react/pages/demos/scroller-demo-page";
import SelectorDemoPage from "../react/pages/demos/selector-demo-page";
import StaggerDemoPage from "../react/pages/demos/stagger-demo-page";
import TextDemoPage from "../react/pages/demos/text-demo-page";
import TransitionsDemoPage from "../react/pages/demos/transitions-demo-page";
import VideoDemoPage from "../react/pages/demos/video-demo-page";
import VirtualKeyboardDemoPage from "../react/pages/demos/virtual-keyboard-demo-page";
import WikitudeDemoPage from "../react/pages/demos/wikitude-demo-page";
import StartupPage from "../react/pages/startup-page";
import FlagsActions from "../redux/actions/local/flags-actions";
import Button from "./components/button";
import { CoreErrorBoundary } from "./components/error-boundary";
import MediaTransitioner from "./components/media-transitioner";
import RouteTransitioner from "./components/route-transitioner";
import DebugOverlay from "./core/debug-overlay";
import DevBuildIndicator from "./core/dev-build-indicator";
import Flags from "./core/flags";
import MultiClickButton from "./core/multi-click-button";
import PathBar from "./core/path-bar";
import TouchFeedback from "./core/touch-feedback";
import AnalyticsAdminPage from "./pages/analytics-admin-page";
import AnalyticsHomePage from "./pages/analytics-home-page";
import DemosPage from "./pages/demos-page";

export const RootBackgroundContext = createContext();

class Root extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    flags: PropTypes.object.isRequired,
    interaction: PropTypes.object.isRequired,
    language: PropTypes.string,
    location: PropTypes.object.isRequired,
    appRoot: PropTypes.object.isRequired,
  };

  constructor() {
    super();
    this.state = {
      background: null, // Automatically set by pages on mount
    };
  }

  render() {
    let style = {};

    // Optional forced content size
    if (Config.contentSize) {
      const contentSize = Config.contentSize.split("x");
      const contentWidth = parseInt(contentSize[0]);
      const contentHeight = parseInt(contentSize[1]);
      style = {
        ...style,
        width: contentWidth,
        minWidth: contentWidth,
        height: contentHeight,
        minHeight: contentHeight,
        transform: Config.rootTransform,
      };
    }

    return (
      <div
        className={Classes.build(
          "root",
          `language-${this.props.language}`,
          { "block-interaction": this.props.interaction.block },
          { "no-select": Config.interaction.mode === "kiosk" },
          { debug: this.props.flags.debug },
          { bounds: this.props.flags.bounds },
          { editing: !Config.disableAdminShortcuts && this.props.flags.editing }
        )}
        style={style}
      >
        <CoreErrorBoundary>
          <MediaTransitioner
            className="background-media-transitioner"
            src={this.state.background}
            classNames={Config.rootBackgroundTransition}
            instantExit={Config.rootBackgroundInstantExit}
            mediaProps={{ autoPlay: true, loop: true, scaling: "fill" }}
          />
          <RootBackgroundContext.Provider value={this.replaceBackground}>
            <RouteTransitioner
              className="page-container"
              transitionKey={Paths.isCorePath(this.props.location.pathname) ? this.props.location.pathname : "app-root"}
              location={this.props.location}
            >
              <Route exact path="/" component={StartupPage} />
              {/** Core page routes */}
              <Route path={Paths.corePaths.analyticsPath} component={AnalyticsPage} />
              <Route path={Paths.corePaths.analyticsAdminPath} component={AnalyticsAdminPage} />
              <Route path={Paths.corePaths.analyticsHomePath} component={AnalyticsHomePage} />
              <Route path={Paths.corePaths.configPath} component={ConfigPage} />
              {/** Demo page routes */}
              <Route path={Paths.corePaths.demosPath} component={DemosPage} />
              <Route path={Paths.corePaths.transitionsDemoPath} component={TransitionsDemoPage} />
              <Route path={Paths.corePaths.audioDemoPath} component={AudioDemoPage} />
              <Route path={Paths.corePaths.beaconsDemoPath} component={BeaconsDemoPage} />
              <Route path={Paths.corePaths.cameraDemoPath} component={CameraDemoPage} />
              <Route path={Paths.corePaths.filePickerDemoPath} component={FilePickerDemoPage} />
              <Route path={Paths.corePaths.videoDemoPath} component={VideoDemoPage} />
              <Route path={Paths.corePaths.textDemoPath} component={TextDemoPage} />
              <Route path={Paths.corePaths.gridDemoPath} component={GridDemoPage} />
              <Route path={Paths.corePaths.maximizerDemoPath} component={MaximizerDemoPage} />
              <Route path={Paths.corePaths.selectorDemoPath} component={SelectorDemoPage} />
              <Route path={Paths.corePaths.dropDemoPath} component={DropDemoPage} />
              <Route path={Paths.corePaths.dropdownDemoPath} component={DropdownDemoPage} />
              <Route path={Paths.corePaths.scrollerDemoPath} component={ScrollerDemoPage} />
              <Route path={Paths.corePaths.staggerDemoPath} component={StaggerDemoPage} />
              <Route path={Paths.corePaths.mapDemoPath} component={MapDemoPage} />
              <Route path={Paths.corePaths.gpioDemoPath} component={GpioDemoPage} />
              <Route path={Paths.corePaths.apiDemoPath} component={ApiDemoPage} />
              <Route path={Paths.corePaths.mailDemoPath} component={MailDemoPage} />
              <Route path={Paths.corePaths.virtualKeyboardDemoPath} component={VirtualKeyboardDemoPage} />
              <Route path={Paths.corePaths.panoramaDemoPath} component={PanoramaDemoPage} />
              <Route path={Paths.corePaths.geolocationDemoPath} component={GeolocationDemoPage} />
              <Route path={Paths.corePaths.wikitudeDemoPath} component={WikitudeDemoPage} />
              {/** Custom app root (which defines its own routes) */}
              <Route component={this.props.appRoot} />
              <Route render={this.renderNoRoute} />
            </RouteTransitioner>
          </RootBackgroundContext.Provider>
          <div id="maximizer-portal" className="maximizer-portal" />
        </CoreErrorBoundary>
        {__DEV__ && Env.isRCC && <Button className="back-hidden-button" onClick={this.onBackButtonClick} />}
        {__DEV__ && Env.isRCC && <Button className="home-hidden-button" onClick={this.onHomeButtonClick} />}
        {Env.isDesktop && (
          <MultiClickButton
            clicks={6}
            timeout={1000}
            className="analytics-hidden-button"
            onMultiClick={this.onAnalyticsButtonClick}
          />
        )}
        <PathBar />
        {Env.isDesktop && <TouchFeedback />}
        <Toaster />
        <Flags />
        <DevBuildIndicator active={__DEV__} />
        {Env.isDesktop && <DebugOverlay />}
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown);
    document.addEventListener("keyup", this.onKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("keyup", this.onKeyUp);
  }

  renderNoRoute = (props) => {
    throw new Error(`No route matches path '${props.location.pathname}'`);
  };

  replaceBackground = (background) => {
    // Redundant calls to `replaceBackground` are OK;
    // they are necessary to catch all relevant transition cases (see `Page`).
    // Here, we simply compare the current background with the new one to avoid
    // an infinite render loop when and if those redundant calls come in.
    if (background === this.state.background) return;
    this.setState({ background });
  };

  onKeyDown = (event) => {
    if (event.key !== "Alt") return;
    window.editing = true;
    this.props.dispatch(FlagsActions.enableEditing());
  };

  onKeyUp = (event) => {
    if (event.key !== "Alt") return;
    window.editing = false;
    this.props.dispatch(FlagsActions.disableEditing());
  };

  onBackButtonClick = () => Navigator.goBack();

  onHomeButtonClick = () => Navigator.navigate({ path: Navigator.home });

  onAnalyticsButtonClick = () => {
    if (this.props.location.pathname !== Paths.corePaths.analyticsPath) {
      Navigator.navigate({ path: Paths.corePaths.analyticsPath });
    } else {
      Navigator.goBack();
    }
  };
}

const mapStateToProps = function (state) {
  return {
    language: state.language,
    flags: state.flags,
    interaction: state.interaction,
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(Root);
