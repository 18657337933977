import _ from "lodash";

const Paths = {
  // It's important to put all core route paths in here else
  // they won't be excluded from analytics!
  corePaths: {
    analyticsPath: "/analytics",
    analyticsAdminPath: "/analytics-admin",
    analyticsHomePath: "/analytics-home",
    configPath: "/config",
    demosPath: "/demos",
    transitionsDemoPath: "/transitions-demo",
    audioDemoPath: "/audio-demo",
    beaconsDemoPath: "/beacons-demo",
    cameraDemoPath: "/camera-demo",
    filePickerDemoPath: "/file-picker-demo",
    videoDemoPath: "/video-demo",
    textDemoPath: "/text-demo",
    dropDemoPath: "/drop-demo",
    dropdownDemoPath: "/dropdown-demo",
    scrollerDemoPath: "/scroller-demo",
    staggerDemoPath: "/stagger-demo",
    gpioDemoPath: "/gpio-demo",
    gridDemoPath: "/grid-demo",
    maximizerDemoPath: "/maximizer-demo",
    selectorDemoPath: "/selector-demo",
    apiDemoPath: "/http-demo",
    mailDemoPath: "/mail-demo",
    mapDemoPath: "/map-demo",
    virtualKeyboardDemoPath: "/virtual-keyboard-demo",
    panoramaDemoPath: "/panorama-demo",
    geolocationDemoPath: "/geolocation-demo",
    wikitudeDemoPath: "/wikitude-demo",
  },
  isCorePath: function (path) {
    return _.includes(_.values(this.corePaths), path);
  },
};

export default Paths;
