import YamlResource from "../logic/yaml-resource";

const resource = new YamlResource();

export const loadConfig = (...args) => resource.load(...args);
export const mergeConfig = (...args) => resource.merge(...args);

export default resource.values;

window.ripple = { ...(window.ripple || {}), config: resource.values };
