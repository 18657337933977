import Enum from "../core/enum/enum";
import Member from "../core/enum/member";

let MediaFormat = new Enum("MediaType");

MediaFormat.ensureMemberIsValid = function (member) {
  // Must be defined before adding initial members!
  // Throw on missing properties (when overriding)
};

MediaFormat.addMembers([
  new Member(0, "Original", { deviceType: "server" }),
  new Member(1, "Thumb32x32", { deviceType: "server" }),
  new Member(2, "Thumb200x150", { deviceType: "server" }),
  new Member(3, "Preview360x270", { deviceType: "server" }),
]);

export default MediaFormat;
