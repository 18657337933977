import PropTypes from "prop-types";
import { forwardRef, memo, useEffect, useImperativeHandle, useRef } from "react";
import Classes from "../../../helpers/classes";
import Load from "../../../helpers/load";
import Rect from "../../../types/rect";
import { useCamera } from "../../hooks/internal/use-camera";
import { useDebug } from "../../hooks/use-debug";
import { useMeasure } from "../../hooks/use-measure";
import Canvas from "../canvas";

class CameraAPI {}

function applyEffects(imageData, effects) {
  const data = imageData.data;
  const dataLength = data.length;

  for (let i = 0, j = 0; j < dataLength; i += 1, j += 4) {
    // Apply effects in succession to produce a new pixel
    effects.forEach((effect) => effect(data, j));
  }

  return imageData;
}

const Camera = memo(
  forwardRef(
    (
      {
        className,
        children,
        effects,
        underlayImageURL,
        overlayImageURL,
        cameraCompositeOperation,
        underlayCompositeOperation,
        overlayCompositeOperation,
        snapFaceToNormalizedFrame,
        getUserMediaOptions,
        ...rest
      },
      ref
    ) => {
      const debug = useDebug();
      const canvasRef = useRef(null);

      const [measureRef, { width: viewWidth, height: viewHeight }] = useMeasure();

      // Load underlay and overlay images
      const underlayImageRef = useRef(null);
      const overlayImageRef = useRef(null);
      useEffect(() => {
        if (underlayImageURL) Load.image(underlayImageURL).then((image) => (underlayImageRef.current = image));
        if (overlayImageURL) Load.image(overlayImageURL).then((image) => (overlayImageRef.current = image));
      }, [overlayImageURL, underlayImageURL]);

      // Passing null to `alterImageData` prevents `useCamera` from extracting the image data for no reason,
      // reducing performance substantially. We only need to alter image data when there are effects to apply.
      const alterImageData = effects.length === 0 ? null : (imageData) => applyEffects(imageData, effects);

      useCamera(getUserMediaOptions, canvasRef, {
        alterImageData,
        drawCompositeOperation: cameraCompositeOperation,
        beforeDrawCompositeOperation: underlayCompositeOperation,
        afterDrawCompositeOperation: overlayCompositeOperation,
        snapFaceToNormalizedFrame,
        beforeDraw: (context) => {
          if (underlayImageRef.current && !debug)
            context.drawImage(underlayImageRef.current, 0, 0, viewWidth, viewHeight);
        },
        afterDraw: (context) => {
          if (overlayImageRef.current && !debug)
            context.drawImage(overlayImageRef.current, 0, 0, viewWidth, viewHeight);
        },
      });

      useImperativeHandle(
        ref,
        () => {
          const api = new CameraAPI();
          api.getCanvas = () => canvasRef.current;
          api.takeSnapshot = (...args) => canvasRef.current.takeSnapshot(...args);
          api.drawSnapshotToCanvas = (...args) => canvasRef.current.drawSnapshotToCanvas(...args);
          return api;
        },
        []
      );

      return (
        <div {...rest} ref={measureRef} className={Classes.build("ripple-camera", className)}>
          <Canvas ref={canvasRef} width={viewWidth} height={viewHeight} />
          <div className="camera-children">{children}</div>
        </div>
      );
    }
  )
);

Camera.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  underlayImageURL: PropTypes.string,
  overlayImageURL: PropTypes.string,
  effects: PropTypes.arrayOf(PropTypes.func),
  // See: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/globalCompositeOperation
  cameraCompositeOperation: PropTypes.string,
  underlayCompositeOperation: PropTypes.string,
  overlayCompositeOperation: PropTypes.string,
  snapFaceToNormalizedFrame: PropTypes.instanceOf(Rect),
  getUserMediaOptions: PropTypes.object,
};

Camera.defaultProps = {
  effects: [],
  getUserMediaOptions: { video: { width: { ideal: 4096 }, height: { ideal: 2160 } } },
};

export default Camera;
