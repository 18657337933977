import _ from "lodash";
import { memo, useState } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";
import Config from "../../../../helpers/config";
import { useApiMessage } from "../../../hooks/use-api-message";
import clipboard from "../../../../helpers/clipboard";
import Toast from "../../../../helpers/toast";
import Button from "../../../components/button";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const [messages, setMessages] = useState([]);
  useApiMessage("demo", (data) => setMessages([...messages, data]));

  const createOnCopyClick = (id) => () => {
    clipboard.copy(document.getElementById(id).innerHTML);
    Toast.info("Copied to clipboard!");
  };

  return (
    <Page className="demo api ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">HTTP API</Text>

          <p className="standard description">
            The HTTP API allows sending messages to the Ripple app when it&apos;s running in REC. It is particularly
            useful to trigger one-off actions in the app from the outside. There is a single endpoint that can receive
            information in multiple ways, but the result is the same in all cases.
          </p>

          <table className="demo">
            <thead>
              <tr>
                <th>Endpoint</th>
                <th>Method</th>
                <th>Data</th>
                <th>Example Request (paste those in a terminal to test them!)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <code>{"/messages/{name}"}</code>
                </td>
                <td>POST</td>
                <td>JSON Body</td>
                <td>
                  <code id="curl-1">{`curl --header "Content-Type: application/json" --request POST --data '{"what":"POST with JSON body"}' http://localhost:${Config.api.port}/message/demo`}</code>
                  &nbsp;
                  <Button className="copy" onClick={createOnCopyClick("curl-1")}>
                    copy
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <code>{"/messages/{name}"}</code>
                </td>
                <td>POST</td>
                <td>Query String</td>
                <td>
                  <code id="curl-2">{`curl --request POST http://localhost:${Config.api.port}/message/demo?what=POST%20with%20query%20string`}</code>
                  &nbsp;
                  <Button className="copy" onClick={createOnCopyClick("curl-2")}>
                    copy
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <code>{"/messages/{name}"}</code>
                </td>
                <td>GET</td>
                <td>Query String</td>
                <td>
                  <code id="curl-3">{`curl --request GET http://localhost:${Config.api.port}/message/demo?what=GET%20with%20query%20string`}</code>
                  &nbsp;
                  <Button className="copy" onClick={createOnCopyClick("curl-3")}>
                    copy
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>

          <h2>Received Messages</h2>
          <table className="demo">
            <thead>
              <tr>
                <th>Name</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {_.map(messages, (data, index) => (
                <tr key={index} className="message">
                  <td className="name">demo</td>
                  <td className="data">{JSON.stringify(data)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
