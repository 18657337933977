import { memo, useCallback } from "react";

import Button from "../../../../components/button";
import Navigator from "../../../../../helpers/navigator";

const DemoBackButton = memo(({ ...rest }) => {
  const onClick = useCallback(Navigator.goBack, []);
  return (
    <Button {...rest} className="demo-back-button" onClick={onClick}>
      Back
    </Button>
  );
});

DemoBackButton.propTypes = {};

export default DemoBackButton;
