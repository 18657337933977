import { memo, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Render from "../../../../helpers/internal/render";
import Text from "../../../components/text";
import Button from "../../../components/button";
import Camera from "../../../components/camera";
import greenScreen from "../../../components/camera/effects/green-screen";
import grayscale from "../../../components/camera/effects/grayscale";
import resource from "../../../../helpers/resource";
import Classes from "../../../../helpers/classes";
import Rect from "../../../../types/rect";

const PageComponent = memo(({ data }) => {
  const cameraRef = useRef(null);

  const [isGreenScreenOn, setIsGreenScreenOn] = useState(false);
  const [isGrayscaleOn, setIsGrayscaleOn] = useState(false);

  const effects = [];
  if (isGreenScreenOn) effects.push(greenScreen);
  if (isGrayscaleOn) effects.push(grayscale);

  const onGreenScreenButtonClick = useCallback(() => setIsGreenScreenOn(!isGreenScreenOn), [isGreenScreenOn]);
  const onGrayscaleButtonClick = useCallback(() => setIsGrayscaleOn(!isGrayscaleOn), [isGrayscaleOn]);

  return (
    <Page className="demo camera ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Camera</Text>
          <p className="standard description">
            The <code>Camera</code> component displays the webcam. It automatically aspect fills the video stream inside
            the component&apos;s div and supports advanced features such as effects (chroma key, grayscale, etc.),
            overlays (display an image under and over the camera), snapshots (capture the whole composition as an image
            for display in the app or for sending by email) and more.
          </p>

          <div className="cases">
            <div className="case">
              <div className="images">
                <Camera
                  ref={cameraRef}
                  effects={effects}
                  underlayImageURL={resource("images/demo/mail-camera-underlay.jpg")}
                  overlayImageURL={resource("images/demo/mail-camera-overlay.png")}
                />
              </div>
              <div className="toggles">
                <Button
                  className={Classes.build("standard toggle", { active: isGreenScreenOn })}
                  onClick={onGreenScreenButtonClick}
                >
                  Green Screen
                </Button>
                <Button
                  className={Classes.build("standard toggle", { active: isGrayscaleOn })}
                  onClick={onGrayscaleButtonClick}
                >
                  Grayscale
                </Button>
              </div>
            </div>
            <div className="case">
              <div className="images">
                <Camera ref={cameraRef} effects={effects} snapFaceToNormalizedFrame={new Rect(0.3, 0.2, 0.4, 0.6)} />
              </div>
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

const protectedPage = Render.protect((c) => c.props.data)(PageComponent);
export default connect((state) => ({ language: state.language, data: state.data }))(protectedPage);
