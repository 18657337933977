function getMediaType(media) {
  // If the media itself (which is guaranteed to be non-null)
  // does not actually contain a media, there is not media type
  if (!media.exists) return null;

  // Render the appropriate component based on media type
  return media.type;
}

export default function (src) {
  if (!src) return null;

  // Find the type based on the extension if the provided src is an URL
  if (typeof src === "string") {
    if (/\.(jpg|jpeg|png|bmp|gif)$/.test(src)) return "image";
    if (/\.(mp4|m4v|mpg|mpeg|mov)$/.test(src)) return "video";
    throw new Error(`Can't guess src kind for: ${src}`);
  }

  // From there we can assume that we're working with a MediaInfo instance
  return getMediaType(src);
}
