import _ from "lodash";
import Long from "long";

let Member = function (id, name, fields) {
  this.id = id;
  this.name = name;
  _.assign(this, fields);

  this.bitmask = function () {
    return Long.fromNumber(1).shiftLeft(this.id);
  };
};

export default Member;
