import { Button, Image, Page as RipplePage, Text } from "ripple";
import styled from "styled-components";
import { accent01Color, backPanelColor } from "../../common";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleArea = styled.div`
  @media all and (orientation: landscape) {
    margin-top: 8vh;
  }
  @media all and (orientation: portrait) {
    margin-top: 1.5vh;
  }
`;

export const Title = styled(Text)`
  font-size: 5vw;
  font-family: "Atma-Bold";
  text-align: center;
  pointer-events: none;

  @media all and (orientation: portrait) {
    opacity: 0;
  }
`;

export const Splitter = styled.div`
  height: 0.3vw;
  width: 100%;
  background-color: ${backPanelColor};
  margin-top: 2vh;

  @media all and (orientation: portrait) {
    opacity: 0;
  }
`;

export const Sections = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  @media all and (orientation: landscape) {
    flex-direction: row;
  }
  @media all and (orientation: portrait) {
    flex-direction: column;
  }
`;

export const Section = styled(Button)`
  @media all and (orientation: landscape) {
    padding-top: 14vh;
  }
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BannerImage = styled(Image).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SectionIcon = styled(Image)`
  @media all and (orientation: landscape) {
    width: 25vmin;
  }
  @media all and (orientation: portrait) {
    width: 16vmin;
  }
`;

export const SectionTitle = styled(Text)`
  margin-top: 2vh;
  font-family: "Atma-Bold";
  color: ${backPanelColor};
  text-align: center;
  font-size: 5vmin;
`;

export const SectionArrow = styled(Image)`
  margin: 1vmin;
  width: 3vmin;
  transform: rotate3d(0, 0, 1, 90deg);
  path {
    fill: ${accent01Color};
  }
`;
