import { Button, Image, resource, Revealer, staggerStep, Text } from "ripple";
import styled from "styled-components";
import { accent01Color, backPanelColor, logoColor, vert05Color } from "../../common";

export const MenuRoot = styled.div`
  position: absolute;
  top: env(safe-area-inset-top);
  left: 0;
  pointer-events: none;
  z-index: 1000;

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
  }

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const MenuPanel = styled(Revealer).attrs((props) => ({
  transition: "slide-in-and-out-from-top",
  disableExitStagger: true,
}))`
  display: flex;

  @media (max-width: 480px) {
    height: 100%;
  }
`;

export const MenuContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  transition: all 500ms ease-in-out;
  box-shadow: ${(props) => (props.show ? "0 0 200px rgba(0, 0, 0, 0.5)" : "none")};
`;

export const LeftColumn = styled.div`
  background-color: ${vert05Color};
  width: 8vh;
  pointer-events: auto;
`;

export const RightColumn = styled.div`
  background-color: ${logoColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  pointer-events: auto;
`;

export const ToggleButton = styled(Button)`
  position: absolute;
  top: 1.5vh;
  left: 1.5vh;
  width: 5vh;
  height: 5vh;
  pointer-events: auto;

  .hotspot {
    margin: -2.5vh;
  }
`;

export const MenuItem = staggerStep({ transition: "slide-in-and-fade-from-right" })(styled(Button)`
  margin-top: 3vh;
  margin-left: ${(props) => (props.indent ? 8 : 2)}vh;
  margin-right: 2vh;
  color: ${backPanelColor};

  &:last-child {
    margin-bottom: 3vh;
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  .hotspot {
    margin: -1.5vh;
  }
`);

export const MenuItemArrow = styled(Image).attrs((props) => ({ src: resource("images/Symbole_Arrow_01.svg") }))`
  width: 3vh;
  height: 3vh;
  path {
    fill: ${accent01Color};
  }
`;

export const MenuItemColumn = styled.div`
  margin-left: 1.5vh;
  display: flex;
  flex-direction: column;
`;

export const SmallText = styled(Text)`
  font-family: "Atma-Semibold";
  font-size: 1.8vh;
`;

export const BigText = styled(Text)`
  margin-top: 0.2vh;
  font-family: "Atma-Semibold";
  font-size: 3vh;
`;

export const MenuSplitter = styled.div`
  margin-top: 3vh;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
`;
