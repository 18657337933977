import svgInject from "@iconfu/svg-inject";
import _ from "lodash";
import PropTypes from "prop-types";
import { forwardRef, memo, useEffect, useRef } from "react";
import mergeRefs from "react-merge-refs";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import Log from "../../../helpers/log";
import { isResourcePath } from "../../../helpers/resource";
import MediaInfo from "../../../logic/info/media-info";
import { MediaSrcPropType } from "../../../logic/prop-types";

const RippleImage = memo(
  forwardRef(({ style, className, src, scaling, density, fadeIn, onLoad }, ref) => {
    const rootRef = useRef(null);

    const url = (() => {
      if (!Config.load.images) return null;
      if (!src) return null;

      if (typeof src === "string") return src;

      // We assume it's an image because we can't know if the underlying format
      // is an image based on the media's type (the media may be a video, but the
      // format may be an image thumbnail for that video).
      if (src instanceof MediaInfo) return src.url;

      return null;
    })();

    const pixelRatio = (() => {
      if (density === "auto") {
        // When the density prop is set to "auto", we extract the image density (for example `2`)
        // from the URL. Examples of valid patterns: `@2x`, `_2x`, `@3x`, etc. If the URL does not
        // contain a density pattern, we consider the image to be @1x.
        const match = /[@_](\d)x(?![a-zA-Z0-9])/.exec(url);
        if (match && match.length === 2) return parseInt(match[1]);
        return 1;
      }
      return density;
    })();

    const srcType = (() => {
      if (!src) return "image";
      if (typeof src === "string" && src.endsWith(".svg")) return "vector";
      if (src instanceof MediaInfo && src.exists) return src.type;
      return "image";
    })();

    const srcDescription = (() => {
      if (!src) return "Empty";
      if (src && src instanceof MediaInfo) return src.description;
      if (isResourcePath(src)) return `Res: ${_.last(src.split("/"))}`;
      return "Url";
    })();

    // When the src changes, preload and append the image to the DOM
    const urlRef = useRef(null);
    const pixelRatioRef = useRef(null);
    useEffect(() => {
      // Do not trigger another load if the URL is the same.
      // This avoids loading the image again each time an ancestor renders.
      if (url === urlRef.current && pixelRatio === pixelRatioRef.current) return;
      urlRef.current = url;
      pixelRatioRef.current = pixelRatio;

      const loadImage = (url) => {
        // We preload the image using a DOM element for two reasons:
        // - Images sometimes only partially load on iOS when using a react-created `<img onload>` and/or `new Image().onload`
        //   (see the approach used prior to this commit). For some unknown reason, this triggered only when a PIXI stage
        //   was present in the page, and only on iOS. The issue seemed to occur when fading-in images that were not yet
        //   fully loaded in the DOM. The problem disappeared when we disabled fade-in, but we took another approach to keep it.
        // - If we used an HTML Image the image would be loaded two times (even if already cached); once when loading
        //   Image and another time when setting `<img src>`.
        return new Promise((resolve, reject) => {
          if (!url) {
            resolve(null);
            return;
          }

          const image = document.createElement("img");
          image.crossOrigin = "anonymous";
          image.srcset = `${url} ${pixelRatio}x`;
          image.src = url; // Fallback
          image.onload = () => resolve(image);
          image.onerror = () => reject();

          // Prevent dragging a ghost of the image in kiosk mode
          if (Config.interaction.mode === "kiosk") image.style["pointer-events"] = "none";
        });
      };

      loadImage(url)
        .then((image) => {
          // Remove the current element(s) if any
          [...rootRef.current.getElementsByClassName("loaded-image-internal")]?.forEach((e) =>
            rootRef.current.removeChild(e)
          );

          // Do not append if no image was loaded
          if (!image) return;

          const appendElement = (element) => {
            if (!rootRef.current) return;

            // Append the new element
            element.classList.add("loaded-image-internal");
            if (rootRef.current) rootRef.current.appendChild(element);

            if (fadeIn === "always") element.style.opacity = 0;
            element.style.transition = "opacity 200ms ease-in-out";

            // Append the new element and fade it in after is has initially rendered with opacity 0.
            // Both `requestAnimationFrame()` and `setTimeout()` are required for the fade to work in Chrome and Safari iOS.
            requestAnimationFrame(() => setTimeout(() => (element.style.opacity = 1), 0));

            if (onLoad) onLoad();
          };

          if (srcType === "image" || srcType === "video") {
            // When the source is an image, append the img tag directly in the DOM
            appendElement(image);
          } else if (srcType === "vector") {
            // When the source is a vector image (SVG), inject it inline instead of loading it using img, so
            // that we can style it and access its contents with JavaScript (for example to animate specific SVG elements).
            svgInject(image, {
              // Disabling caching ensures that afterLoad is called every time but the browser's cache still applies.
              // This should also reduce memory usage when displaying large/complex SVG.
              useCache: false,
              // Disabling this preserves ids as they exist in Sketch (or other editors) which
              // makes for stable IDs to programmatically alter the SVG DOM.
              makeIdsUnique: false,
              afterLoad: (svg) => {
                // Remove the `<title>` element if present, because it makes
                // a native browser tooltip appear when hovering the mouse on the image.
                const titleElement = svg.querySelector("title");
                if (titleElement) titleElement.parentNode.removeChild(titleElement);

                appendElement(svg);
              },
            });
          } else {
            Log.error(`Unsupported image src type '${srcType}'`);
          }
        })
        .catch(() => {
          Log.error("Error loading image");
        });
    }, [url, fadeIn, srcType, onLoad, pixelRatio]);

    return (
      <div
        ref={mergeRefs([ref, rootRef])}
        className={Classes.build("ripple-image", { fade: fadeIn === "always" }, scaling, className)}
        style={style}
      >
        {<span className="media-info-description">{srcDescription}</span>}
        {/* Image will be inserted here dynamically after load */}
      </div>
    );
  })
);

RippleImage.propTypes = {
  src: MediaSrcPropType,
  className: PropTypes.string,
  style: PropTypes.object,
  scaling: PropTypes.oneOf(["fit", "fill", "stretch"]),
  density: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]), // "auto" will automatically extract the density from the URL and fallback to 1 otherwise
  fadeIn: PropTypes.oneOf(["never", "always"]),
  onLoad: PropTypes.func, // Common interface for media readiness
};

RippleImage.defaultProps = {
  scaling: "stretch", // Most similar to <img/> when specifying a fixed width and height
  fadeIn: "never", // Most similar to <img/>
  density: "auto", // Unless the filename contains a density indicator (ex: `@2x`), same behavior as <img/> (consider the image as @1x)
};

export default RippleImage;
