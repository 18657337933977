import { memo, useRef } from "react";
import { extend, useThree, useFrame } from "@react-three/fiber";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
extend({ OrbitControls });

// Source: https://codeworkshop.dev/blog/2020-04-03-adding-orbit-controls-to-react-three-fiber/
const ThreeFiberOrbitControls = memo(({ ...rest }) => {
  const defaultProps = {
    minDistance: 1,
    maxDistance: 3,
    enablePan: false,
  };

  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();
  useFrame((state) => controls.current.update());
  return <orbitControls ref={controls} args={[camera, domElement]} {...{ ...defaultProps, ...rest }} />;
});

export default ThreeFiberOrbitControls;
