import _ from "lodash";
import Strings from "../../../../helpers/strings";
import AnalyticsHelper from "../../analytics-helper";

function calculate(preprocessed) {
  const navigationEvents = _.filter(preprocessed.events, (e) => e.name === "navigation" && e.data.root === false);
  const groupedByPath = _.groupBy(navigationEvents, (e) => AnalyticsHelper.firstPathComponent(e.data.path));
  return _.mapValues(groupedByPath, (g) => g.length);
}

export default function (preprocessed) {
  this.name = "PageViews";
  this.keyName = "Page";
  this.valueName = "Views";
  this.format = (value) => `${value} ${Strings.localized("AnalyticsViews").value}`;
  this.value = calculate(preprocessed);
}
