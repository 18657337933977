import { PureComponent } from "react";

export default class Render {
  static protect(condition) {
    return function (CustomComponent) {
      class RenderProtectWrapper extends PureComponent {
        render() {
          return condition(this) ? <CustomComponent {...this.props} /> : null;
        }
      }
      return RenderProtectWrapper;
    };
  }
}
