import PropTypes from "prop-types";
import { memo, useCallback, useContext, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Classes from "../../../helpers/classes";
import { Styles } from "../../../ripple";
import { useConstant } from "../../hooks/use-constant";
import { useDebug } from "../../hooks/use-debug";
import { MapContentContext } from "./map-content";

/**
 * Pixel-perfect layout on the map content.
 * Same result as not wrapping the thing in MapElement at all.
 */
const scaleFixed = () => 1;

/**
 * Layout in content coordinates, but stay the same visual size after initial display.
 * For example, if we have a 2000x2000 map and a 100x100 item and the map needs to be
 * scaled by 0.5 to fit in the viewport, the item will now be displayed as 50x50. With
 * `scaleToContent`, the item will always measure 50x50 on the screen.
 */
const scaleToContent = (contentScale, screenScale) => 1 / contentScale;

/**
 * Layout in content coordinates, then scale so that the item has the pixel size it would
 * have had it been inserted in the DOM in screen coordinates (outside of the map).
 * For example, if we have a 2000x2000 map and a 100x100 item and the map needs to be
 * scaled by 0.5 to fit in the viewport, the item will always be displayed at 100x100
 * on the screen but will still be positioned relative to the MapContent's coordinate system.
 */
const scaleToScreen = (contentScale, screenScale) => 1 / screenScale;

/**
 * Watches MapContent scale changes and transforms itself proportionally based on the specified scaling mode.
 * Anything that changes size based on zoom must be positioned using this component. Use the `position` prop
 * to specify where the thing should be and the
 */
const MapElement = memo(({ children, style, className, anchor, position, scaling, ...rest }) => {
  const id = useConstant(() => uuidv4());
  const debug = useDebug();

  const contentRef = useRef(null);
  const lastKnownScaleRef = useRef(1);

  const contentContext = useContext(MapContentContext);

  const updateTransform = useCallback(() => {
    const scale = lastKnownScaleRef.current;
    const translateTransform = `translate3d(${-anchor.x * 100}%, ${-anchor.y * 100}%, 0)`;
    const scaleTransform = `scale3d(${scale}, ${scale}, 1)`;
    contentRef.current.style.transform = `${translateTransform} ${scaleTransform}`;
    contentRef.current.style.transformOrigin = `${anchor.x * 100}% ${anchor.y * 100}%`;
  }, [anchor.x, anchor.y]);

  const onScaleChange = useCallback(
    (contentScale, screenScale) => {
      const scale = scaling(contentScale, screenScale);
      lastKnownScaleRef.current = scale;
      updateTransform();
    },
    [scaling, updateTransform]
  );

  useEffect(() => {
    contentContext.registerContent(id, { onScaleChange });
    return () => contentContext.unregisterContent(id);
  }, [id, contentContext, onScaleChange]);

  // Update the transforms
  useEffect(() => {
    updateTransform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor, position]);

  return (
    <div
      {...rest}
      ref={contentRef}
      className={Classes.build("map-element", className)}
      style={Styles.merge(style, {
        top: isFinite(position.top) ? `${position.top}px` : position.top,
        left: isFinite(position.left) ? `${position.left}px` : position.left,
      })}
    >
      {children}
      {debug && (
        <div className="map-element-anchor" style={{ top: `${anchor.y * 100}%`, left: `${anchor.x * 100}%` }}>
          <div className="map-element-anchor-crosshair-horizontal" />
          <div className="map-element-anchor-crosshair-vertical" />
        </div>
      )}
    </div>
  );
});

MapElement.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  scaling: PropTypes.func,
  anchor: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }), // The point around which to scale (Ex: {x: 0.5, y: 1} means to snap the bottom center on the `position` and to scale around that point)
  position: PropTypes.shape({
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }), // The position, in CSS, where the element should be located. Use the anchor to snap and scale the visuals around this point.
};

MapElement.defaultProps = {
  scaling: scaleFixed,
  anchor: { x: 0.5, y: 0.5 },
  position: { top: "50%", left: "50%" },
};

MapElement.scaleFixed = scaleFixed;
MapElement.scaleToContent = scaleToContent;
MapElement.scaleToScreen = scaleToScreen;

export default MapElement;
