import PropTypes from "prop-types";
import { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from "react";
import Classes from "../../../helpers/classes";
import Format from "../../../helpers/format";
import Button from "../button";
import ProgressBar from "../progress-bar";
import Video from "../video";

const VideoPlayer = memo(
  forwardRef(
    (
      {
        style,
        className,
        controlsPosition,
        controlsMode,
        controlsAutoHideDelay,
        timeMode,
        showPlayButton,
        showTimeLabel,
        showControls,
        showPausedOverlay,
        showExtraButton,
        enableSeeking,
        pauseTimeout,
        pausedOverlay,
        onExtraButtonClick,
        onProgress: onProgressExternal,
        onLoad: onLoadExternal,
        onPlayStateChange: onPlayStateChangeExternal,
        getApi,
        ...remainingProps
      },
      ref
    ) => {
      const [isPlaying, setIsPlaying] = useState(false);
      const [progress, setProgress] = useState(0);
      const [elapsed, setElapsed] = useState(0);
      const [remaining, setRemaining] = useState(null);
      const [controlsAutoHidden, setControlsAutoHidden] = useState(false);

      const autoHideControlsTimeoutRef = useRef(null);

      const videoApiRef = useRef(null);
      const onGetVideoApi = useCallback(
        (api) => {
          if (getApi) getApi(api);
          videoApiRef.current = api;
        },
        [getApi]
      );

      const resetAutoHideControls = useCallback(() => {
        if (controlsMode !== "auto-hide") return;
        setControlsAutoHidden(false);
        clearTimeout(autoHideControlsTimeoutRef.current);
        autoHideControlsTimeoutRef.current = setTimeout(() => {
          setControlsAutoHidden(true);
        }, controlsAutoHideDelay);
      }, [controlsAutoHideDelay, controlsMode]);

      const stopAutoHideControls = useCallback(() => {
        clearTimeout(autoHideControlsTimeoutRef.current);
        setControlsAutoHidden(false);
      }, []);

      const onPlayerClick = useCallback(() => {
        resetAutoHideControls();
      }, [resetAutoHideControls]);

      const onVideoPlayStateChange = useCallback(
        (isPlaying) => {
          setIsPlaying(isPlaying);

          if (isPlaying && controlsMode === "auto-hide") {
            resetAutoHideControls();
          } else {
            stopAutoHideControls();
          }

          onPlayStateChangeExternal?.(isPlaying);
        },
        [controlsMode, resetAutoHideControls, stopAutoHideControls, onPlayStateChangeExternal]
      );

      const onVideoProgress = useCallback(
        (currentTime, duration) => {
          setProgress(currentTime / duration);
          setElapsed(currentTime);
          setRemaining(duration - currentTime);
          onProgressExternal?.(currentTime, duration);
        },
        [onProgressExternal]
      );

      const onVideoLoad = useCallback(
        (duration) => {
          setRemaining(duration);
          onLoadExternal?.(duration);
        },
        [onLoadExternal]
      );

      const onPauseOverlayButtonClick = useCallback(() => {
        videoApiRef.current.toggle();
        resetAutoHideControls();
      }, [resetAutoHideControls]);

      const onPlayClick = useCallback(() => {
        videoApiRef.current.toggle();
      }, []);

      const onProgressBarSeek = useCallback(
        (ratio) => {
          videoApiRef.current.seekToRatio(ratio);
          resetAutoHideControls();
        },
        [resetAutoHideControls]
      );

      const getTime = useCallback(() => {
        return {
          elapsed,
          remaining,
        }[timeMode];
      }, [elapsed, remaining, timeMode]);

      useImperativeHandle(
        ref,
        () => {
          return videoApiRef.current;
        },
        []
      );

      const renderPausedOverlayInternal = (customPausedOverlay) => {
        return (
          <Button
            className={Classes.build("pause-overlay", { visible: !isPlaying })}
            onClick={onPauseOverlayButtonClick}
          >
            {(() => {
              if (!customPausedOverlay) return <div className="default-paused-overlay" />;
              if (typeof customPausedOverlay === "function") return customPausedOverlay();
              return customPausedOverlay;
            })()}
          </Button>
        );
      };

      const renderControls = (
        controlsPosition,
        controlsMode,
        enableSeeking,
        showPlayButton,
        showExtraButton,
        showTimeLabel,
        onExtraButtonClick
      ) => {
        const time = Math.round(getTime());
        const timeString = Format.time(time * 1000);

        const hidden = (() => {
          if (controlsMode === "hidden") return true;
          if (controlsMode === "visible-always") return false;
          if (controlsMode === "visible-during-playback" && isPlaying) return false;
          if (controlsMode === "auto-hide") return false;
          return true;
        })();

        const actuallyHidden = hidden || controlsAutoHidden;

        const controlsContainerStyles = {
          position: "absolute",
          width: "100%",
          pointerEvents: actuallyHidden ? "none" : "inherit",
        };
        if (controlsPosition === "top") controlsContainerStyles.top = 0;
        if (controlsPosition === "bottom") controlsContainerStyles.bottom = 0;

        return (
          <div
            style={controlsContainerStyles}
            className={Classes.build("controls-container", { "auto-hidden": controlsAutoHidden })}
          >
            <div className={Classes.build("controls", { hidden })}>
              {showPlayButton && (
                <Button className={Classes.build("play-button", { playing: isPlaying })} onClick={onPlayClick} />
              )}
              <ProgressBar
                className="progress-bar"
                interactive={enableSeeking}
                progress={progress}
                onSeek={onProgressBarSeek}
              />
              {showTimeLabel && <div className="time-label">{timeString}</div>}
              {showExtraButton && <Button className="extra-button" onClick={onExtraButtonClick} />}
            </div>
          </div>
        );
      };

      return (
        <div className={Classes.build("ripple-video-player", className)} style={style} onClick={onPlayerClick}>
          <Video
            {...remainingProps}
            onPlayStateChange={onVideoPlayStateChange}
            onProgress={onVideoProgress}
            onLoad={onVideoLoad}
            pauseTimeout={pauseTimeout}
            getApi={onGetVideoApi}
          />
          {showPausedOverlay && renderPausedOverlayInternal(pausedOverlay)}
          {showControls &&
            renderControls(
              controlsPosition,
              controlsMode,
              enableSeeking,
              showPlayButton,
              showExtraButton,
              showTimeLabel,
              onExtraButtonClick
            )}
        </div>
      );
    }
  )
);

VideoPlayer.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  controlsPosition: PropTypes.oneOf(["top", "bottom"]),
  controlsMode: PropTypes.oneOf(["visible-always", "visible-during-playback", "auto-hide", "hidden"]),
  controlsAutoHideDelay: PropTypes.number,
  timeMode: PropTypes.oneOf(["elapsed", "remaining"]),
  showPlayButton: PropTypes.bool,
  showTimeLabel: PropTypes.bool,
  showControls: PropTypes.bool,
  showPausedOverlay: PropTypes.bool,
  showExtraButton: PropTypes.bool,
  enableSeeking: PropTypes.bool,
  pausedOverlay: PropTypes.node,
  onExtraButtonClick: PropTypes.func,
  pauseTimeout: PropTypes.oneOf(["reset", "continue"]),
  getApi: PropTypes.func,
  onProgress: PropTypes.func,
  onLoad: PropTypes.func,
  onPlayStateChange: PropTypes.func,
  // Remaining props are forwarded to the inner `Video` component
};

VideoPlayer.defaultProps = {
  controlsPosition: "bottom",
  controlsMode: "visible-always",
  controlsAutoHideDelay: 3000,
  timeMode: "remaining",
  showPlayButton: true,
  showTimeLabel: true,
  showControls: true,
  showPausedOverlay: false,
  showExtraButton: false,
  enableSeeking: true,
  pauseTimeout: "reset", // It makes sense for the timeout to be paused by default when we use a VideoPlayer (as opposed to a Video)
};

export default VideoPlayer;
