import _ from "lodash";
import Config from "../helpers/config";
import Toast from "../helpers/toast";
import FlagsActions from "../redux/actions/local/flags-actions";

function getTextInfoKey(nodeId, semantic, language) {
  return `${nodeId}.${semantic}.${language}`;
}

export default class Edits {
  static _store = null;
  static _apiClient = null;

  static editedTextInfos = {};
  static unsavedTextInfos = {};

  static setTextInfo(nodeId, semantic, language, textInfo) {
    const key = getTextInfoKey(nodeId, semantic, language);
    Edits.editedTextInfos[key] = textInfo;
    Edits.unsavedTextInfos[key] = textInfo;

    // The check avoids performance issues when setting text infos too often
    if (!Edits._store.getState().flags.modified) Edits._store.dispatch(FlagsActions.setModified(true));
  }

  static getTextInfo(nodeId, semantic, language) {
    return Edits.editedTextInfos[getTextInfoKey(nodeId, semantic, language)];
  }

  static save() {
    const dataSource = Config.data.source;
    if (dataSource !== "server" && dataSource !== "fetch") {
      Toast.error("The data source must be set to 'server' or 'fetch' to save!");
      return;
    }

    // Clone the text infos and instantly empty the object
    // (until we know if the operation succeeded or not)
    const unsavedTextInfos = _.clone(Edits.unsavedTextInfos);
    Edits.unsavedTextInfos = {};

    // Because some TextInfo properties are computed,
    // we must create a POJO for serialization.
    const definition = _.map(_.values(unsavedTextInfos), (t) => ({
      nodeId: t.nodeId,
      semantic: t.semantic,
      language: t.language,
      value: t.editedValue,
      rawValue: t.rawValue,
    }));

    Edits._apiClient
      .updateTexts(definition)
      .then(() => {
        Toast.info(`Saved ${definition.length} edit(s) to server!`);

        const remainingEditCount = _.keys(Edits.unsavedTextInfos).length;
        Edits._store.dispatch(FlagsActions.setModified(remainingEditCount !== 0));
      })
      .catch((error) => {
        Toast.error(`Could not save ${definition.length} edit(s) to server!`);

        // The operation failed, so we put back the TextInfos
        // while skipping the ones that have been modified since.
        _.forOwn(unsavedTextInfos, (textInfo, key) => {
          if (typeof Edits.unsavedTextInfos[key] !== "undefined") return;
          Edits.unsavedTextInfos[key] = textInfo;
        });
      });
  }
}
