// A function that takes navigation options and returns new navigation options with at least a path
// (and optionally more) or nothing, in which case navigation falls back to the originally provided
// navigation options. See `Navigator.navigate()` and `PagesConfigurator` (in Ripple) for details.

export default (nav) => {
  if (nav.type === "transition") return { path: `/transition/${nav.node.id}` };
  if (nav.type === "subchapter") {
    const introVideo = nav.node.optionalMedia("IntroVideo", "FullscreenVideo");
    if (introVideo.exists) {
      return { path: `/transition/${nav.node.id}` };
    } else {
      return { path: `/panorama/${nav.node.id}` };
    }
  }
  if (nav.type === "panorama") return { path: `/panorama/${nav.node.id}` };
  if (nav.type === "sections") return { path: `/sections/${nav.node.id}` };
};
