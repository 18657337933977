import Color from "../../../../helpers/internal/color";

export default function (data, offset) {
  // eslint-disable-next-line no-unused-vars
  const [h, s, l] = Color.rgbToHsl(data[offset], data[offset + 1], data[offset + 2]);
  const [r, g, b] = Color.hslToRgb(h, 0, l);
  data[offset] = r;
  data[offset + 1] = g;
  data[offset + 2] = b;
}
