import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Audio from "../../../helpers/audio";
import Classes from "../../../helpers/classes";
import Env from "../../../helpers/env";
import Link from "../../../helpers/link";
import resource from "../../../helpers/resource";
import Strings from "../../../helpers/strings";
import StringInfo from "../../../logic/info/string-info";
import TextInfo from "../../../logic/info/text-info";
import { TextSrcPropType } from "../../../logic/prop-types";
import { usePrevious } from "../../hooks/use-previous";

/**
 * A component that renders HTML provided as a string.
 * Use this for "content" strings such as descriptions.
 */
const Paragraphs = memo(({ style, className, children, enableLinks, localized }) => {
  children = localized ? Strings.localized(localized) : children;

  const divRef = useRef(null);
  const [contentEditable, setContentEditable] = useState(false);
  const previousContentEditable = usePrevious(contentEditable);

  const isTextInfo = children ? children instanceof TextInfo : false;
  const isStringInfo = children ? children instanceof StringInfo : false;
  const isString = children ? typeof children === "string" : false;
  const isReactNode = !isTextInfo && !isStringInfo && !isString;
  const isEmpty = isTextInfo || isStringInfo ? !children.value || children.value === "" : !children;

  const save = useCallback(() => {
    const textInfo = children; // If called, we can assume that `children` is a TextInfo instance
    textInfo.value = divRef.current.innerHTML;
    textInfo.rawValue = divRef.current.innerText;
  }, [children]);

  const onClick = useCallback(
    (event) => {
      if (!event.altKey || !isTextInfo) return;

      setContentEditable(true);

      // Force-exist to keep the field visible after alt-clicking it (allows editing normally hidden empty texts)
      children.setForceExist(true);
      children.markAsEdited();
    },
    [children, isTextInfo]
  );

  const onInput = useCallback(() => save(), [save]);

  const onBlur = useCallback(() => {
    if (divRef.current.innerText === "") children.setForceExist(false);
    setContentEditable(false);
    save();
  }, [children, save]);

  const onKeyDown = useCallback((event) => {
    if (event.key === "Escape") divRef.current.blur();
  }, []);

  // Focus the content div when entering editing mode
  useEffect(() => {
    if (!previousContentEditable && contentEditable) divRef.current.focus();
  }, [contentEditable, previousContentEditable]);

  // Replace `<a href>` with an onclick handler that delegates links to custom logic
  useLayoutEffect(() => {
    const links = divRef.current.querySelectorAll("a");
    if (!links || links.length === 0) return;
    _.each(links, (a) => {
      if (!a.href) return;
      const href = a.href;
      a.removeAttribute("href");
      a.onclick = () => {
        // Playing an empty audio file before opening links works around a super-weird
        // issue on iOS where opening a SafariViewController without a sound would mute
        // all sound app-wide for about 30s.
        Audio.discrete("silence").play(resource("audio/silence.mp3"));
        Link.open(href);
      };
    });
  }, [children]);

  const actualClassName = Classes.build("ripple-paragraphs", className, {
    empty: isEmpty,
    "links-disabled": !(enableLinks || Env.isRCC), // In RCC, links open in an in-app browser
    "debug-show-paragraphs-editable": isTextInfo,
    "debug-show-paragraphs-uneditable": !isTextInfo,
  });

  const getValue = () => {
    if (isTextInfo) return children.value;
    if (children instanceof StringInfo) return children.value;
    return children;
  };

  const getChildrenDescription = () => {
    if (!children) return "Empty";
    if (isTextInfo) return children.description;
    if (children instanceof StringInfo) return `Res: ${children.key} ${children.language.toUpperCase()}`;
    return "Raw";
  };

  let actualChildren = undefined;
  if (isReactNode) actualChildren = children;

  return (
    <div className={actualClassName} style={style}>
      {<span className="text-info-description">{getChildrenDescription()}</span>}
      <div
        ref={divRef}
        contentEditable={contentEditable}
        onClick={onClick}
        onInput={onInput}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        {...(!isReactNode ? { dangerouslySetInnerHTML: { __html: getValue() } } : {})}
      >
        {actualChildren}
      </div>
    </div>
  );
});

Paragraphs.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.oneOfType([TextSrcPropType, PropTypes.node]),
  enableLinks: PropTypes.bool,
  localized: PropTypes.string,
};

export default Paragraphs;
