import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import Classes from "../../../helpers/classes";
import Localization from "../../../helpers/localization";
import Strings from "../../../helpers/strings";
import Button from "../button";

// A button that cycles between the available languages
// and updates its visual state to reflect the current language
class LanguageButton extends PureComponent {
  static propTypes = {
    delegateRenderContent: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    return (
      <Button {...this.props} className={Classes.build("language", this.props.className)} onClick={this.onClick}>
        {this.renderContent()}
      </Button>
    );
  }

  renderContent = () => {
    if (this.props.delegateRenderContent)
      return this.props.delegateRenderContent(Localization.getCurrentLanguage(), Localization.getNextLanguage());
    return Strings.localized("LanguageButtonTitle", Localization.getNextLanguage());
  };

  onClick = () => {
    Localization.switchToNextLanguage();
  };
}

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(LanguageButton);
