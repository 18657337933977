import { PureComponent } from "react";
import PropTypes from "prop-types";
import { DragSource } from "react-dnd";

import Classes from "../../../../helpers/classes";
import Styles from "../../../../helpers/styles";

const source = {
  beginDrag(props) {
    return { id: props.id, group: props.group };
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class Droppable extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    isDragging: PropTypes.bool, // Provided by react-dnd
    children: PropTypes.node, // [optional] something that is always rendered (the "background" of the dropzone)
    id: PropTypes.any.isRequired, // The id of the droppable, used to determine droppability on a specific dropzone and more
    hasDropped: PropTypes.bool.isRequired, // Provided externally, set to true to indicate that the droppable has been dropped somewhere
    renderEmptyLayer: PropTypes.func, // Something to render ONLY when the droppable is empty (is dragging or has dropped)
    renderForegroundLayer: PropTypes.func, // The visual item that will be dragged
    onTouch: PropTypes.func, // Called as soon as the item is touched (mouse down or touch start)
    onDragStart: PropTypes.func, // Callback for when the droppable is picked up (dragged)
    onDragEnd: PropTypes.func, // Callback for when the droppable is released (regardless of if it's dropped on a dropzone or not)
    group: PropTypes.string, // Specify a group name to group a bunch of Droppables and Dropzones together (prevents drags across groups)
  };

  static defaultProps = {
    renderEmptyLayer: () => null,
    renderForegroundLayer: () => null,
    group: "all",
  };

  // It's especially important to use styles to hide things instead of using
  // conditional rendering because if the element where the touch start event is
  // initially emitted disappears, the browser stops emitting touch move events,
  // freezing the drag in place.
  getStylesForVisibleIf(isVisible) {
    return {
      visibility: isVisible ? "visible" : "hidden",
    };
  }

  render() {
    /* eslint-disable react/prop-types */
    const { connectDragSource, isDragging } = this.props;
    /* eslint-enable react/prop-types */
    const isEmpty = isDragging || this.props.hasDropped;
    const ownStyles = { pointerEvents: isEmpty ? "none" : "inherit" };

    return connectDragSource(
      <div
        className={Classes.build("ripple-droppable", "debug-show-area", this.props.className)}
        style={Styles.merge(this.props.style, ownStyles)}
        onMouseDown={this.onTouchOrMouseDown}
        onTouchStart={this.onTouchOrMouseDown}
      >
        <div className="drop-content" style={{ pointerEvents: isEmpty ? "none" : "inherit" }}>
          <div className="drop-layer">{this.props.children}</div>
          <div className="drop-layer" style={this.getStylesForVisibleIf(isEmpty)}>
            {this.props.renderEmptyLayer()}
          </div>
          <div className="drop-layer" style={this.getStylesForVisibleIf(!isEmpty)}>
            {this.props.renderForegroundLayer()}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isDragging && !prevProps.isDragging) this.props.onDragStart?.(this.props.id);
    if (!this.props.isDragging && prevProps.isDragging) this.props.onDragEnd?.(this.props.id);
  }

  onTouchOrMouseDown = () => {
    if (this.props.onTouch) this.props.onTouch(this.props.id);
  };
}

export default DragSource("droppable", source, collect)(Droppable);
