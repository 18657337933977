import { Image, Page as RipplePage, staggerStep, Text, VideoPlayer } from "ripple";
import styled from "styled-components";
import { accent01Color, StandardButton } from "../../common";

export const Page = styled(RipplePage)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FullscreenVideoPlayer = styled(VideoPlayer).attrs((props) => ({ scaling: "fill" }))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .controls {
    padding: 0;
    margin: 0;
    margin-right: 38vmin;
    margin-bottom: 4vmin;
    background-color: transparent;
    border: none;
    .ripple-button {
      margin: 0 2vw;
    }
    .progress-bar {
      margin: 0;
    }
    .track {
      height: 1vmin;
      background-color: rgba(0, 0, 0, 0.5);
    }
    .progress {
      background-color: ${accent01Color};
    }
    .play-button {
      font-size: 3vmin;
      color: ${accent01Color};
      .hotspot {
        margin: -4vmin;
      }
    }
    .time-label {
      margin: 0;
      margin-left: 2vw;
      font-size: 2vmin;
    }
  }
`;

export const Decoration = styled(Image).attrs((props) => ({ fadeIn: "always" }))`
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 80%;
  pointer-events: none;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export const Splitter = staggerStep({})(
  styled.div`
    height: 1px;
    background-color: white;
    width: 100%;
    margin-top: 2vh;
    margin-bottom: 1vh;
  `
);

export const Title = staggerStep({ transition: "slide-in-and-fade-from-bottom" })(
  styled(Text)`
    font-size: 8vmin;
    font-family: "Atma-Bold";
    text-align: center;
  `
);

export const Subtitle = staggerStep({ transition: "slide-in-and-fade-from-top" })(
  styled(Text)`
    font-size: 3.5vmin;
    font-family: "Atma-Bold";
    text-align: center;
  `
);

export const SkipButton = staggerStep({ transition: "slide-in-and-fade-from-right" })(styled(StandardButton)``);

export const VideoNotice = styled(StandardButton)`
  margin-top: 4vmin;
  font-size: 3vmin;
  color: white;
  max-width: 100vw;
  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
  text-align: center;
`;

export const SkipButtonContainer = styled.div`
  position: absolute;
  bottom: calc(3vmin + env(safe-area-inset-bottom));
  right: calc(3vmin + env(safe-area-inset-right));

  transition: opacity 500ms ease-in-out;
  opacity: ${(props) => (props.show ? 1 : 0)};
`;
