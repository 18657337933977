import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Config, Image, Localization, Navigator, resource, Strings, useData } from "ripple";
import {
  BigText,
  LeftColumn,
  MenuContent,
  MenuItem,
  MenuItemArrow,
  MenuItemColumn,
  MenuPanel,
  MenuRoot,
  MenuSplitter,
  RightColumn,
  SmallText,
  ToggleButton,
} from "./styled";

const Menu = ({ show, ...rest }) => {
  const client = useData((data) => data.root);

  const [showMenu, setShowMenu] = useState(false);

  const createOnChapterClick = (chapter) => () => {
    Navigator.navigate({ node: chapter, type: "transition" });
    setShowMenu(false);
  };

  const createOnSubchapterClick = (subchapter) => () => {
    Navigator.navigate({ node: subchapter, type: "subchapter" });
    setShowMenu(false);
  };

  const onToggleButtonClick = useCallback(() => setShowMenu(!showMenu), [showMenu]);

  const onLanguageButtonClick = useCallback(() => {
    Localization.switchToNextLanguage();
  }, []);

  const onEndClick = useCallback(() => {
    location.href = client.wantedText("BackToSiteLinkUrl").value;
  }, [client]);

  const renderChapter = (chapter) => {
    const isMultiChapter = chapter.semantic === "MultiChapitre";
    return (
      <>
        <MenuItem key={chapter.id} onClick={createOnChapterClick(chapter)}>
          <MenuItemArrow />
          <MenuItemColumn>
            <SmallText>{chapter.wantedText("Subtitle")}</SmallText>
            <BigText>{chapter.wantedText("Title")}</BigText>
          </MenuItemColumn>
        </MenuItem>
        {isMultiChapter &&
          chapter.children.map((subchapter) => (
            <MenuItem key={subchapter.id} onClick={createOnSubchapterClick(subchapter)} indent>
              <MenuItemArrow />
              <MenuItemColumn>
                <SmallText>{subchapter.wantedText("Title")}</SmallText>
              </MenuItemColumn>
            </MenuItem>
          ))}
      </>
    );
  };

  return (
    <MenuRoot show={show} {...rest}>
      <MenuPanel reveal={showMenu}>
        <MenuContent show={showMenu}>
          <LeftColumn></LeftColumn>
          <RightColumn>
            {client.children.map(renderChapter)}
            <MenuSplitter />
            {Config.language.supported.length > 1 && (
              <>
                <MenuItem onClick={onLanguageButtonClick}>
                  <MenuItemArrow />
                  <MenuItemColumn>
                    <BigText>{Strings.localized("LanguageName", Localization.getNextLanguage())}</BigText>
                  </MenuItemColumn>
                </MenuItem>
              </>
            )}
            <MenuItem onClick={onEndClick}>
              <MenuItemArrow />
              <MenuItemColumn>
                <BigText>{client.wantedText("BackToSiteLinkTitle")}</BigText>
              </MenuItemColumn>
            </MenuItem>
          </RightColumn>
        </MenuContent>
      </MenuPanel>
      <ToggleButton onClick={onToggleButtonClick}>
        <Image src={resource(`images/Button-Hamburger${showMenu ? "-Close" : ""}.svg`)} />
      </ToggleButton>
    </MenuRoot>
  );
};

Menu.propTypes = {
  show: PropTypes.bool,
};

Menu.defaultProps = {
  show: true,
};

export default Menu;
