import _ from "lodash";
import PropTypes from "prop-types";
import { cloneElement, createRef, PureComponent } from "react";
import Classes from "../../../helpers/classes";
import Config from "../../../helpers/config";
import Log from "../../../helpers/log";
import { isResourcePath } from "../../../helpers/resource";
import Styles from "../../../helpers/styles";
import Timeout from "../../../helpers/timeout";
import MediaInfo from "../../../logic/info/media-info";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { VideoSharedPlaybackState } from "../../hooks/specialized/use-video-shared-playback-state";
import Image from "../image";

function getInitializedSharedPlaybackState(sharedPlaybackState) {
  return sharedPlaybackState && sharedPlaybackState.isInitialized ? sharedPlaybackState : null;
}

function updateSharedPlaybackState(state, modificator) {
  if (!state) return;
  state.isInitialized = true;
  modificator(state);
}

class Video extends PureComponent {
  static propTypes = {
    src: MediaSrcPropType,
    className: PropTypes.string,
    style: PropTypes.object,
    scaling: PropTypes.oneOf(["fit", "fill", "stretch"]),
    autoPlay: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    thumbnail: MediaSrcPropType,
    fadeInOnPlay: PropTypes.bool,
    playsInline: PropTypes.bool, // If false, the video enters fullscreen on playback on mobile
    onLoad: PropTypes.func, // Common interface for media readiness
    onStart: PropTypes.func,
    onEnd: PropTypes.func,
    onProgress: PropTypes.func,
    onPlayStateChange: PropTypes.func,
    seekToStartOnEnded: PropTypes.bool,
    pauseTimeout: PropTypes.oneOf(["reset", "continue"]), // Set to a valid timeout resume mode (see Timeout implementation)
    sharedPlaybackState: PropTypes.instanceOf(VideoSharedPlaybackState), // When a new Video is displayed with the same statePreservationKey as a previous Video (app-wide), the new Video restores its state from the data contained the state cache for this key. Useful to preserve playback state when using a maximizer.
    getApi: PropTypes.func,
  };

  static defaultProps = {
    scaling: "fit",
    autoPlay: false,
    loop: false,
    fadeInOnPlay: true,
    playsInline: true,
    seekToStartOnEnded: true,
    // Note: The "muted" default comes from Config (see this.render)
  };

  state = { isLoaded: false, isPlaying: false, isAtStart: true };

  constructor(props) {
    super(props);

    this.video = createRef();
  }

  render() {
    if (this.props.getApi) this.props.getApi(this.createApi());
    const className = Classes.build("ripple-video", this.props.className);
    const videoClassName = Classes.build(this.props.scaling, { loaded: this.state.isLoaded });
    const loop = this.props.loop;
    const muted = this.props.muted || Config.audio.global.muted;
    const videoStyle = this.props.fadeInOnPlay ? { transition: "opacity 0.250s" } : {};

    const srcProps = {};
    const url = this.getUrl();
    if (url) srcProps.src = url;

    return (
      <div className={className} style={Styles.merge(this.props.style)}>
        {<span className="media-info-description">{this.getSrcDescription()}</span>}
        {cloneElement(
          /* eslint-disable ripple/callback-naming */
          <video
            key={this.props.src} // Force creating a new <video> on each src change
            ref={this.video}
            className={videoClassName}
            style={videoStyle}
            preload="none"
            playsInline={this.props.playsInline}
            onCanPlay={this.onCanPlay}
            onPlay={this.onPlay}
            onPause={this.onPause}
            onEnded={this.onEnded}
            onError={this.onError}
            onStalled={this.onStalled}
            onAbort={this.onAbort}
            loop={loop}
            muted={muted}
          />,
          /* eslint-enable ripple/callback-naming */
          srcProps
        )}
        {this.props.thumbnail && (
          <Image
            className={Classes.build("thumbnail", { visible: !this.state.isPlaying && this.state.isAtStart })}
            src={this.props.thumbnail}
            scaling={this.props.scaling}
          />
        )}
      </div>
    );
  }

  componentDidMount() {
    this.resetIfSrcChanged();
  }

  componentDidUpdate() {
    this.resetIfSrcChanged();
  }

  componentWillUnmount() {
    // If we don't remove the video src, it will keep downloading
    // after the component is unmounted and will spawn another download
    // when shown again! We don't want that.
    const video = this.video.current;
    if (video) {
      video.removeAttribute("src");
      video.load(); // Just forces the video element to read the src attribute again
    }
    this.setNotPlaying(/* Unmounting */ true);
  }

  getUrl = () => {
    const src = this.props.src;

    if (!Config.load.videos) return null;
    if (!src) return null;

    if (typeof src === "string") return src;
    if (src instanceof MediaInfo) return src.url;

    return null;
  };

  getSrcDescription = () => {
    const src = this.props.src;
    if (!src) return "Empty";
    if (src && src instanceof MediaInfo) return src.description;
    if (isResourcePath(src)) return `Res: ${_.last(src.split("/"))}`;
    return "Url";
  };

  resetIfSrcChanged = () => {
    const url = this.getUrl();
    if (url === this.currentUrl) return;
    this.currentUrl = url;
    this.setState({ isLoaded: false });
    this.setNotPlaying();
    this.video.current.load();
  };

  onCanPlay = () => {
    // Do things once per src change. Checking against the previous
    // "can play" src is important to avoid multiple unexpected `onLoad` calls
    // because the video `canplay` event can be called multiple times on slow
    // networks where the video stalls and buffering re-occurs.
    const srcChanged = this.props.src !== this.lastCanPlaySrc;
    const sharedPlaybackState = getInitializedSharedPlaybackState(this.props.sharedPlaybackState);
    if (srcChanged) {
      if (this.props.onLoad) this.props.onLoad(this.video.current.duration);
      if (sharedPlaybackState) this.seekToTime(sharedPlaybackState.progress);
      if ((!sharedPlaybackState && this.props.autoPlay) || (sharedPlaybackState && sharedPlaybackState.isPlaying)) {
        // The `isPlaying` check is a workaround for the "The play() request was interrupted by a call to pause()" exception.
        // Solution: https://stackoverflow.com/a/36898221/167983
        const isPlaying =
          this.video.currentTime > 0 && !this.video.paused && !this.video.ended && this.video.readyState > 2;
        if (!isPlaying) this.play();
      }
    }
    this.setState({ isLoaded: true });
    this.lastCanPlaySrc = this.props.src;
  };

  onPlay = () => {
    this.hasStartedPlaying = true;
    if (this.proceedWithTransition) this.proceedWithTransition();
    if (this.props.onStart) this.props.onStart();
    this.setPlaying();
  };

  onPause = () => {
    this.setNotPlaying();
  };

  // eslint-disable-next-line ripple/callback-naming
  onEnded = () => {
    const video = this.video.current;
    if (this.props.seekToStartOnEnded && !this.props.loop) {
      this.video.current.pause();
      video.currentTime = 0;
      this.setState({ isAtStart: true });
    }
    this.updateProgress(this.props.seekToStartOnEnded ? 0 : video.duration, video.duration);
    if (this.props.onEnd) this.props.onEnd();
    this.setNotPlaying();
  };

  onError = (error) => {
    Log.error("Video: Error ", error.message, error);
  };

  // eslint-disable-next-line ripple/callback-naming
  onStalled = () => {
    Log.warn("Video: Stalled because media data is not available");
  };

  onAbort = () => {
    Log.warn("Video: Loading was aborted");
  };

  onTimeUpdate = () => {
    const video = this.video.current;
    const currentTime = video.currentTime;
    this.updateProgress(currentTime, video.duration);
    if (currentTime === 0 && !this.state.isAtStart) this.setState({ isAtStart: true });
    if (currentTime !== 0 && this.state.isAtStart) this.setState({ isAtStart: false });
  };

  updateProgress = (progress, duration) => {
    if (this.props.onProgress) this.props.onProgress(progress, duration);
    updateSharedPlaybackState(this.props.sharedPlaybackState, (state) => (state.progress = progress));
  };

  pauseTimeout = () => {
    if (!this.props.pauseTimeout) return;
    this.timeoutHandle = Timeout.pause("Video");
  };

  resumeTimeout = () => {
    if (!this.timeoutHandle) return;
    this.timeoutHandle.release();
    this.timeoutHandle = null;
  };

  setPlaying = () => {
    if (this.isPlaying) return;
    this.isPlaying = true;

    this.setState({ isPlaying: true });
    if (this.props.onPlayStateChange) this.props.onPlayStateChange(true);
    updateSharedPlaybackState(this.props.sharedPlaybackState, (state) => (state.isPlaying = true));

    this.intervalToken = setInterval(this.onTimeUpdate, 50);
    this.pauseTimeout();
  };

  setNotPlaying = (unmounting = false) => {
    if (!this.isPlaying) return;
    this.isPlaying = false;

    this.setState({ isPlaying: false });
    if (this.props.onPlayStateChange) this.props.onPlayStateChange(false);
    if (!unmounting) updateSharedPlaybackState(this.props.sharedPlaybackState, (state) => (state.isPlaying = false));

    clearInterval(this.intervalToken);
    this.resumeTimeout();
  };

  play = () => {
    try {
      this.video.current.play();
    } catch (error) {
      Log.error(error);
    }
  };

  pause = () => {
    this.video.current.pause();
  };

  toggle = () => {
    const video = this.video.current;
    this.isPlaying ? video.pause() : video.play();
  };

  seekToTime = (timeInSeconds) => {
    this.video.current.currentTime = timeInSeconds;
    if (this.props.onProgress) this.props.onProgress(timeInSeconds, this.video.current.duration);
  };

  seekToRatio = (ratio) => {
    const timeInSeconds = ratio * this.video.current.duration;
    this.seekToTime(timeInSeconds);
    if (this.props.onProgress) this.props.onProgress(timeInSeconds, this.video.current.duration);
  };

  getTime = () => {
    return this.video.current.currentTime;
  };

  getRatio = () => {
    return this.video.current.currentTime / this.video.current.duration;
  };

  getIsPlaying = () => {
    return this.isPlaying;
  };

  createApi = () => {
    return {
      pause: this.pause,
      play: this.play,
      toggle: this.toggle,
      seekToTime: this.seekToTime,
      seekToRatio: this.seekToRatio,
      getTime: this.getTime,
      getRatio: this.getRatio,
      getIsPlaying: this.getIsPlaying,
    };
  };
}

export default Video;
