import { Button, Image, Text } from "ripple";
import styled from "styled-components";

export const simbiozBlue = "#00a5e3";
export const vert05Color = "#197D3F";
export const logoColor = "#079445";
export const backPanelColor = "#F5EFE9";
export const accent01Color = "#DF9425";

export const StandardButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5vmin 4vmin;
  border-radius: 6vmin;
  border: 2px solid ${backPanelColor};

  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
`;

export const StandardButtonText = styled(Text)`
  color: ${backPanelColor};
  font-size: 3vmin;
`;

export const StandardRightButtonIcon = styled(Image)`
  margin-left: 1vmin;
  height: 3vmin;
  width: 3vmin;
`;

export const StandardLeftButtonIcon = styled(Image)`
  margin-right: 1vmin;
  height: 3vmin;
  width: 3vmin;
`;
