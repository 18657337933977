import { Html } from "@react-three/drei";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Coordinates from "../../../helpers/coordinates";
import { useDebug } from "../../hooks/use-debug";
import { useEditing } from "../../hooks/use-editing";

const DISTANCE = 400; // It's a constant because the panorama dome size never changes

const Pin = ({
  theta, // Equator angle in radians around the y (up) axis
  phi, // Polar angle in radians from the y (up) axis
  children,
}) => {
  const [position, setPosition] = useState([0, 0, 0]);
  const debug = useDebug();
  const editing = useEditing();

  useEffect(() => {
    const cartesianCoordinates = Coordinates.sphericalToCartesian(DISTANCE, theta, phi);
    setPosition([cartesianCoordinates.x, cartesianCoordinates.y, cartesianCoordinates.z]);
  }, [phi, theta]);

  return (
    <>
      {/* Clone the children to position them in the enclosing dome according to our internal calculations */}
      {React.Children.map(children, (c) =>
        React.cloneElement(c, { position, style: { ...c.props.style, pointerEvents: editing ? "none" : "inherit" } })
      )}
      {debug && (
        <>
          <mesh position={position}>
            <sphereBufferGeometry attach="geometry" args={[4, 32, 16]}>
              <meshBasicMaterial attach="material" color="red" />
            </sphereBufferGeometry>
            <Html
              style={{
                width: 200,
                transform: "translate3d(-50%, 100%, 0)",
                pointerEvents: "none",
              }}
            >
              <p className="debug">
                <b>Theta:</b> {theta.toFixed(4)} <b>Phi:</b> {phi.toFixed(4)}
              </p>
              <p className="debug">
                <b>X:</b> {position[0].toFixed(4)} <b>Y:</b> {position[1].toFixed(4)} <b>Z:</b> {position[2].toFixed(4)}
              </p>
            </Html>
          </mesh>
        </>
      )}
    </>
  );
};

Pin.propTypes = {
  theta: PropTypes.number,
  phi: PropTypes.number,
  children: PropTypes.node,
};

Pin.defaultProps = {
  theta: 0,
  phi: 0,
  children: (
    <mesh>
      <sphereBufferGeometry attach="geometry" args={[8, 32, 16]}>
        <meshBasicMaterial attach="material" color="red" />
      </sphereBufferGeometry>
    </mesh>
  ),
};

export default Pin;
