import { useEffect, useState } from "react";
import Geolocation from "../../helpers/geolocation";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Get regular updates with the user's geolocation.
 */
export const useGeolocation = (onUpdate = () => {}, onError = () => {}) => {
  const mostRecentUpdateCallbackRef = useLatestRef(onUpdate);
  const mostRecentErrorCallbackRef = useLatestRef(onError);

  const [geolocation, setGeolocation] = useState(null);

  useEffect(() => {
    const mostRecentUpdateCallback = (...args) => {
      setGeolocation(...args);
      mostRecentUpdateCallbackRef.current(...args);
    };
    const mostRecentErrorCallback = (...args) => mostRecentErrorCallbackRef.current(...args);
    const subscription = Geolocation.locationSubscribe(mostRecentUpdateCallback, mostRecentErrorCallback);
    return () => Geolocation.locationUnsubscribe(subscription);
  }, [mostRecentErrorCallbackRef, mostRecentUpdateCallbackRef]);

  return geolocation;
};
