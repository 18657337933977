import { memo, useRef, useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";
import FilePicker from "../../../components/file-picker";
import Canvas from "../../../components/canvas";
import Rect from "../../../../types/rect";
import Load from "../../../../helpers/load";
import resource from "../../../../helpers/resource";

const PageComponent = memo(() => {
  const canvasRef = useRef(null);

  const overlayImageRef = useRef(null);
  useEffect(() => {
    Load.image(resource("images/demo/mail-camera-overlay.png")).then((image) => (overlayImageRef.current = image));
  }, []);

  const [image, setImage] = useState(null);
  useEffect(() => {
    if (!image) return;

    const canvas = canvasRef.current;

    const imageRect = new Rect(0, 0, image.width, image.height);
    const canvasRect = new Rect(0, 0, canvas.clientWidth, canvas.clientHeight);
    const fill = imageRect.fillIn(canvasRect);

    const context = canvas.getContext("2d");
    context.drawImage(image, fill.x, fill.y, fill.width, fill.height);
    if (overlayImageRef.current) context.drawImage(overlayImageRef.current, 0, 0, canvasRect.width, canvasRect.height);
  }, [image]);

  const onPick = useCallback((files) => {
    files[0].readAsImage().then(setImage);
  }, []);

  return (
    <Page className="demo file-picker ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">File Picker</Text>
          <p className="standard description">
            The file picker allows either choosing a file from the filesystem or taking a photo with the camera (on
            mobile), depending on the props provided.
          </p>
          {__DEV__ && Env.isRCC && (
            <p className="standard description important">
              NOTE: Photo-taking does not work in the Android web view as of December 2019.
            </p>
          )}
          {__DEV__ && Env.isRCC && (
            <p className="standard description important">
              NOTE: In DEV, hot module reloading might trigger a full page reload if you stay more than approximately 5
              seconds in the native camera view. This problem is absent in packaged apps because hot module reloading is
              out of the picture, however in RCC iOS the OS might terminate the web view process if the camera is open
              for too long (~20+ seconds), depending on OS memory pressure. In RCC Android, the OS might kill the whole
              Cordova activity instead, in the same situation.
            </p>
          )}
          <div className="case">
            <Canvas ref={canvasRef} />
            <FilePicker accept="image/*" onPick={onPick}>
              Choose Photo
            </FilePicker>
            {Env.isMobile && (
              <FilePicker accept="image/*" capture="user" onPick={onPick}>
                Take Photo (Front-Facing)
              </FilePicker>
            )}
            {Env.isMobile && (
              <FilePicker accept="image/*" capture="environment" onPick={onPick}>
                Take Photo (Rear-Facing)
              </FilePicker>
            )}
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

export default PageComponent;
