import _ from "lodash";

/**
 * This layout allows you to specify an hardcoded number of columns for each row,
 * based on the total number of children to display.
 *
 * An example definition might be:
   ```
   {
     7: [2, 3, 2], // For seven items, put two on the first row, three on the second row and two on the third row
     8: [2, 4, 2], // For eight items, put two on the first row, four on the second row and two on the third row
   }
   ```
 */
export default (definition) => {
  // Validate the definition to make sure the total number of columns matches the child count
  // (ensure that the sum of integers in the array matches the key).
  _.forOwn(definition, (value, key) => {
    const expected = parseInt(key);
    const actual = _.sum(value);
    if (actual !== expected)
      throw new Error(
        `manualGridLayout: The sum of columns specified for ${expected} items does not add up to ${expected}`
      );
  });

  // Return the layout
  return (count) => {
    const columnsPerRow = definition[count];
    if (!columnsPerRow) return [];
    return columnsPerRow;
  };
};
