import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import Beacons from "../../helpers/beacons";
import { useLatestRef } from "./internal/use-latest-ref";

const MINIMUM_RSSI = -100;

function getKey(beacon) {
  return `${beacon.uuid}:${beacon.major}:${beacon.minor}`;
}

function areBeaconInfosEqual(a, b) {
  if (!a || !b) return false;
  return a.beacon.uuid === b.beacon.uuid && a.beacon.major === b.beacon.major && a.beacon.minor === b.beacon.minor;
}

// First pass : Collecting beacon data in BeaconInfos and calculating an average signal strength
const MAX_HISTORY_ENTRIES = 6;
const SUCCESSIVE_UNDETECTED_COUNT_BEFORE_ELISION = 4;

// Second pass : Filter out beacons for final consideration based on additional heuristics
const MIN_SIGNAL_STRENGTH_BEFORE_CONSIDERED = -96;
const MIN_HISTORY_BEFORE_CONSIDERED = 3;
const MIN_BETTER_BEFORE_CONSIDERED = 3;

class BeaconInfo {
  beacon = null;
  history = [];
  average = MINIMUM_RSSI;
  better = 0;
  undetected = 0;

  constructor(beacon) {
    this.beacon = beacon;
  }

  addToHistory(rssi) {
    // Keep a maximum number of history entries
    this.history.push(rssi);
    if (this.history.length > MAX_HISTORY_ENTRIES) this.history.shift();

    // Update the average
    this.average = _.sum(this.history) / this.history.length;
  }
}

/**
 * Detect and report the closest beacon matching the provided UUID, major and minor,
 * with some hysteresis (the resulting beacon is meant to be used as-is in an app).
 */
export const useClosestBeacon = (uuid, major = null, minor = null, onDebug) => {
  const mostRecentDebugCallbackRef = useLatestRef(onDebug);
  const beaconInfosRef = useRef({});
  const closestBeaconInfoRef = useRef(null);

  const [actualClosestBeacon, setActualClosestBeacon] = useState(null);

  useEffect(() => {
    const subscription = Beacons.subscribe(uuid, major, minor, (beacons) => {
      const beaconInfos = beaconInfosRef.current;

      // Add or update beacon infos for the detected beacons
      _.each(beacons, (beacon) => {
        const key = getKey(beacon);

        // Obtain or create a BeaconInfo for the detected beacon
        const existingBeaconInfo = beaconInfos[key];
        const beaconInfo = existingBeaconInfo || new BeaconInfo(beacon);
        beaconInfos[key] = beaconInfo;

        // Add the current signal strength to the history
        // Do not add if the proximity is unknown (no relevant RSSI information)
        if (beacon.proximity !== "ProximityUnknown") beaconInfo.addToHistory(beacon.rssi);
      });

      _.each(beaconInfos, (beaconInfo, key) => {
        if (
          // If there is no current closest, this one is automatically better.
          !closestBeaconInfoRef.current ||
          // If the closest beacon disappeared from the list of considered beacons (physically out of range),
          // then other beacons are automatically better. Without this, if a closest beacon with a
          // strong signal is removed from the list of beacon infos (without its average
          // lowering because we're now ignoring "not-detected" values for average calculation),
          // then it could never be replaced by any other beacon unless it had a better average.
          // The previously recorded closest beacon with the highest average would then get "stuck"
          // as the closest forever even if its physical beacon has disappeared for a while.
          !_.values(beaconInfos).includes(closestBeaconInfoRef.current) ||
          // Finally, if the average of the beacon is higher than the current closest beacon, consider it better.
          beaconInfo.average > closestBeaconInfoRef.current.average
        ) {
          beaconInfo.better += 1;
        } else {
          beaconInfo.better = 0;
        }

        // If a BeaconInfo's beacon isn't detected at all track that fact without affecting the beacon's average
        const beacon = _.find(beacons, (b) => getKey(b) === key);
        beaconInfo.undetected = !beacon ? beaconInfo.undetected + 1 : 0;

        // If the beacon has not been detected for a while, consider it fully gone for now
        if (beaconInfo.undetected >= SUCCESSIVE_UNDETECTED_COUNT_BEFORE_ELISION) delete beaconInfos[key];
      });

      // Get the closest beacon candidate based on its average
      const closestBeaconInfo = _.maxBy(_.values(beaconInfos), (beaconInfo) => beaconInfo.average) || null;

      if (!areBeaconInfosEqual(closestBeaconInfo, closestBeaconInfoRef.current)) {
        if (!closestBeaconInfo) {
          setActualClosestBeacon(null);
          closestBeaconInfoRef.current = null;
        } else {
          // The beacon has been "better" than the current one for a while
          const betterThanCurrent = closestBeaconInfo.better >= MIN_BETTER_BEFORE_CONSIDERED;

          // There is only one visible beacon
          const onlyOneCandidate = _.keys(beaconInfos).length === 1;

          // There is no closest beacon right now (first candidate "closest beacon")
          const firstCandidate = !closestBeaconInfoRef.current;

          // We always require beacons to have a minimum of 3 history values to have a better idea of the beacon's actual signal
          const hasMinimumSignalHistory = closestBeaconInfo.history.length >= MIN_HISTORY_BEFORE_CONSIDERED;

          // Beacons with an average below a certain signal strength are ignored to avoid stray beacons
          // (for example, on another floor) from becoming closest when there is no current closest beacon
          const hasMinimumAverageSignalStrength = closestBeaconInfo.average >= MIN_SIGNAL_STRENGTH_BEFORE_CONSIDERED;

          if (
            (betterThanCurrent || onlyOneCandidate || firstCandidate) &&
            hasMinimumSignalHistory &&
            hasMinimumAverageSignalStrength
          ) {
            setActualClosestBeacon(closestBeaconInfo.beacon);
            closestBeaconInfoRef.current = closestBeaconInfo;
          }
        }
      }

      if (mostRecentDebugCallbackRef.current) {
        const debug = _.map(beaconInfos, (info) => {
          return {
            uuid: info.beacon.uuid,
            major: info.beacon.major,
            minor: info.beacon.minor,
            better: info.better,
            undetected: info.undetected,
            average: info.average,
            history: info.history.join(","),
            closest: info === closestBeaconInfo,
          };
        });

        const mostRecentDebugCallback = (...args) => mostRecentDebugCallbackRef.current(...args);
        mostRecentDebugCallback(debug);
      }
    });
    return () => Beacons.unsubscribe(subscription);
  }, [mostRecentDebugCallbackRef, uuid, major, minor]);

  return actualClosestBeacon;
};
