import create from "zustand";

const useStore = create((set) => ({
  tutorialShown: false,
  setTutorialShown: () => set({ tutorialShown: true }),

  viewed: [],
  addViewed: (node) => set((state) => ({ viewed: [...state.viewed, node] })),
  resetViewed: () => set({ viewed: [] }),
}));

export default useStore;
