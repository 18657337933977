import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, tap } from "rxjs/operators";
import Log from "../helpers/log";

function isStartupPage(path) {
  return path === "" || path === "/";
}

function observeDataUpdateWhileOnStartupPage(storeState$) {
  return storeState$.pipe(
    filter((state) => !!state.data),
    distinctUntilChanged((state) => state.data),
    filter((state) => isStartupPage(state.router.location.pathname)),
    tap((state) => Log.debug(`Store Observables: Data update while on startup page`))
  );
}

function observeStatusChange(storeState$) {
  return storeState$.pipe(distinctUntilChanged((state) => state.status));
}

function observeFlagChange(storeState$) {
  return (flagName) =>
    storeState$.pipe(
      map((state) => state.flags[flagName]),
      distinctUntilChanged()
    );
}

function observeDataAutoReloadFlagChanged(storeState$) {
  return storeState$.pipe(
    map((state) => state.flags.dataAutoReload),
    distinctUntilChanged()
  );
}

function observeNavigation(storeState$) {
  return storeState$.pipe(
    map((state) => state.router.location.pathname),
    distinctUntilChanged()
  );
}

function observeNavigationToStartupPageAfterAppStart(storeState$) {
  return storeState$.pipe(
    map((state) => state.router.location.pathname),
    distinctUntilChanged(),
    pairwise(),
    filter((pathnames) => {
      const previousPath = pathnames[0];
      const currentPath = pathnames[1];
      return !isStartupPage(previousPath) && isStartupPage(currentPath);
    }),
    tap((state) => Log.debug(`Store Observables: Entered startup page after app start`))
  );
}

const StoreObservables = {
  initialize: function (store) {
    const storeState$ = Observable.create(function (observer) {
      store.subscribe(() => observer.next(store.getState()));
    });
    return {
      storeState$: storeState$,
      dataUpdateWhileOnStartupPage$: observeDataUpdateWhileOnStartupPage(storeState$),
      navigation$: observeNavigation(storeState$),
      navigationToStartupPageAfterAppStart$: observeNavigationToStartupPageAfterAppStart(storeState$),
      statusChange$: observeStatusChange(storeState$),
      dataAutoReloadFlagChanged$: observeDataAutoReloadFlagChanged(storeState$),
      flagChange$: observeFlagChange(storeState$),
    };
  },
};

export default StoreObservables;
