import { transparentize } from "polished";
import { TransitionGroup } from "react-transition-group";
import { Button, Text } from "ripple";
import styled from "styled-components";
import { simbiozBlue } from "../../common";

export const GracePopupTransitionGroup = styled(TransitionGroup)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);

  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
`;

export const Popup = styled.div`
  position: relative;

  background-color: white;
  border-radius: 20px;
  min-width: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 7px 50px 0px rgba(0, 0, 0, 0.15);

  padding: 60px;

  animation: appear-floating;
  animation-duration: 500ms;
  animation-delay: 200ms;
  animation-fill-mode: forwards;

  opacity: 0;
  transform: scale(0.3) translate(0px, 50px);
`;

export const Title = styled(Text)`
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
`;

export const Countdown = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: #cccccc;
`;

export const PopupButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupButton = styled(Button)`
  color: ${simbiozBlue};
  font-size: 22px;
  margin: 10px;
  padding: 20px;
  background-color: ${transparentize(0.85, simbiozBlue)};
  border-radius: 6px;
`;
