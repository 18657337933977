import { memo, useState } from "react";
import PropTypes from "prop-types";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";
import VirtualKeyboard from "../../../components/virtual-keyboard";
import Button from "../../../components/button";
import Classes from "../../../../helpers/classes";

const PageComponent = memo(() => {
  const [appearance, setAppearance] = useState(null);
  const createOnAppearanceButtonClick = (appearance) => () => setAppearance(appearance);

  return (
    <Page className="demo virtual-keyboard ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Virtual Keyboard</Text>
          <p className="standard description">
            To allow typing in kiosk apps, Ripple includes a very customizable virtual keyboard.
          </p>
          <p className="standard description">
            To localize keys, provide <code>[key]KeyboardKey</code> keys in the strings files. To localize the layout
            itself (for example, to add an AZERTY layout for France), simply provide a different layout configuration
            object to the component.
          </p>
          <p className="standard description note">
            NOTE: Mouse events cause the input fields to lose focus when the virtual keyboard keys are pressed. To test
            the virtual keyboard, you must either use a touchscreen or simulate touch events using the Dev Tools.
          </p>
          <div className="form">
            <input type="text" placeholder="Tap here then type!" />
            <input type="text" placeholder="Tap here then type!" />
            <div className="cases">
              <div className="case">
                <Text className="case-title">Default Layout and Appearance</Text>
                <VirtualKeyboard className={Classes.build(appearance ? `appearance-${appearance}` : null)} />
              </div>
              <div className="case">
                <Text className="case-title">Default Layout (Disabled Keys)</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  disabledKeys={["tab", "\\", "/", "[", "]", "'"]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Custom Layout</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    [7, 8, 9],
                    [4, 5, 6],
                    [1, 2, 3],
                    [0, ".", null],
                  ]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Custom Layout</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    ["!", "@", "#", "$"],
                    ["?", "&", "*", ":"],
                    ["[", "]", "{", "}"],
                    [null, "(", ")", null],
                  ]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Custom Layout</Text>
                <VirtualKeyboard
                  className={Classes.build("mobile-like", appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
                    ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
                    ["z", "x", "c", "v", "b", "n", "m"],
                    ["@", "space", "."],
                  ]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Crazy Layout</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    ["a", null, "b", null],
                    [null, "c", null, "d"],
                    ["e", null, "f", null],
                    [null, "g", null, "h"],
                  ]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Custom Actions</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
                    ["clear", { key: "submit", action: () => alert("Submit!"), class: "special" }],
                  ]}
                />
              </div>
              <div className="case">
                <Text className="case-title">Custom Type and Classes</Text>
                <VirtualKeyboard
                  className={Classes.build(appearance ? `appearance-${appearance}` : null)}
                  layout={[
                    [
                      { key: "custom 1", type: "this", class: "custom-key-style" },
                      { key: "custom 3", type: "types", class: "special" },
                    ],
                    [
                      { key: "custom 2", type: "something", class: "special" },
                      { key: "custom 4", type: "custom", class: "custom-key-style" },
                    ],
                  ]}
                />
              </div>
            </div>
            <div className="toggles">
              <Button
                className={Classes.build("standard toggle", { active: appearance === null })}
                onClick={createOnAppearanceButtonClick(null)}
              >
                Default
              </Button>
              <Button
                className={Classes.build("standard toggle", { active: appearance === "custom1" })}
                onClick={createOnAppearanceButtonClick("custom1")}
              >
                Custom 1
              </Button>
              <Button
                className={Classes.build("standard toggle", { active: appearance === "custom2" })}
                onClick={createOnAppearanceButtonClick("custom2")}
              >
                Custom 2
              </Button>
              <Button
                className={Classes.build("standard toggle", { active: appearance === "custom3" })}
                onClick={createOnAppearanceButtonClick("custom3")}
              >
                Custom 3
              </Button>
              <Button
                className={Classes.build("standard toggle", { active: appearance === "custom4" })}
                onClick={createOnAppearanceButtonClick("custom4")}
              >
                Custom 4
              </Button>
              <Button
                className={Classes.build("standard toggle", { active: appearance === "custom5" })}
                onClick={createOnAppearanceButtonClick("custom5")}
              >
                Custom 5
              </Button>
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

export default PageComponent;
