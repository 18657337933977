import { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Status from "../../../core/status";
import Strings from "../../../helpers/strings";
import Classes from "../../../helpers/classes";
import Env from "../../../helpers/env";
import Page from "../../components/page";
import ProgressBar from "../../components/progress-bar";
import { StaggerStep } from "../../components/stagger";
import resource from "../../../helpers/resource";

class PageComponent extends PureComponent {
  static propTypes = {
    startup: PropTypes.object.isRequired,
    server: PropTypes.object,
  };

  render() {
    return (
      <Page className="startup" background={resource("images/startup-background.png")}>
        <div className="center">
          <StaggerStep>
            <img className="logo" src={resource("images/startup-logo.png")} />
          </StaggerStep>
          <StaggerStep>
            <div className="status">{this.renderStatus()}</div>
          </StaggerStep>
          <StaggerStep>
            <ProgressBar
              progress={this.props.startup.progress || 0}
              className={Classes.build({ active: this.props.startup.status === Status.FETCHING_MEDIAS })}
            />
          </StaggerStep>
        </div>
      </Page>
    );
  }

  renderStatus = () => {
    switch (this.props.startup.status) {
      case Status.SERVER_UNREACHABLE:
        return (
          <>
            <span>{Strings.localized("StartupServerUnreachable").value}</span>
            <span>{__DEV__ && <>({this.props.server.url})</>}</span>
            {this.renderRetryButton()}
          </>
        );
      case Status.DATA_ERROR:
        return (
          <>
            <span>{Strings.localized("StartupDataError").value}</span>
            {this.renderRetryButton()}
          </>
        );
      case Status.FETCHING_MEDIAS:
        return Strings.localized("StartupFetchingMedias").value;
      case Status.DATA_REQUESTED:
        return Strings.localized("StartupDownloadingData").value;
      case Status.DATA_RECEIVED:
        return Strings.localized("StartupDataReceived").value;
      case Status.DATA_LOCAL:
        return Strings.localized("StartupDataLocalMode").value;
      case Status.DATA_STATIC:
        return Strings.localized("StartupDataStaticMode").value;
      default:
        return Strings.localized("StartupLoading").value;
    }
  };

  renderRetryButton = () => (
    <a href="/">{Strings.localized(`StartupServerUnreachable${Env.isMobile ? "Mobile" : "Desktop"}Retry`).value}</a>
  );
}

const mapStateToProps = function (state) {
  return { language: state.language, startup: state.startup, server: state.serverInfo };
};

export default connect(mapStateToProps)(PageComponent);
