import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import Classes from "../../helpers/classes";

class DevBuildIndicator extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
  };

  render() {
    return <div className={Classes.build("dev-build-indicator", "unclickable", { active: this.props.active })} />;
  }
}

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(DevBuildIndicator);
