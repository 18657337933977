import { memo, forwardRef } from "react";
import PropTypes from "prop-types";

const ThreeFiberCube = memo(
  forwardRef(({ size, color, materialProps, ...rest }, ref) => {
    return (
      <mesh {...rest} ref={ref}>
        <boxBufferGeometry attach="geometry" args={size || [1, 1, 1]} />
        <meshStandardMaterial attach="material" {...{ color: color || "orange", ...materialProps }} />
      </mesh>
    );
  })
);

ThreeFiberCube.propTypes = {
  size: PropTypes.arrayOf(PropTypes.number),
  color: PropTypes.string,
  materialProps: PropTypes.object,
};

export default ThreeFiberCube;
