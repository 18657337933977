import { PureComponent } from "react";

import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="demo scroller ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          <Scroller
            className="page"
            innerClassName="page"
            startFadeRatio={0.1}
            endFadeRatio={0.04}
            scrollbarSideInset={3}
          >
            <Text className="title">Scroller</Text>
            <p className="standard description">
              The <code>Scroller</code> component is a touch-enabled scrollable area with a fully customizable
              scrollbar, content fading and more. It supports both vertical and horizontal scrolling. Enter debug mode
              to see scroller bounds.
            </p>
            <div className="scrollers-root">
              <Scroller className="scroller5" orientation="vertical" disablePropagation>
                {this.renderScroller5Content()}
              </Scroller>
              <Scroller className="scroller1" orientation="vertical" disablePropagation>
                {this.renderScrollerContent()}
              </Scroller>
              <Scroller className="scroller2" orientation="horizontal">
                {this.renderScrollerContent()}
              </Scroller>
              <Scroller
                className="scroller3"
                orientation="vertical"
                startFadeRatio={0.02}
                endFadeRatio={0.1}
                scrollbarSideInset={10}
                scrollbarStartInset={20}
                scrollbarEndInset={40}
                disablePropagation
              >
                {this.renderScrollerContent()}
              </Scroller>
              <Scroller className="scroller4" orientation="vertical" disablePropagation>
                {this.renderScroller4Content()}
              </Scroller>
            </div>
          </Scroller>
          {Env.isRCC && <DemoBackButton />}
        </SafeArea>
      </Page>
    );
  }

  renderScrollerContent() {
    return (
      <>
        <div className="box style1">First</div>
        <div className="box style2">Second</div>
        <div className="box style1">Third</div>
        <div className="box style2">Fourth</div>
        <div className="box style1">Fifth</div>
        <div className="box style2">Sixth</div>
        <div className="box style1">Seventh</div>
        <div className="box style2">Eighth</div>
        <div className="box style1">Ninth</div>
        <div className="box style2">Tenth</div>
      </>
    );
  }

  renderScroller4Content() {
    return (
      <>
        <div className="box style1">First</div>
        {this.renderHorizontalScrollerBox()}
        <div className="box style1">Third</div>
        <div className="box style2">Fourth</div>
        <div className="box style1">Fifth</div>
        <div className="box style2">Sixth</div>
        <div className="box style1">Seventh</div>
        <div className="box style2">Eighth</div>
        <div className="box style1">Ninth</div>
        <div className="box style2">Tenth</div>
      </>
    );
  }

  renderScroller5Content() {
    return (
      <>
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
        {this.renderHorizontalScrollerBox()}
      </>
    );
  }

  renderHorizontalScrollerBox() {
    return (
      <div className="box contains-scroller">
        <Scroller
          className="embedded"
          orientation="horizontal"
          scrollbarStartInset={10}
          scrollbarEndInset={10}
          scrollbarSideInset={6}
          stopPropagationOnScroll
          startThreshold={15}
        >
          <div className="box style1">1</div>
          <div className="box style2">2</div>
          <div className="box style1">3</div>
          <div className="box style2">4</div>
          <div className="box style1">5</div>
          <div className="box style2">6</div>
          <div className="box style1">7</div>
          <div className="box style2">8</div>
          <div className="box style1">9</div>
          <div className="box style2">10</div>
        </Scroller>
      </div>
    );
  }
}

export default PageComponent;
