import Enum from "../core/enum/enum";
import Member from "../core/enum/member";

let MediaType = new Enum("MediaType");

MediaType.ensureMemberIsValid = function (member) {
  // Must be defined before adding initial members!
  // Throw on missing properties (when overriding)
};

MediaType.addMembers([
  new Member(0, "Generic"),
  new Member(1, "Image"),
  new Member(2, "Video"),
  new Member(3, "Audio"),
  new Member(4, "Vector"),
]);

export default MediaType;
