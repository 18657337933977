import { darken, lighten } from "polished";
import { Button, Image, Paragraphs, Scroller, Text } from "ripple";
import styled from "styled-components";
import { accent01Color, logoColor, vert05Color } from "../../common";

export const PopupRoot = styled.div`
  position: relative;
  display: flex;
  background-color: ${logoColor};

  width: 100%;
  height: 100%;

  @media screen and (max-width: 1400px) {
    flex-direction: column;
    height: 100%;
  }
  @media screen and (min-width: 1401px) {
    flex-direction: row;
    max-width: 80%;
    max-height: 60%;
  }
`;

export const MediaArea = styled.div`
  position: relative;
  background-color: ${darken(0.04, vert05Color)};
  max-height: 60vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MediaAreaImage = styled(Image).attrs((props) => ({ scaling: "fit", fadeIn: "always" }))`
  width: 100%;
  height: 100%;
`;

export const TextArea = styled.div`
  min-height: 0; /* Fixes scroller expanding past TextArea! */
  min-width: 30%;
  flex: 1;
`;

export const TextAreaScroller = styled(Scroller).attrs((props) => ({
  scrollbarInset: 6,
}))`
  height: 100%;
  .scroller-content {
    padding: 20px;
  }

  .scroller-thumb {
    background-color: white;
  }
`;

export const Title = styled(Text)`
  font-family: "Atma-Bold";
  font-size: 2.5vmax;

  @media screen and (min-width: 1000px) {
    font-size: 2vmax;
  }
`;

export const Description = styled(Paragraphs)`
  margin-top: 12px;
  font-family: "Roboto-Light";
  font-size: 2vmax;

  a {
    color: ${lighten(0.15, accent01Color)};
  }

  b,
  strong {
    font-family: "Roboto-Bold";
  }

  @media screen and (min-width: 1000px) {
    font-size: 1.2vmax;
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 1vh;
  right: 1vh;
  width: 6vh;
  height: 6vh;

  background-color: ${accent01Color};
  border-radius: 50%;
  padding: 1.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ripple-image {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 1401px) {
    top: -2.5vh;
    right: -2.5vh;
  }

  .hotspot {
    margin: -2.5vh;
  }
`;

export const PlayButton = styled(Button)`
  position: absolute;
  background-color: ${accent01Color};
  width: 15vmin;
  height: 15vmin;
  border-radius: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .hotspot {
    margin: -15vmin;
  }

  &:before {
    margin-left: 0.5vmin;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f04b";
    font-size: 6vmin;
    color: white;
  }
`;
