import _ from "lodash";

const checkVisible = (node) => node.visibility.name === "Public" || node.visibility.name === "Discovered";

let Node = function (id, fields) {
  this.id = id;
  this.parent = null;
  this.texts = [];
  this.medias = [];
  this.children = [];
  this.allChildren = [];
  this.tags = [];
  this.allTags = [];
  this.settings = {};
  this.addons = {};
  this.storage = {}; // For use by application code to store arbitrary information
  _.assign(this, fields);

  /** Returns true if the node itself is visible, regardless of its ancestors' visibility. */
  this.isVisible = function () {
    return checkVisible(this);
  };

  /** Returns true if the node is visible AND is within ancestors which are also visible. */
  this.isActuallyVisible = function (node = this) {
    if (node.parent === null) return checkVisible(node);
    return checkVisible(node) && this.isActuallyVisible(node.parent);
  };

  // Siblings

  this.sibling = function (increment, { wrapping } = {}) {
    const parent = this.parent;
    if (!parent) return null;

    const siblings = parent.children;
    const indexOfCurrent = _.indexOf(siblings, this);
    let nextIndex = indexOfCurrent + increment;

    if (wrapping) {
      if (nextIndex < 0) nextIndex = siblings.length - 1;
      if (nextIndex > siblings.length - 1) nextIndex = 0;
    }

    // If we're out of bounds (won't happen if wrapping)
    if (nextIndex < 0 || nextIndex >= siblings.length) return null;

    return siblings[nextIndex];
  };

  this.previousSibling = function (options) {
    return this.sibling(-1, options);
  };

  this.nextSibling = function (options) {
    return this.sibling(+1, options);
  };

  // Tree Traversal

  this.ancestorWithSemanticIncludingSelf = function (semantic) {
    if (this.semantic === semantic) return this;
    return this.ancestorWithSemantic(semantic);
  };

  this.ancestorWithSemantic = function (semantic) {
    if (this.parent.semantic === semantic) return this.parent;
    return this.parent.ancestorWithSemantic(semantic);
  };

  this.descendantWithIdIncludingSelf = function (id) {
    if (this.id === id) return this;
    return this.descendantWithId(id);
  };

  this.descendantWithId = function (id) {
    for (let i = 0; i < this.children.length; i++) {
      let child = this.children[i];
      let descendant = child.descendantWithIdIncludingSelf(id);
      if (descendant) return descendant;
    }
    return null;
  };

  this.descendants = function (node = this) {
    const descendants = [];
    descendants.push(...node.children);
    descendants.push(..._.flatMap(node.children, (child) => this.descendants(child)));
    return descendants;
  };
};

export default Node;
