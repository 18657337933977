import _ from "lodash";
import { PureComponent } from "react";

import YamlResource from "../../../logic/yaml-resource";
import LocationHelper from "../../../helpers/internal/location-helper";
import Strings from "../../../helpers/strings";
import Toast from "../../../helpers/toast";
import Clipboard from "../../../helpers/clipboard";
import Env from "../../../helpers/env";

import Page from "../../components/page";
import Scroller from "../../components/scroller";
import Button from "../../components/button";
import resource from "../../../helpers/resource";
import Timeout from "../../../helpers/timeout";

function buildArguments(config) {
  if (typeof config === "undefined" || _.isEmpty(config)) return "";
  const json = JSON.stringify(config);
  // eslint-disable-next-line quotes
  return `--config="${json.replace(/"/g, '\\"')}"`;
}

function buildURL(config) {
  if (typeof config === "undefined" || _.isEmpty(config)) return "";
  const json = JSON.stringify(config);
  return `${window.location.origin}?config=${encodeURI(json)}`;
}

// This returns the deepest object that contains
// the last key of the specified keypath.
function getNestedObject(obj, keypath) {
  return _.reduce(
    keypath.slice(0, -1),
    (acc, key) => {
      const nestedObject = acc[key];

      // As soon as the nested object doesn't
      // exist, return an empty object so that we can finish reducing
      // but end up with an empty object at the end.
      if (!nestedObject) return {};

      return nestedObject;
    },
    obj
  );
}

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="config" pauseTimeout="reset">
        <Scroller scrollbarSideInset={10} scrollbarStartInset={40} scrollbarEndInset={40}>
          <div className="editor">{this.loaded && this.renderElementsRecursively(this.modifiedConfig)}</div>
          {Env.isDesktop && (
            <>
              <div className="top">
                <Button onClick={this.onReloadOnDiskConfigPress}>
                  {Strings.localized("ConfigActionReloadOnDiskConfig").value}
                  <i className="fa fa-sync" />
                </Button>
              </div>
              <div className="buttons">
                {Env.isContained && (
                  <Button onClick={this.onSaveToLocalConfigPress}>
                    {Strings.localized("ConfigActionSaveToLocalConfig")}
                  </Button>
                )}
                {Env.isContained && (
                  <Button onClick={this.onSaveToLocalConfigAndReloadAppPress}>
                    {Strings.localized("ConfigActionSaveToLocalConfigAndReloadApp")}
                  </Button>
                )}
                <Button onClick={this.onCopyProcessArgumentsPress}>
                  {Strings.localized("ConfigActionCopyProcessArguments")}
                </Button>
                <Button onClick={this.onCopyURLPress}>{Strings.localized("ConfigActionCopyURL")}</Button>
                {!Env.isContained && (
                  <Button onClick={this.onReloadWithURLPress}>{Strings.localized("ConfigActionReloadWithURL")}</Button>
                )}
              </div>
            </>
          )}
        </Scroller>
      </Page>
    );
  }

  renderElementsRecursively(object, level = 0, parentKeypath = []) {
    let elements = [];
    _.forOwn(object, (value, key) => {
      const keypath = parentKeypath.slice(0);
      keypath.push(key);
      if (value !== null && typeof value === "object") {
        elements.push(this.renderSection(level, keypath));
        elements = elements.concat(this.renderElementsRecursively(value, level + 1, keypath));
      } else {
        const valueForDisplay = this.getConfigValue(this.displayConfig, keypath);
        elements.push(this.renderField(level, keypath, value, valueForDisplay));
      }
    });
    return elements;
  }

  renderSection(level, keypath) {
    return (
      <div key={keypath.join(".")} className="section">
        <span style={{ marginLeft: this.indentationForLevel(level) }}>{_.last(keypath) + ":"}</span>
      </div>
    );
  }

  renderField(level, keypath, value, valueForDisplay) {
    const customValue = this.getConfigValue(this.customConfig, keypath);
    const localValue = this.getConfigValue(this.localConfig, keypath);
    const configValue = this.getConfigValue(this.configOverrides, keypath);

    const className = (() => {
      if (value !== localValue) return "modified";
      if (value !== customValue) return "local";
      return "";
    })();

    // We can reset as long as we haven't come back to the custom value
    const showReset = value !== customValue;

    // Here, we use the custom config as a source of truth for the value's allowed type
    const allowedType = typeof customValue;

    const override = configValue !== localValue ? configValue : undefined;
    const hasOverride = typeof override !== "undefined";

    return (
      <div key={keypath.join(".")} className="field">
        <span style={{ marginLeft: this.indentationForLevel(level) }}>{_.last(keypath) + ":"}</span>
        <input
          className={className}
          value={this.fieldify(valueForDisplay)}
          onChange={this.onChange(keypath)}
          onKeyPress={this.onKeyPress(allowedType)}
        />
        {showReset && <Button className="reset fa fa-undo" onClick={this.onResetValuePress(keypath)} />}
        {hasOverride && <span className="override">{`(override: ${override})`}</span>}
      </div>
    );
  }

  componentDidMount() {
    // We keep this unmodified, it's the base config we diff against
    this.customConfigResource = new YamlResource();

    // This is what our editable model is based on, and what we diff against the custom config
    this.localConfigResource = new YamlResource();

    // Those are specified through the URL and override anything that's configured
    this.configOverrides = (() => {
      const string = LocationHelper.getValue("config");
      if (!string) return {};
      return JSON.parse(string);
    })();

    // The modified config represents the data behind the editor.
    // The display config is the model behind the React controlled inputs.
    // It matches the first one exactly, except that edited values become strings
    // instead of typed values. The display model is is necessary to avoid loss when
    // (for example) editing a number with a temporarily NaN value.
    Promise.all([this.loadCustomConfig(), this.loadLocalConfig()]).then(() => {
      this.modifiedConfig = _.cloneDeep(this.localConfig);
      this.displayConfig = _.cloneDeep(this.localConfig);
      this.loaded = true;
      this.forceUpdate();
    });
  }

  loadCustomConfig = () => {
    return this.customConfigResource
      .load(resource("data/core-config.yml"), resource("data/custom-config.yml"))
      .then(() => (this.customConfig = _.cloneDeep(this.customConfigResource.values)));
  };

  loadLocalConfig = () => {
    return this.localConfigResource
      .load(
        resource("data/core-config.yml"),
        resource("data/custom-config.yml"),
        resource("data/local-config.yml[optional]")
      )
      .then(() => (this.localConfig = _.cloneDeep(this.localConfigResource.values)));
  };

  componentDidUpdate(prevProps, prevState) {
    // Update the size of all inputs
    _.each(document.querySelectorAll("div.config input"), (input) => this.updateInputElementSize(input));
  }

  indentationForLevel(level) {
    return level * 20;
  }

  onChange = (keypath) => (event) => {
    this.setConfigValue(this.modifiedConfig, keypath, this.retype(event.target.value));
    this.setConfigValue(this.displayConfig, keypath, event.target.value);
    this.forceUpdate();
  };

  onKeyPress = (type) => (event) => {
    if (type === "anything") return;
    if (type === "number" && !/[0-9\\.-]/.test(event.nativeEvent.key)) event.preventDefault();
  };

  onResetValuePress = (keypath) => (event) => {
    const input = event.target.closest(".field").querySelector("input");

    const localValue = this.getConfigValue(this.localConfig, keypath);
    const customValue = this.getConfigValue(this.customConfig, keypath);

    // We reset to the local value first, then to the custom value
    const typedValue = this.retype(input.value);
    const resetValue = typedValue === localValue ? customValue : localValue;

    this.setConfigValue(this.modifiedConfig, keypath, resetValue);
    this.setConfigValue(this.displayConfig, keypath, resetValue);

    this.updateInputElementSize(input);
    this.forceUpdate();
  };

  onReloadOnDiskConfigPress = () => {
    this.loadLocalConfig().then(() => {
      this.modifiedConfig = _.cloneDeep(this.localConfig);
      this.displayConfig = _.cloneDeep(this.localConfig);
      this.forceUpdate();
    });
  };

  onSaveToLocalConfigPress = () => {
    const difference = this.diff(this.customConfig, this.modifiedConfig);
    Env._sendToContainer("overwrite-local-config", difference);
    this.loadLocalConfig().then(() => this.forceUpdate());
    Toast.info("Changes saved!");
  };

  onSaveToLocalConfigAndReloadAppPress = () => {
    this.onSaveToLocalConfigPress();
    Timeout.force();
    window.location.href = "/"; // Force reload
  };

  onCopyProcessArgumentsPress = () => {
    this.finalDiffThen(this.localConfig, this.modifiedConfig, (difference) => {
      Clipboard.copy(buildArguments(difference));
      Toast.info("Process arguments copied to clipboard!");
    });
  };

  onCopyURLPress = () => {
    this.finalDiffThen(this.localConfig, this.modifiedConfig, (difference) => {
      Clipboard.copy(buildURL(difference));
      Toast.info("Overrides query string copied to clipboard!");
    });
  };

  onReloadWithURLPress = () => {
    this.finalDiffThen(this.localConfig, this.modifiedConfig, (difference) => {
      window.location = buildURL(difference);
    });
  };

  finalDiffThen = (ours, theirs, action) => {
    const difference = this.diff(ours, theirs);
    if (_.isEmpty(difference)) {
      Toast.warn("There is nothing to copy because no changes were made");
      Clipboard.copy(""); // Empty the clipboard to avoid mistakes!
      return;
    }
    action(difference);
  };

  updateInputElementSize = (input) => {
    const extraPadding = navigator.vendor.match(/apple/i) ? 4 : 0;
    input.style.width = 0;
    input.style.width = input.scrollWidth + extraPadding + "px";
  };

  getConfigValue = (config, keypath) => {
    const nestedObject = getNestedObject(config, keypath);
    if (!nestedObject) return undefined;
    return nestedObject[_.last(keypath)];
  };

  setConfigValue = (config, keypath, value) => {
    getNestedObject(config, keypath)[_.last(keypath)] = value;
  };

  /* String to typed (to convert field values back into types for storage) */
  retype = (value) => {
    if (typeof value !== "string") return value;
    if (value === "true") return true;
    if (value === "false") return false;
    if (value === "null") return null;
    if (!isNaN(value)) return Number(value);
    return value;
  };

  /* Typed to string (for display in fields) */
  fieldify = (value) => {
    if (value === null) return "null";
    return value.toString();
  };

  diff = (ours, theirs) => {
    const difference = {};
    _.forOwn(ours, (ourValue, key) => {
      const theirValue = theirs[key];
      if (ourValue !== null && typeof ourValue === "object") {
        const childDiff = this.diff(ourValue, theirValue);
        if (childDiff) difference[key] = childDiff;
      } else {
        if (theirValue !== ourValue) difference[key] = theirValue;
      }
    });
    if (!_.isEmpty(difference)) return difference; // Else return undefined
  };
}

export default PageComponent;
