import { Handle } from "handles.js";
import Log from "./log";

export default class Timeout {
  static _observables = null;
  static _pauseCount = 0; // Do not set this externally!

  /**
   * Force a timeout *right now*. Ignores timeout pause if active.
   */
  static force() {
    Timeout._observables.forceTimeout();
  }

  /**
   * Pauses the timeout until the handle that is returned from this function is released.
   * @param {string} requesterName - A friendly name for the code that requests the timeout pause (used to make sense of the logs)
   * @param {object} [resumeMode] - Indicates what to do with the remaining time when timeout resumes (either "reset" or "continue", optional)
   */
  static paused() {
    return Timeout._pauseCount > 0;
  }

  static pause(requesterName, resumeMode = "reset" /* This is the safe bet when it comes to user expectation! */) {
    if (!requesterName) throw new Error("A requester name *MUST* be provided when pausing the timeout!");

    Timeout._pauseCount += 1;

    if (Timeout._pauseCount === 1) {
      Timeout._observables.pause();
      Log.info(`Timeout paused with resume mode '${resumeMode}'`);
    }

    Log.debug(`(Timeout pause requested by '${requesterName}', ${Timeout._pauseCount} requesters total)`);

    return new Handle(() => {
      Timeout._pauseCount -= 1;

      Log.debug(`(Timeout pause released by '${requesterName}', ${Timeout._pauseCount} requesters remaining)`);

      if (Timeout._pauseCount === 0) {
        if (resumeMode === "reset") {
          Timeout._observables.resumeAndReset();
        } else if (resumeMode === "continue") {
          Timeout._observables.resumeAndContinue();
        } else {
          throw new Error(`Unsupported timeout resume mode '${resumeMode}'`);
        }
      }
    });
  }
}

window.ripple = { ...(window.ripple || {}), timeout: Timeout };
