import RemoteStateActions from "../../actions/local/remote-state-actions";

const defaultState = null;

const remoteSharedStateReducer = function (state = defaultState, action) {
  switch (action.type) {
    case RemoteStateActions.UPDATE_REMOTE_STATE:
      return action.state;
    default:
      return state;
  }
};

export default remoteSharedStateReducer;
