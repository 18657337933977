import Env from "./env";

export default (resourcesRelativePath) => {
  if (Env.isContained) return `resources/${resourcesRelativePath}`;
  return `/resources/${resourcesRelativePath}`;
};

export const isResourcePath = (path) => {
  // eslint-disable-next-line ripple/enforce-resource-helper
  return path.includes("resources/");
};
