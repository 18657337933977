import { useContext, useState, useEffect } from "react";
import RippleContext from "../../../core/ripple-context";
import { useLatestRef } from "./use-latest-ref";

export const useFlagChange = (flagName, onChange = () => {}) => {
  const rippleContext = useContext(RippleContext);
  const [changeCount, setChangeCount] = useState(0);
  const mostRecentChangeCallbackRef = useLatestRef(onChange);
  const mostRecentForceRenderCallbackRef = useLatestRef(() => setChangeCount(changeCount + 1), [changeCount]);

  // Call the callback once at mount to ensure consistent state if only using the callback
  useEffect(() => {
    const mostRecentChangeCallback = (...args) => mostRecentChangeCallbackRef.current(...args);
    if (mostRecentChangeCallback) mostRecentChangeCallback(rippleContext.store.getState().flags[flagName]);
  }, [flagName, mostRecentChangeCallbackRef, rippleContext]);

  // Subscribe for subsequent changes
  useEffect(() => {
    const subscription = rippleContext.observables.store.flagChange$(flagName).subscribe((flagState) => {
      const mostRecentForceRenderCallback = (...args) => mostRecentForceRenderCallbackRef.current(...args);
      mostRecentForceRenderCallback(); // Force a render when the flag state changes

      const mostRecentChangeCallback = (...args) => mostRecentChangeCallbackRef.current(...args);
      if (mostRecentChangeCallback) mostRecentChangeCallback(flagState); // Also call the callback if we need to perform something manually on update
    });

    return () => subscription.unsubscribe();
  }, [flagName, mostRecentChangeCallbackRef, mostRecentForceRenderCallbackRef, rippleContext]);

  // Return the initial flag state instantly.
  // Because we force a render when the flag state changes,
  // this value can be used directly to toggle things in most cases.
  return rippleContext.store.getState().flags[flagName];
};
