import _ from "lodash";

function calculate(preprocessed) {
  const startupEvents = _.filter(preprocessed.events, (e) => e.name === "startup");
  const platforms = _.filter(
    _.map(startupEvents, (e) => e.data.platform),
    (p) => p !== undefined
  );
  return _.countBy(platforms, (platform) => platform);
}

export default function (preprocessed) {
  this.name = "Platforms";
  this.keyName = "Platform";
  this.valueName = "Platform";
  this.format = (value) => value;
  this.value = calculate(preprocessed);
}
