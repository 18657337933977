import _ from "lodash";
import { createRef, PureComponent } from "react";
import PropTypes from "prop-types";
import Classes from "../../../helpers/classes";
import Styles from "../../../helpers/styles";

class ProgressBar extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    progress: PropTypes.number, // The current progress ratio (from 0 to 1)
    interactive: PropTypes.bool, // Whether the user can interact with (seek) the progress bar or not
    animated: PropTypes.bool, // Whether the progress bar animates to the current progress or updates instantly
    onSeek: PropTypes.func, // Tells the user that a seek has occured, but lets the parent provide the new progress if applicable
  };

  static defaultProps = {
    interactive: false,
  };

  constructor(props) {
    super(props);
    this.track = createRef();
  }

  render() {
    return (
      <div
        className={Classes.build("ripple-progress-bar", this.props.className)}
        style={Styles.merge(this.props.style)}
      >
        <div className="track" ref={this.track} onMouseUp={this.handleUp} onTouchEnd={this.handleUp}>
          <div
            className={Classes.build("progress", { animated: this.props.animated })}
            style={{ width: Math.floor(this.props.progress * 100) + "%" }}
          />
        </div>
      </div>
    );
  }

  handleUp = (event) => {
    const { onSeek } = this.props;
    if (onSeek && this.props.interactive) onSeek(this.getProgressForEvent(event));
  };

  getProgressForEvent = (event) => {
    const nativeEvent = event.nativeEvent;
    const trackRect = this.track.current.getBoundingClientRect();
    const trackX = trackRect.x;
    const eventX = !_.isUndefined(nativeEvent.changedTouches)
      ? nativeEvent.changedTouches[0].clientX
      : nativeEvent.clientX;
    return (eventX - trackX) / trackRect.width;
  };
}

export default ProgressBar;
