import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { Image, resource } from "ripple";
import ModalViewer from "../modal-viewer";
import {
  CloseButton,
  Description,
  MediaArea,
  MediaAreaImage,
  PlayButton,
  PopupRoot,
  TextArea,
  TextAreaScroller,
  Title,
} from "./styled";

const Popup = ({ node, onClose, ...rest }) => {
  const media = node.wantedMedia("Video", "FullscreenVideo");

  const [showModalViewer, setShowModalViewer] = useState(false);
  const onPlayClick = useCallback(() => setShowModalViewer(true), []);
  const onModalClose = useCallback(() => setShowModalViewer(false), []);

  return (
    <PopupRoot {...rest}>
      <MediaArea>
        <MediaAreaImage key={node.id} src={node.wantedMedia("Image", "FullscreenImage")} />
        {media.type === "video" && <PlayButton onClick={onPlayClick} />}
      </MediaArea>
      <TextArea>
        <TextAreaScroller>
          <Title>{node.wantedText("Title")}</Title>
          <Description enableLinks>{node.wantedText("Description")}</Description>
        </TextAreaScroller>
      </TextArea>
      <CloseButton onClick={onClose}>
        <Image src={resource("images/Symbole_Close.svg")} />
      </CloseButton>
      {ReactDOM.createPortal(
        showModalViewer && <ModalViewer show={showModalViewer} src={media} onClose={onModalClose} />,
        document.getElementById("modal-viewer-portal")
      )}
    </PopupRoot>
  );
};

Popup.propTypes = {
  node: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Popup;
