import { useEffect } from "react";
import Timeout from "../../helpers/timeout";

/** Pauses timeout automatically for as long as the caller is mounted. */
export const useTimeoutPause = (requesterName, resumeMode = undefined) => {
  useEffect(() => {
    const handle = Timeout.pause(requesterName, resumeMode);
    return () => handle && handle.release();
  }, [requesterName, resumeMode]);
};
