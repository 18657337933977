import _ from "lodash";
import LanguageActions from "../redux/actions/local/language-actions";
import Analytics from "./analytics";
import Config from "./config";
import Env from "./env";
import Maths from "./maths";

const LAST_SELECTED_LANGUAGE = "last-selected-language";

function getDefaultLanguageFromUrl() {
  const patterns = Config.language.urlPatterns;
  for (const pattern in patterns) {
    if (new RegExp(pattern).test(window.location)) return patterns[pattern];
  }
  return null;
}

export default class Localization {
  static _store = null; // Must be set early on so that we can dispatch actions

  /** Get the language that corresponds to the configured default language. */
  static getDefaultLanguage() {
    const lastSelectedLanguage = localStorage.getItem(LAST_SELECTED_LANGUAGE);

    const rawUserLanguage = navigator.language.split("-")[0].toLowerCase();
    const rawUserLanguageMatchesSupportedLanguage = !!_.find(Localization.getLanguages(), (l) => l === rawUserLanguage);
    const userLanguage = rawUserLanguageMatchesSupportedLanguage ? rawUserLanguage : null;

    const defaultLanguage = getDefaultLanguageFromUrl() ?? Config.language.default;

    const dynamicDefaultLanguage = lastSelectedLanguage || userLanguage || defaultLanguage;
    const finalLanguage = Env.isRCC ? dynamicDefaultLanguage : defaultLanguage;

    if (typeof finalLanguage === "undefined") throw new Error(`No language found for default '${finalLanguage}'`);
    return finalLanguage;
  }

  /** Get the language that corresponds to the currently active language. */
  static getCurrentLanguage() {
    return Localization._store.getState().language;
  }

  /** Get the list of all supported languages (languages for which there is a translation) */
  static getLanguages() {
    return Config.language.supported;
  }

  /** Get the language that corresponds to the next language from the currently active language. */
  static getNextLanguage() {
    const languages = Localization.getLanguages();
    const currentLanguage = Localization.getCurrentLanguage();
    if (_.isEmpty(currentLanguage)) return languages[0];
    const currentIndex = _.indexOf(languages, currentLanguage);
    const nextIndex = Maths.wrap(currentIndex + 1, 0, languages.length - 1);
    const nextLanguage = languages[nextIndex];
    return nextLanguage;
  }

  /** Performs a switch to the configured default language in a single step. */
  static switchToDefaultLanguage() {
    Localization.switchToLanguage(Localization.getDefaultLanguage());
  }

  /**
   * Switch to the provided language
   * @param language A Language instance or a language code (string).
   */
  static switchToLanguage(language) {
    // We can either change the language with a Language instance or a language code
    Analytics.track("language-change", { language: language });
    Localization._store.dispatch(LanguageActions.change(language));
    localStorage.setItem(LAST_SELECTED_LANGUAGE, language);
  }

  /** Performs a switch to the next language from the currently active language. */
  static switchToNextLanguage() {
    Localization.switchToLanguage(Localization.getNextLanguage());
  }
}
