import { Html } from "@react-three/drei";
import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Audio,
  Config,
  Image,
  Load,
  Navigator,
  Panorama,
  resource,
  Strings,
  useConstant,
  useData,
  useInteraction,
  useLastTruthy,
  usePrevious,
} from "ripple";
import useStore from "../../../viewed-store";
import { StandardButtonText, StandardLeftButtonIcon, StandardRightButtonIcon } from "../../common";
import Menu from "../../components/menu";
import Popup from "../../components/popup";
import {
  BottomLeftButton,
  BottomRightButton,
  FullscreenPanorama,
  MuteButton,
  Page,
  PinArrow,
  PinButton,
  PinContainer,
  PinText,
  PopupRevealer,
  Tutorial,
} from "./styled";

const PanoramaPage = memo(() => {
  const { id } = useParams();
  const previousId = usePrevious(id);
  const client = useData((data) => data.root);
  const node = useData((data) => data.requiredNode(id));

  // Zustand store
  const viewed = useStore((state) => state.viewed);
  const addViewed = useStore((state) => state.addViewed);
  const tutorialShown = useStore((state) => state.tutorialShown);
  const setTutorialShown = useStore((state) => state.setTutorialShown);

  const [selectedPin, setSelectedPin] = useState(null);
  const [lastTruthySelectedPin] = useLastTruthy(selectedPin);

  useEffect(() => {
    if (id !== previousId) setSelectedPin(null);
  }, [id, previousId]);

  const [showTutorial, setShowTutorial] = useState(false);
  useInteraction(() => {
    setShowTutorial(false);
    setTutorialShown();
  });

  // Show tutorial after a certain delay, if appropriate
  useEffect(() => {
    const timeout = setTimeout(() => setShowTutorial(!tutorialShown), 2000);
    return () => clearTimeout(timeout);
  }, [tutorialShown]);

  // Play background audio
  const [isMuted, setIsMuted] = useState(false);
  const backgroundAudioTrack = Audio.background("panorama-ambience");
  const audio = node.optionalMedia("PanoramaBackgroundAudio", "Audio");
  useEffect(() => {
    if (!audio.exists) return;
    backgroundAudioTrack.play(audio, { fadeDuration: Config.panoramaAmbienceFadeInDuration });
    return () => backgroundAudioTrack.stop({ fadeDuration: 200 });
  }, [audio, backgroundAudioTrack, node]);

  const onMuteButtonClick = useCallback(() => {
    if (backgroundAudioTrack.isPlaying) {
      backgroundAudioTrack.stop();
      setIsMuted(true);
    } else {
      backgroundAudioTrack.play(audio, { fadeDuration: Config.panoramaAmbienceFadeInDuration });
      setIsMuted(false);
    }
  }, [audio, backgroundAudioTrack]);

  const preloadPin = (pin, callback) => {
    const url = pin.wantedMedia("Image", "FullscreenImage").url;
    Load.image(url).then(callback);
  };

  const createOnPinClick = (pin) => () => {
    preloadPin(pin, () => setSelectedPin(pin));
    addViewed(pin);
  };

  const onPopupClose = useCallback(() => {
    setSelectedPin(null);
  }, []);

  const onBackButtonClick = useCallback(() => {
    Navigator.navigate({ node: node.parent, type: "sections" });
  }, [node.parent]);

  const isLastChapter = useConstant(() => !node.nextSibling());
  const onNextClick = useCallback(() => {
    if (isLastChapter) {
      location.href = client.wantedText("BackToSiteLinkUrl").value;
    } else {
      Navigator.navigate({ node: node.nextSibling(), type: "transition" });
    }
  }, [client, isLastChapter, node]);

  return (
    <Page>
      <FullscreenPanorama src={node.wantedMedia("PanoramicImage", "PanoramicImage")}>
        {node.children.map((pin) => {
          const position = JSON.parse(pin.wantedText("Position").value);
          const isViewed = viewed.includes(pin);
          return (
            <Panorama.Pin key={pin.id} theta={position?.theta} phi={position?.phi}>
              <Html
                zIndexRange={[500, 1]} // Avoid overlapping menu and Ripple debug overlay
                style={{ transform: "translate3d(-50%,-100%,0)" }} // Anchor on bottom-center
              >
                <PinContainer show={!selectedPin && !showTutorial}>
                  <PinButton disabled={!!selectedPin} onClick={createOnPinClick(pin)} viewed={isViewed}>
                    <PinText>{pin.wantedText("PinTitle")}</PinText>
                  </PinButton>
                  <PinArrow viewed={isViewed} />
                </PinContainer>
              </Html>
            </Panorama.Pin>
          );
        })}
      </FullscreenPanorama>
      <MuteButton onClick={onMuteButtonClick}>
        <Image src={resource(`images/Button_${isMuted ? "Muted" : "Unmuted"}.png`)} />
      </MuteButton>
      {node.parent.semantic === "MultiChapitre" ? (
        <BottomLeftButton onClick={onBackButtonClick}>
          <StandardLeftButtonIcon
            src={resource("images/Symbole_Arrow_01.svg")}
            style={{ transform: "rotate3d(0,0,1,180deg)" }}
          />
          <StandardButtonText>{node.parent.wantedText("BackButtonTitle")}</StandardButtonText>
        </BottomLeftButton>
      ) : (
        <BottomRightButton onClick={onNextClick}>
          <StandardButtonText>{Strings.localized(isLastChapter ? "FinishButton" : "NextButton")}</StandardButtonText>
          <StandardRightButtonIcon src={resource("images/Symbole_Arrow_01.svg")} />
        </BottomRightButton>
      )}
      <Tutorial show={showTutorial}>
        <Image
          src={resource(
            `images/Tutorial_${
              [...document.querySelector("html").classList].includes("touchevents") ? "Tablet" : "Desktop"
            }.png`
          )}
        />
      </Tutorial>
      <PopupRevealer reveal={!!selectedPin}>
        {lastTruthySelectedPin && <Popup node={lastTruthySelectedPin} onClose={onPopupClose} />}
      </PopupRevealer>
      <Menu />
    </Page>
  );
});

export default PanoramaPage;
