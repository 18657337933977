import _ from "lodash";

import Strings from "../../../../helpers/strings";
import LocationHelper from "../../../../helpers/internal/location-helper";

export default function (preprocessed) {
  const navigationEvents = _.filter(preprocessed.events, (e) => e.name === "navigation" && !e.data.root);
  const paths = _.map(navigationEvents, (e) => LocationHelper.urlFromPathAndQuery(e.data.path, e.data.query));
  const unorderedRows = _.map(_.countBy(paths), (value, key) => [key, value]);
  const rows = _.orderBy(unorderedRows, (row) => row[1], "desc");

  this.name = "Paths";
  this.header = [
    Strings.localized("AnalyticsPathsPathColumn").value,
    Strings.localized("AnalyticsPathsViewsColumn").value,
  ];
  this.rows = rows;
  this.grow = [9, 1];
  this.align = ["left", "right"];
}
