import PropTypes from "prop-types";
import MediaInfo from "./info/media-info";
import StringInfo from "./info/string-info";
import TextInfo from "./info/text-info";

export const TextSrcPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(TextInfo),
  PropTypes.instanceOf(StringInfo),
  PropTypes.string,
  PropTypes.number,
]);

export const MediaSrcPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(MediaInfo)]);
