export default class Maths {
  /** Returns the normalized (0..1) ratio of given value within the [min, max] interval. */
  static ratio(value, min, max) {
    return (value - min) / (max - min);
  }

  /** Interpolates between min and max according to ratio. */
  static interpolate(ratio, min, max) {
    return min + (max - min) * ratio;
  }

  /** Wrap the integer value within the [min, max] range (inclusive). */
  static wrap(value, min, max) {
    const a = value - min;
    const b = max - min + 1;
    return min + (a >= 0 ? a % b : (b + (a % b)) % b);
  }

  /** Ensure the value is never less than min nor more than max */
  static clamp(value, min, max) {
    if (value < min) return min;
    if (value > max) return max;
    return value;
  }
}
