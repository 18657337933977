import _ from "lodash";

/**
 * The goal of the Styles helper is to help merge externally-provided
 * styles into a custom component. Given any number of objects as arguments,
 * it performs a merge of the objects starting from the first and
 * proceeding with successive parameters.
 */
export default class Styles {
  static merge(...objects) {
    return _.reduce(
      objects,
      (result, object) => {
        // Ignore null or undefined arguments
        if (object === null || typeof object === "undefined") return result;

        if (typeof object !== "object")
          throw new Error(`Styles merging only supports merging objects. Received: ${object}`);

        return _.merge(result, object || {});
      },
      {}
    );
  }
}
