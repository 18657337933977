import _ from "lodash";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import Env from "../../../../helpers/env";
import Maths from "../../../../helpers/maths";
import resource from "../../../../helpers/resource";
import Point from "../../../../types/point";
import Rect from "../../../../types/rect";
import Button from "../../../components/button";
import Image from "../../../components/image";
import Map from "../../../components/map";
import Page from "../../../components/page";
import SafeArea from "../../../components/safe-area";
import Scroller from "../../../components/scroller";
import { StaggerStep } from "../../../components/stagger";
import Text from "../../../components/text";
import { useInterval } from "../../../hooks/use-interval";
import DemoBackButton from "../components/demo-back-button";

const mapRect = new Rect(0, 0, 2592, 1944);

const framingRect = new Rect(200, 200, 600, 600);
const framingRectNormalized = framingRect.convertAbsoluteToNormalizedIn(mapRect);

const centerPoint = new Point(500, 500);
const centerPointNormalized = centerPoint.convertAbsoluteToNormalizedIn(mapRect);

const PageComponent = memo(({ inception = true }) => {
  const firstMapContentRef = useRef(null);

  const onButtonClick = useCallback(() => {
    alert("All of Ripple's components (and more) can be used within the map!");
  }, []);

  const [stressTestLevel, setStressTestLevel] = useState(false);
  const onToggleStressTest = useCallback(() => {
    setStressTestLevel(Maths.wrap(stressTestLevel + 1, 0, 6));
  }, [stressTestLevel]);

  const onFitInstantButtonClick = useCallback(() => {
    firstMapContentRef.current.fit(framingRectNormalized, { animate: false });
  }, []);

  const onFitAnimatedButtonClick = useCallback(() => {
    firstMapContentRef.current.fit(framingRectNormalized);
  }, []);

  const onCenterInstantButtonClick = useCallback(() => {
    firstMapContentRef.current.center(centerPointNormalized, { animate: false });
  }, []);

  const onCenterAnimatedButtonClick = useCallback(() => {
    firstMapContentRef.current.center(centerPointNormalized);
  }, []);

  const createOnNumberedButtonClick = (number) => () => {
    alert(`Clicked button ${number}`);
  };

  useInterval(() => {
    const svg = document.querySelector(".ripple-map.canada svg");
    if (!svg) return;

    // NOTE! This could be done once but stays here for simplicity's sake.
    svg.querySelector("#Contours")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Contours-Ontario2018")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Contours-Quebec2018")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Frames")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Frames-Ontario2018")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Frames-Quebec2018")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Ridings-Ontario2018")?.setAttribute("visibility", "hidden");
    svg.querySelector("#Ridings-Quebec2018")?.setAttribute("visibility", "hidden");

    const ridings = svg.querySelector("#Ridings");
    if (!ridings) return;

    const paths = [...ridings.querySelectorAll("path"), ...ridings.querySelectorAll("polygon")];
    paths.forEach((path) => {
      path.setAttribute("fill", `hsl(${Math.random() * 255}, 50%, 50%)`);
    });
  }, 1000);

  return (
    <Page className="demo map ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title" style={{ marginTop: 0 }}>
            Map
          </Text>
          <p className="standard description">
            The <code>Map</code> component is a lightweight, mobile-friendly zoomable map that can contain anything!
          </p>
          <div className="buttons">
            <Button className="standard" onClick={onCenterInstantButtonClick}>
              Center Instant
            </Button>
            <Button className="standard" onClick={onCenterAnimatedButtonClick}>
              Center Animated
            </Button>
            <Button className="standard" onClick={onFitInstantButtonClick}>
              Fit Instant
            </Button>
            <Button className="standard" onClick={onFitAnimatedButtonClick}>
              Fit Animated
            </Button>
            <Button className="standard red" onClick={onToggleStressTest}>
              Stress Test
            </Button>
          </div>
          <div className="cases">
            <div className="case larger-image">
              <h2>Image Bigger Than Container</h2>
              <Map>
                <Map.ImageContent ref={firstMapContentRef} src={resource("images/demo/single-tile.jpg")}>
                  <div
                    style={{
                      position: "absolute",
                      top: 300,
                      left: 300,
                      width: 400,
                      height: 300,
                      backgroundColor: "lightgray",
                      boxShadow: "0 0 50px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <Button className="standard" onClick={onButtonClick}>
                      Click Me!
                    </Button>
                    <Text style={{ textAlign: "center", marginTop: 32 }}>
                      This is some custom layout, in a 400x300 area in the image&apos;s coordinate space.
                    </Text>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: framingRect.y,
                      left: framingRect.x,
                      width: framingRect.width,
                      height: framingRect.height,
                      border: "4px solid blue",
                      pointerEvents: "none",
                    }}
                  />
                  <Image
                    style={{ position: "absolute", top: 1500, left: 1500 }}
                    src={resource("images/demo/red-pin.png")}
                  />
                  <Map.Element
                    scaling={Map.Element.scaleToContent}
                    position={{ top: "1550px", left: "1600px" }}
                    anchor={{ x: 0.5, y: 1 }}
                  >
                    <Image src={resource("images/demo/green-pin.png")} />
                  </Map.Element>
                  <Map.Element
                    scaling={Map.Element.scaleToScreen}
                    position={{ top: "1490px", left: "1700px" }}
                    anchor={{ x: 0.5, y: 1 }}
                  >
                    <Image src={resource("images/demo/blue-pin.png")} />
                  </Map.Element>
                  {stressTestLevel !== 0 &&
                    _.range(0, 5 * stressTestLevel).map((x) =>
                      _.range(0, 10).map((y) => (
                        <Map.Element
                          scaling={Map.Element.scaleToContent}
                          position={{ top: 150 + y * 50, left: 940 + x * 50 }}
                          key={`${x}${y}`}
                        >
                          <Button
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 20,
                              backgroundColor: `hsl(${x * y * 2}, 100%, 50%)`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "black",
                              fontSize: 14,
                            }}
                            onClick={createOnNumberedButtonClick(x * y)}
                          >
                            {x + y * 34}
                          </Button>
                        </Map.Element>
                      ))
                    )}
                </Map.ImageContent>
              </Map>
            </div>
            <div className="case smaller-image">
              <h2>Image Smaller Than Container, Multiple Content</h2>
              <Map>
                <Map.ImageContent src={resource("images/demo/single-tile-verysmall.jpg")} style={{ color: "white" }}>
                  Underlay
                </Map.ImageContent>
                <Map.ImageContent
                  src={resource("images/demo/single-tile-verysmall.jpg")}
                  style={{ color: "white", opacity: 0.5 }}
                >
                  Overlay
                </Map.ImageContent>
              </Map>
            </div>
            {Env.isDesktop && (
              <>
                <div className="case layout-only">
                  <h2>Custom Layout Only</h2>
                  <Map>
                    <Map.Content width={300} height={300}>
                      <div className="custom-content">
                        <Button className="standard" onClick={onButtonClick}>
                          Ripple Button!
                        </Button>
                        <div className="round-pill">
                          I am live text with a CSS animation.
                          <b>Watch me spin!</b>
                        </div>
                      </div>
                    </Map.Content>
                  </Map>
                </div>
                <div className="case video">
                  <h2>Video Content</h2>
                  <Map>
                    <Map.VideoContent src={resource("video/demo/video.mp4")} width={1920} height={1080}>
                      <Map.Element
                        scaling={Map.Element.scaleToScreen}
                        position={{ top: 400, left: 400 }}
                        anchor={{ x: 0.5, y: 0 }}
                      >
                        <Image
                          style={{ transform: "rotate3d(0,0,1, 180deg)" }}
                          src={resource("images/demo/red-pin.png")}
                        />
                      </Map.Element>
                      <Map.Element
                        scaling={Map.Element.scaleToContent}
                        position={{ top: 500, left: 700 }}
                        anchor={{ x: 0.5, y: 0.5 }}
                      >
                        <Image src={resource("images/demo/green-pin.png")} />
                      </Map.Element>
                      <Map.Element
                        scaling={Map.Element.scaleToScreen}
                        position={{ top: 600, left: 1000 }}
                        anchor={{ x: 0.5, y: 1 }}
                      >
                        <Image src={resource("images/demo/blue-pin.png")} />
                      </Map.Element>
                    </Map.VideoContent>
                  </Map>
                </div>
                <div className="case svg">
                  <h2>Svg Image Content</h2>
                  <Map className="canada">
                    <Map.ImageContent
                      src={resource("images/demo/canada.svg")}
                      width={16088}
                      height={10280}
                      maxZoom={50}
                    >
                      <Map.Element
                        scaling={Map.Element.scaleToScreen}
                        position={{ top: 6940, left: 12100 }}
                        anchor={{ x: 0.5, y: 1 }}
                      >
                        <Image src={resource("images/demo/blue-pin.png")} />
                      </Map.Element>
                      <Button
                        onClick={onButtonClick}
                        style={{ position: "absolute", top: 4300, left: 8000, width: 1000, height: 1000 }}
                      >
                        <Image src={resource("images/demo/small-icon.svg")} />
                      </Button>
                    </Map.ImageContent>
                  </Map>
                </div>
                <div className="case stagger">
                  <h2>Stagger Support</h2>
                  <Map>
                    <Map.ImageContent
                      src={resource("images/demo/sparta.jpg")}
                      style={{ color: "white", textAlign: "center", textShadow: "0 0 30px black", fontSize: 60 }}
                    >
                      {/* Of course, in apps, use stagger as usual with `styled-components`, not like this! */}
                      <StaggerStep transition="slide-in-and-fade-from-right" delayBefore={1000}>
                        <span>This</span>
                      </StaggerStep>
                      <StaggerStep transition="slide-in-and-fade-from-left" delayBefore={1000} delayAfter={1000}>
                        <span>Is</span>
                      </StaggerStep>
                      <div style={{ display: "inline" }}>
                        <StaggerStep>
                          <span>S</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>T</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>A</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>G</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>G</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>E</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>R</span>
                        </StaggerStep>
                        <StaggerStep>
                          <span>!</span>
                        </StaggerStep>
                      </div>
                      <StaggerStep transition="slide-in-and-fade-from-top">
                        <Image
                          src={resource("images/demo/red-pin.png")}
                          className="rotating-pin"
                          style={{ position: "absolute", top: 90, left: 416 }}
                        />
                      </StaggerStep>
                      <StaggerStep transition="slide-in-and-fade-from-top">
                        <Image
                          src={resource("images/demo/red-pin.png")}
                          className="rotating-pin"
                          style={{ position: "absolute", top: 90, left: 484 }}
                        />
                      </StaggerStep>
                    </Map.ImageContent>
                  </Map>
                </div>
                {inception && (
                  <div className="case mapception">
                    <h2>Mapception!</h2>
                    <Map>
                      <Map.Content width={1920} height={1080}>
                        <PageComponent inception={false} />
                      </Map.Content>
                    </Map>
                  </div>
                )}
              </>
            )}
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
  inception: PropTypes.bool,
};

export default PageComponent;
