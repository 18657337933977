import { v4 as uuidv4 } from "uuid";
import Log from "../helpers/log";
import Env from "./env";

export default class Wikitude {
  static _requiredFeatures = ["geo", "instant_tracking", "image_tracking"];
  static _wikitude = null; // Must be set early on so that we can use it afterwards
  static _initialized = false;
  static _hookCallbacks = {};

  static _onJSONObjectReceived = (object) => Object.values(Wikitude._hookCallbacks).forEach((c) => c(object));

  /**
   * Open the Wikitude world at the specified resource path
   * (as generated using the `resource()` helper function).
   */
  static openWorld(resourcePath) {
    if (!Env.isRCC) {
      Log.warn("Cannot open Wikitude world because we're not running in RCC");
      return;
    }

    // Different world URLs must be provided in a development environment VS
    // when the app is packaged for distribution, because of how the native Wikitude logic
    // interprets the provided path.
    //
    // - At dev time, we must provide the full URL to the ARchitect
    //   world HTML file as hosted on the dev server. The dev server's SSL certificate MUST be
    //   installed and trusted on the device for Wikitude to succeed in loading the world. Otherwise,
    //   it will silently fail to load. See: `ripple/lib/build/certificate/certificate.md`.
    //
    // - When packaging for deployment, we must provide a relative path from the RCC (Cordova) app's root,
    //   including the `www` directory. This is because the native Wikitude code builds a native file URL
    //   to load the world from and the *cwd* of that is the packaged app's root directory.
    //
    // NOTE: We could also possibly support an absolute (native) path to a downloaded scene, but this remains to be tested.
    // See: https://support.wikitude.com/support/discussions/topics/5000080244
    const path = __DEV__
      ? `${location.protocol}//${location.hostname}${location.port ? ":" + location.port : ""}/${resourcePath}`
      : `www/${resourcePath}`;

    return new Promise((resolve, reject) => {
      Wikitude._wikitude.isDeviceSupported(
        () => {
          const loadedSubscription = Wikitude.messageReceiveSubscribe((object) => {
            if (object.action === "ready") {
              Log.info(`Wikitude: Received ready message`);
              Wikitude.messageReceiveUnsubscribe(loadedSubscription);
              resolve();

              // Close the Wikitude view when receiving an appropriate message from the loaded scene.
              const closeSubscription = Wikitude.messageReceiveSubscribe((object) => {
                if (object.action === "close-wikitude") {
                  Wikitude.messageReceiveUnsubscribe(closeSubscription);
                  Wikitude._wikitude.close();
                }
              });
            }
          });

          Wikitude._wikitude.loadARchitectWorld(
            () => {
              Log.info(`Wikitude: Loaded world at path '${path}'`);
            },
            (error) => {
              const message = `Wikitude: Failed to load world '${path}' (${error})`;
              Log.error(message);
              reject(message);
            },
            path,
            this._requiredFeatures
          );
        },
        () => {
          const message = `Wikitude: Some required features are not supported (declared features: ${this._requiredFeatures.toString()})`;
          Log.error(message);
          reject(message);
        },
        this._requiredFeatures
      );
    });
  }

  static sendMessage(obj) {
    if (!Env.isRCC) {
      Log.warn("Cannot send message to Wikitude because we're not running in RCC");
      return;
    }

    try {
      Wikitude._wikitude.callJavaScript(`window.messageFromApp('${btoa(encodeURIComponent(JSON.stringify(obj)))}')`);
    } catch (error) {
      alert(`Wikitude: Could not send message. ${error}`);
    }
  }

  static messageReceiveSubscribe(callback) {
    if (!Env.isRCC) {
      Log.warn("Cannot subscribe to Wikitude message receive because we're not running in RCC");
      return;
    }

    // Lazily initialize the global callback if necessary
    if (!Wikitude._initialized) {
      Wikitude._wikitude.setJSONObjectReceivedCallback(Wikitude._onJSONObjectReceived);
      Wikitude._initialized = true;
    }

    const subscriptionId = uuidv4();
    Wikitude._hookCallbacks[subscriptionId] = callback;
    return subscriptionId;
  }

  static messageReceiveUnsubscribe(subscriptionId) {
    if (!Env.isRCC) {
      Log.warn("Cannot unsubscribe from Wikitude message receive because we're not running in RCC");
      return;
    }

    delete Wikitude._hookCallbacks[subscriptionId];
  }
}
