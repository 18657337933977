import API from "../../helpers/api";
import { useLatestRef } from "./internal/use-latest-ref";
import { useEffect } from "react";

/**
 * Listen for a named message on the HTTP API
 * (the HTTP API port can be found in the config).
 */
export const useApiMessage = (name, onReceive) => {
  const mostRecentCallbackRef = useLatestRef(onReceive);

  useEffect(() => {
    const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
    const listener = API.messageSubscribe(name, mostRecentCallback);
    return () => API.messageUnsubscribe(listener);
  }, [mostRecentCallbackRef, name]);
};
