import { memo, forwardRef, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useFrame } from "@react-three/fiber";

import MediaInfo from "../../../logic/info/media-info";
import { MediaSrcPropType } from "../../../logic/prop-types";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import ThreeFiberCube from "../three-fiber-cube";

// Original from: https://discourse.threejs.org/t/dispose-things-correctly-in-three-js/6534

const sceneTraverse = (obj, fn) => {
  if (!obj) return;

  fn(obj);

  if (obj.children && obj.children.length > 0) {
    obj.children.forEach((o) => sceneTraverse(o, fn));
  }
};

const dispose = (scene) => {
  sceneTraverse(scene, (o) => {
    if (o.geometry) o.geometry.dispose();

    if (o.material) {
      if (o.material.length) {
        for (let i = 0; i < o.material.length; ++i) {
          const m = o.material[i];
          m.dispose();
          if (m.map) m.map.dispose();
        }
      } else {
        o.material.dispose();
        if (o.material.map) o.material.map.dispose();
      }
    }
  });
};

const LoadingIndicator = ({ ...rest }) => {
  const cubeRef = useRef(null);
  useFrame(() => {
    cubeRef.current.rotation.x -= 0.02;
    cubeRef.current.rotation.y += 0.04;
    cubeRef.current.rotation.z += 0.02;
  });
  return <ThreeFiberCube ref={cubeRef} size={[0.2, 0.2, 0.2]} color={"lightgrey"} {...rest} />;
};

const ThreeFiberModel = memo(
  forwardRef(({ src, showLoadingIndicator, ...rest }, ref) => {
    const url = src instanceof MediaInfo ? src.url : src;

    const [scene, setScene] = useState(null);
    useEffect(() => {
      if (!url) return;
      const gltfLoader = new GLTFLoader();
      gltfLoader.load(url, (gltf) => {
        setScene(gltf.scene);
      });
    }, [url]);

    // Dispose on unmount
    useEffect(() => () => dispose(scene), [scene]);

    return scene ? (
      <primitive ref={ref} object={scene} {...rest} dispose={null} />
    ) : (
      showLoadingIndicator && <LoadingIndicator />
    );
  })
);

ThreeFiberModel.propTypes = {
  src: MediaSrcPropType,
  showLoadingIndicator: PropTypes.bool,
};

export default ThreeFiberModel;
