import _ from "lodash";
import { memo, useState, useCallback } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Grid from "../../../components/grid";
import Button from "../../../components/button";
import Scroller from "../../../components/scroller";
import Classes from "../../../../helpers/classes";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const maxItemCount = 30;

  const [itemCount, setItemCount] = useState(9);
  const [showBorders, setShowBorders] = useState(false);
  const renderItems = (itemCount) =>
    _.map(_.range(0, itemCount), (index) => (
      <div
        key={index}
        className="item"
        style={{ backgroundColor: `hsl(${Math.round((index / maxItemCount) * 360)}, 100%, 35%)` }}
      >
        {index + 1}
      </div>
    ));

  const onMinusClick = useCallback(() => setItemCount(Math.max(0, itemCount - 1)), [itemCount]);
  const onPlusClick = useCallback(() => setItemCount(Math.min(maxItemCount, itemCount + 1)), [itemCount]);
  const onToggleBordersClick = useCallback(() => setShowBorders(!showBorders), [showBorders]);

  return (
    <Page className="demo grid ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Grid</Text>
          <p className="standard description">
            The Grid component allows easy layout of items in grid-like patterns in a flexible but coherent way across
            apps. A single <code>Grid</code> component is provided with an arbitrary function that, given an item count,
            decides how many columns are displayed on each row. Multiple layout functions are included in Ripple, or you
            can provide your own. As layouts are functions, they can also be composed to create advanced layouts.
          </p>
          <p className="standard description">
            The grid itself is only concerned with the DOM. Layout within the grid (such as item alignment and
            justification) is performed by targeting the Grid&apos;s generated elements with CSS.
          </p>
          <div className="controls">
            <Button className="standard plusminus" onClick={onMinusClick}>
              -
            </Button>
            <span>{itemCount} items</span>
            <Button className="standard plusminus" onClick={onPlusClick}>
              +
            </Button>
            <Button className="standard toggle-borders" onClick={onToggleBordersClick}>
              Toggle Borders
            </Button>
          </div>
          <div className={Classes.build("cases", { "show-borders": showBorders })}>
            <div className="case">
              <h2 className="standard">Fixed Columns Layout</h2>
              <p className="standard">
                Keeps a fixed number of columns and generates as many rows as necessary to fit the provided items.
              </p>
              <Grid layout={Grid.layout.fixedColumns(3)}>{renderItems(itemCount)}</Grid>
            </div>
            <div className="case">
              <h2 className="standard">Square Layout</h2>
              <p className="standard">Renders items in a square grid (as square as possible).</p>
              <Grid layout={Grid.layout.square()}>{renderItems(itemCount)}</Grid>
            </div>
            <div className="case">
              <h2 className="standard">Manual Layout</h2>
              <p className="standard">
                Renders a specific number of columns per individual row based on a provided definition. This example
                supports a maximum of 12 items.
              </p>
              <Grid
                className="center-aligned"
                layout={Grid.layout.manual({
                  1: [1],
                  2: [1, 1],
                  3: [1, 1, 1],
                  4: [2, 2],
                  5: [3, 2],
                  6: [1, 2, 3],
                  7: [3, 1, 3],
                  8: [4, 4],
                  9: [2, 4, 3],
                  10: [3, 4, 3],
                  11: [6, 3, 2],
                  12: [3, 3, 3, 3],
                })}
              >
                {renderItems(itemCount)}
              </Grid>
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
