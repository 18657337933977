import { memo } from "react";
import Strings from "../../../helpers/strings";
import Page from "../../components/page";

const PageComponent = memo(() => {
  return (
    <Page className="analytics-home ripple-dashboard" pauseTimeout="reset">
      <h1>{Strings.localized("AnalyticsHomeTitle").value}</h1>
      <p>{Strings.localized("AnalyticsHomeMessage").value}</p>
    </Page>
  );
});

export default PageComponent;
