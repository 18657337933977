import Edits from "../edits";

export default class TextInfo {
  _forceExist = false; // This is used to make Paragraphs appear semi-permanently when alt-clicked, even if empty
  editedValue = null;
  constructor(
    text = null, // Stays null if there is no backing text (no Text instance in the data)
    nodeId, // [NotNull] There is always a node that contains (or would contain) the text
    semantic, // [NotNull] The semantic that we asked for, regardless of the text existing or not
    language // [NotNull] Because either we are provided a language or we use the current language
  ) {
    this.text = text;
    this.nodeId = nodeId;
    this.semantic = semantic;
    this.language = language;
    this.rawValue = null; // No need for a private field like `value` because this is only stored in the TextInfo, not in Text
  }

  get exists() {
    // `this.value` Calls the getter (with fallback)
    return window.editing || this._forceExist || (this.value !== null && this.value !== "");
  }

  get value() {
    // We return the TextInfo's value (non-null if edited) and fallback to the Text's value
    return this.editedValue || (this.text ? this.text.value : null);
  }

  set value(newValue) {
    this.editedValue = newValue;
    this.markAsEdited();
  }

  get description() {
    if (!this.text || (this.text && this.text.language === this.language)) {
      return `Data: ${this.semantic} ${this.language.toUpperCase()}`;
    } else {
      const fallbackLanguage = this.text.language === "zx" ? "*" : this.text.language;
      return `Data: ${this.semantic} ${this.language.toUpperCase()} (${fallbackLanguage.toUpperCase()})`;
    }
  }

  markAsEdited() {
    Edits.setTextInfo(this.nodeId, this.semantic, this.language, this);
  }

  setForceExist(value) {
    this._forceExist = value;
  }
}
