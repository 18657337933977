import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import dataReducer from "../local/data-reducer";
import flagsReducer from "../local/flags-reducer";
import interactionReducer from "../local/interaction-reducer";
import languageReducer from "../local/language-reducer";
import progressReducer from "../local/progress-reducer";
import remoteStateReducer from "../local/remote-state-reducer";
import serverInfoReducer from "../local/serverinfo-reducer";
import shortcutsReducer from "../local/shortcuts-reducer";
import statusReducer from "../local/status-reducer";
import timeoutReducer from "../local/timeout-reducer";

const RootLocalReducerFactory = {
  create: function (appReducer, history) {
    return combineReducers({
      language: languageReducer,
      data: dataReducer,
      local: appReducer,
      remote: remoteStateReducer,
      timeout: timeoutReducer,
      serverInfo: serverInfoReducer,
      interaction: interactionReducer,
      startup: combineReducers({
        status: statusReducer,
        progress: progressReducer,
      }),
      flags: flagsReducer,
      shortcuts: shortcutsReducer,
      router: connectRouter(history),
    });
  },
};

export default RootLocalReducerFactory;
