import { useLanguage } from "ripple";
import { Overlay } from "./styled";

const AppOverlay = () => {
  useLanguage();
  return (
    <Overlay>
      <div id="modal-viewer-portal" style={{ backgroundColor: "red" }} />
    </Overlay>
  );
};

export default AppOverlay;
