import { getDistance } from "ol/sphere";
import { useRef, useState } from "react";
import { Log } from "../../ripple";
import { useGeolocation } from "./use-geolocation";

export const useGeofence = (position, radius, onEnter = () => {}, onExit = () => {}) => {
  if (!radius) throw new Error(`Geofence: Cannot initialize without a radius (received '${radius}')`);

  const previousDistanceRef = useRef(null);
  const [isInsideGeofence, setIsInsideGeofence] = useState(false);

  useGeolocation((location) => {
    const userCoordinate = [location.coords.latitude, location.coords.longitude];
    const targetCoordinate = [position.latitude, position.longitude];

    const currentDistance = getDistance(userCoordinate, targetCoordinate);
    const previousDistance = previousDistanceRef.current ?? Number.MAX_SAFE_INTEGER;
    previousDistanceRef.current = currentDistance;

    if (currentDistance <= radius && previousDistance > radius) {
      Log.info(`Geofence: Entered radius of ${radius} meters around ${position.latitude},${position.longitude}`);
      setIsInsideGeofence(true);
      onEnter();
    } else if (currentDistance > radius && previousDistance <= radius) {
      Log.info(`Geofence: Exited radius of ${radius} meters around ${position.latitude},${position.longitude}`);
      setIsInsideGeofence(false);
      onExit();
    }
  });

  return isInsideGeofence;
};
