import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";

const VideoMaterial = ({ url, muted }) => {
  const videoRef = useRef(null);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    if (videoRef.current) videoRef.current.muted = muted;
  }, [muted, video]);

  useEffect(() => {
    videoRef.current?.pause();

    const v = document.createElement("video");
    v.src = url;
    v.crossOrigin = "Anonymous";
    v.loop = true;
    v.play();

    videoRef.current = v;
    setVideo(v);
  }, [url]);

  return (
    <meshBasicMaterial attach="material" side={THREE.BackSide}>
      {video && <videoTexture attach="map" args={[video]} />}
    </meshBasicMaterial>
  );
};

VideoMaterial.propTypes = {
  url: PropTypes.string,
  muted: PropTypes.bool,
};

export default VideoMaterial;
