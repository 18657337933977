import { applyMiddleware, createStore } from "redux";
import Logger from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import Config from "../helpers/config";
import liteLoggingMiddleware from "./middleware/lite-logging-middleware";
import RootMasterReducerFactory from "./reducers/roots/root-master-reducer-factory";

const internalCreateStore = function (reducer, epic, localStore) {
  const rootMasterReducer = RootMasterReducerFactory.create(reducer);

  const middleware = [];

  const epicMiddleware = createEpicMiddleware({ dependencies: { localStore } });

  // Let `redux-observable` generate additional actions based on the configured epics
  middleware.push(epicMiddleware);

  // The logger must be last to log all actions including those generated by
  // other middleware such as redux-observable
  if (Config.dev.masterReduxLogger === "full") middleware.push(Logger);
  if (Config.dev.masterReduxLogger === "lite") middleware.push(liteLoggingMiddleware("Action [master]"));

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);
  const store = createStoreWithMiddleware(
    rootMasterReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  epicMiddleware.run(epic); // AFTER store creation!

  return store;
};

export default { createStore: internalCreateStore };
