import Log from "../../helpers/log";

export default class StringInfo {
  constructor(resource, key, language) {
    this.resource = resource;
    this.key = key;
    this.language = language;
  }

  get exists() {
    // `this.value` Calls the getter (with fallback)
    return window.editing || this._forceExist || (this.value !== null && this.value !== "");
  }

  get optionalValue() {
    const obj = this.resource.values[this.key];
    if (!obj) return null;

    let localizedValue = obj[this.language];
    if (!localizedValue) localizedValue = obj["zx"];

    return localizedValue;
  }

  get value() {
    const obj = this.resource.values[this.key];
    if (typeof obj === "undefined") {
      Log.warn(`Missing string resource for key '${this.key}'`);
      return `[${this.key}]`;
    }

    // Try to get the value for the exact language
    let localizedValue = obj[this.language];

    // Fallback on language neutral value if provided
    if (!localizedValue) localizedValue = obj["zx"];

    if (typeof localizedValue === "undefined") {
      Log.warn(`Missing '${this.language}' value for string resource with key '${this.key}'`);
      return `[${this.key} (${this.language})]`;
    }

    return localizedValue;
  }

  get description() {
    return `Res: ${this.key} ${this.language.toUpperCase()}`;
  }
}
