import { useEffect, useState } from "react";

import Log from "../../helpers/log";
import Env from "../../helpers/env";

/**
 * Read a value from the RCC native platform (NSUserDefaults / Settings Bundle on iOS)
 * and similar on Android.
 */
export const usePreference = (...args) => {
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    if (!Env.isRCC) {
      Log.info("Reading preferences is a no-op outside of RCC");
      return;
    }

    window.plugins.appPreferences.fetch(
      (value) => {
        setValue(value);
      },
      (error) => {
        Log.error(`Failed reading preferences value for arguments ${args}: ${error}`);
      },
      ...args
    );
  }, [args]);

  return value;
};
