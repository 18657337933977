import { PureComponent } from "react";
import PropTypes from "prop-types";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import Transitioner from "../../../components/transitioner";
import Button from "../../../components/button";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

class DemoTransition extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    overflow: PropTypes.bool,
    perspective: PropTypes.string,
    classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  constructor(props) {
    super(props);
    this.state = { toggled: false };
  }

  render() {
    return (
      <div className="demo-transition">
        <Transitioner
          className="transition-area"
          classNames={this.props.classNames}
          transitionKey={this.state.toggled}
          timeout={1000}
          style={{ perspective: this.props.perspective, overflow: this.props.overflow ? "visible" : "hidden" }}
        >
          {this.state.toggled ? <div className="content b">B</div> : <div className="content a">A</div>}
        </Transitioner>
        <Button className="standard" onClick={this.onButtonPress}>
          {this.props.name}
        </Button>
      </div>
    );
  }

  onButtonPress = () => {
    this.setState((prevState) => ({ toggled: !prevState.toggled }));
  };
}

class PageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { toggled: [] };
  }
  render() {
    return (
      <Page className="demo transitions ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          <Scroller
            className="page"
            innerClassName="page"
            startFadeRatio={0.1}
            endFadeRatio={0.04}
            scrollbarSideInset={3}
          >
            <Text className="title">Transitions</Text>
            <p className="standard description">
              These transition animations are included in Ripple and can be used to animate page navigations or
              transition from any UI element to another. Animations can affect <b>opacity</b>, <b>scale</b>,{" "}
              <b>3D transforms</b>, <b>corner radius</b>, <b>background color</b>, <b>text color</b>,{" "}
              <b>border width and color</b>, and more.
            </p>
            <div className="transitions">
              <DemoTransition name="appear-floating" classNames="appear-floating" overflow={true} />
              <DemoTransition name="scale-up-fade" classNames="scale-up-fade" overflow={true} />
              <DemoTransition name="scale-down-fade" classNames="scale-down-fade" overflow={true} />
              <DemoTransition name="photo-drop" classNames="photo-drop" overflow={true} />
              <DemoTransition name="flip-left (2D)" classNames="flip-left" />
              <DemoTransition name="flip-right (2D)" classNames="flip-right" />
              <DemoTransition name="flip-left (3D)" classNames="flip-left" overflow={true} perspective="300px" />
              <DemoTransition name="flip-right (3D)" classNames="flip-right" overflow={true} perspective="300px" />
              <DemoTransition name="slide-and-scale-from-left" classNames="slide-and-scale-from-left" />
              <DemoTransition name="slide-and-scale-from-right" classNames="slide-and-scale-from-right" />
              <DemoTransition name="slide-in-from-left" classNames="slide-in-from-left" />
              <DemoTransition name="slide-in-from-right" classNames="slide-in-from-right" />
              <DemoTransition name="slide-in-from-bottom-overlapping" classNames="slide-in-from-bottom-overlapping" />
              <DemoTransition name="slide-in-from-left-overlapping" classNames="slide-in-from-left-overlapping" />
              <DemoTransition name="slide-in-from-right-overlapping" classNames="slide-in-from-right-overlapping" />
              <DemoTransition name="slide-in-from-top-overlapping" classNames="slide-in-from-top-overlapping" />
              <DemoTransition name="slide-in-and-fade-from-bottom" classNames="slide-in-and-fade-from-bottom" />
              <DemoTransition name="slide-in-and-fade-from-left" classNames="slide-in-and-fade-from-left" />
              <DemoTransition name="slide-in-and-fade-from-right" classNames="slide-in-and-fade-from-right" />
              <DemoTransition name="slide-in-and-fade-from-top" classNames="slide-in-and-fade-from-top" />
              <DemoTransition name="slide-in-and-out-from-bottom" classNames="slide-in-and-out-from-bottom" />
              <DemoTransition name="slide-in-and-out-from-left" classNames="slide-in-and-out-from-left" />
              <DemoTransition name="slide-in-and-out-from-right" classNames="slide-in-and-out-from-right" />
              <DemoTransition name="slide-in-and-out-from-top" classNames="slide-in-and-out-from-top" />
              <DemoTransition name="fade-out-instant-in-glitchy" classNames="fade-out-instant-in-glitchy" />
              <DemoTransition name="fade-out-fade-in" classNames="fade-out-fade-in" />
              <DemoTransition name="crossfade" classNames="crossfade" />
              <DemoTransition name="fade-in-over" classNames="fade-in-over" />
            </div>
          </Scroller>
          {Env.isRCC && <DemoBackButton />}
        </SafeArea>
      </Page>
    );
  }
}

export default PageComponent;
