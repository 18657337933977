import _ from "lodash";

function calculate(preprocessed) {
  if (preprocessed.sessions.length === 0) return 0;
  const sums = _.map(preprocessed.sessions, (events) => {
    // Only consider navigation events
    const navigationEvents = _.filter(events, (e) => e.name === "navigation");
    // Group by distinct navigation paths
    const groupedByPath = _.groupBy(navigationEvents, (ne) => ne.data.path);
    // Count distinct navigation paths
    return _.values(groupedByPath).length;
  });

  if (sums.length === 0) return 0;

  return Math.round(_.mean(sums));
}

export default function (preprocessed) {
  this.name = "AverageDistinctPagesPerSession";
  this.format = (value) => value;
  this.value = calculate(preprocessed);
}
