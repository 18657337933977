// This triggers webpack bundling of styles.
// The styles are injected in a <style> tag at runtime,
// which makes them HMR-compatible.
import { App, resource } from "ripple";
import "../styles/main.less";
import nav from "./core/nav";
import stats from "./core/stats";
import "./modernizr-touchevents";
import AppRoot from "./react/app-root";
import localEpic from "./redux/local/local-epic";
import localReducer from "./redux/local/local-reducer";
import masterEpic from "./redux/master/master-epic";
import masterReducer from "./redux/master/master-reducer";
import useViewedStore from "./viewed-store";

App.bootstrap({
  element: document.querySelector("#app"),
  appRoot: AppRoot,
  home: () => null, // Override the configured home based on runtime information such as localStorage data (for example to show a tutorial on first run and another page on subsequent runs)
  nav: nav, // Dynamic navigation logic
  stats: stats, // Custom stats to add in the `/analytics` dashboard
  localReducer: localReducer, // Root reducer for the `local` portion of the local store
  localEpic: localEpic, // Epics triggered by dispatches to the local store
  masterReducer: masterReducer, // Root reducer for the IPC master store (maintained by the IPC master)
  masterEpic: masterEpic, // Epics triggered by IPC dispatches (run on the master store maintained by the IPC master)
  timeoutOverride: {
    timeoutActions: (defaultActions) => {
      useViewedStore.getState().resetViewed();
      defaultActions();
    },
  },
  fonts: {
    custom: {
      families: [
        "Roboto-BlackItalic",
        "Roboto-Thin",
        "Roboto-Bold",
        "Roboto-Black",
        "Roboto-Italic",
        "Roboto-ThinItalic",
        "Roboto-BoldItalic",
        "Roboto-Medium",
        "Roboto-LightItalic",
        "Roboto-MediumItalic",
        "Roboto-Light",
        "Roboto-Regular",
        "Atma-Bold",
        "Atma-Medium",
        "Atma-Light",
        "Atma-Regular",
        "Atma-SemiBold",
        "RobotoCondensed-LightItalic",
        "RobotoCondensed-Italic",
        "RobotoCondensed-Regular",
        "RobotoCondensed-Light",
        "RobotoCondensed-Bold",
        "RobotoCondensed-BoldItalic",
      ],
      urls: [
        resource("fonts/roboto/stylesheet.css"),
        resource("fonts/atma/stylesheet.css"),
        resource("fonts/roboto-condensed/stylesheet.css"),
      ],
    },
  },
});
