import { PureComponent } from "react";

import Page from "../../../components/page";
import Audio from "../../../../helpers/audio";
import Log from "../../../../helpers/log";
import Button from "../../../components/button";
import resource from "../../../../helpers/resource";
import Scroller from "../../../components/scroller";
import Env from "../../../../helpers/env";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";

class PageComponent extends PureComponent {
  render() {
    return (
      <Page className="demo audio ripple-dashboard" pauseTimeout="reset">
        <SafeArea>
          <Scroller
            className="page"
            innerClassName="page"
            startFadeRatio={0.1}
            endFadeRatio={0.04}
            scrollbarSideInset={3}
          >
            <Text className="title">Audio</Text>
            <h2 className="standard">Background</h2>
            {this.renderBackgroundDemo()}
            <h2 className="standard">Track</h2>
            {this.renderTrackDemo()}
            <h2 className="standard">Discrete</h2>
            {this.renderSoundDemo()}
          </Scroller>
          {Env.isRCC && <DemoBackButton />}
        </SafeArea>
      </Page>
    );
  }
  renderBackgroundDemo() {
    return (
      <div>
        <p className="standard">Background audio loops a sound until it&apos;s replaced by another one.</p>
        <div className="row">
          <Button className="standard" onClick={this.onBackground1ButtonPress}>
            Loop 1
          </Button>
          <Button className="standard" onClick={this.onBackground2ButtonPress}>
            Loop 2 (Left)
          </Button>
          <Button className="standard" onClick={this.onBackground3ButtonPress}>
            Loop 3 (Right)
          </Button>
          <Button className="standard red" onClick={this.onStopBackgroundButtonPress}>
            Stop
          </Button>
        </div>
      </div>
    );
  }

  renderTrackDemo() {
    return (
      <div>
        <p className="standard">Tracks plays each sound once without looping. Useful for things such as voice-overs.</p>
        <div className="row">
          <Button className="standard" onClick={this.onVoiceover1ButtonPress}>
            Voiceover 1
          </Button>
          <Button className="standard" onClick={this.onVoiceover2ButtonPress}>
            Voiceover 2
          </Button>
          <Button className="standard red" onClick={this.onVoiceoverStopButtonPress}>
            Stop
          </Button>
        </div>
      </div>
    );
  }

  renderSoundDemo() {
    return (
      <div>
        <p className="standard">Discrete sounds that play once and can&apos;t be stopped.</p>
        <div className="row">
          <Button className="standard wide" onClick={this.onSound1ButtonPress}>
            Sound 1<br />
            (Low Volume)
          </Button>
          <Button className="standard wide" onClick={this.onSound2ButtonPress}>
            Sound 2<br />
            (Normal Volume, Left)
          </Button>
          <Button className="standard wide" onClick={this.onSound3ButtonPress}>
            Sound 3<br />
            (Medium Volume, Right)
          </Button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // Mute the "touchFeedback" and "buttons" audio contexts so that we can hear our sound and only those!
    Audio.discrete("touchFeedback").configure({ muted: true });
    Audio.discrete("buttons").configure({ muted: true });
    Audio.discrete("mobile-buttons").configure({ muted: true });
  }

  componentWillUnmount() {
    // Unmute them to avoid side-effects when navigating to other pages from this one
    Audio.discrete("touchFeedback").resetConfiguration();
    Audio.discrete("buttons").resetConfiguration();
    Audio.discrete("mobile-buttons").resetConfiguration();
  }

  onBackground1ButtonPress = () => {
    Audio.background("demo-music").play(resource("audio/demo/background1.mp3"), {
      onStart: () => Log.info("Background 1 Started!"),
      onFinish: () => Log.info("Background 1 Finished!"),
    });
  };

  onBackground2ButtonPress = () => {
    Audio.background("demo-music").play(resource("audio/demo/background2.mp3"), {
      stereo: -1,
      onStart: () => Log.info("Background 2 Started!"),
      onFinish: () => Log.info("Background 2 Finished!"),
    });
  };

  onBackground3ButtonPress = () => {
    Audio.background("demo-music").play(resource("audio/demo/background3.mp3"), {
      stereo: 1,
      onStart: () => Log.info("Background 3 Started!"),
      onFinish: () => Log.info("Background 3 Finished!"),
    });
  };

  onStopBackgroundButtonPress = () => {
    Audio.background("demo-music").stop();
  };

  onVoiceover1ButtonPress = () => {
    Audio.track("demo-voiceover").play(resource("audio/demo/voiceover1.mp3"), {
      onStart: () => Log.info("VoiceOver 1 Started!"),
      onFinish: () => Log.info("VoiceOver 1 Finished!"),
    });
  };

  onVoiceover2ButtonPress = () => {
    Audio.track("demo-voiceover").play(resource("audio/demo/voiceover2.mp3"), {
      onStart: () => Log.info("VoiceOver 2 Started!"),
      onFinish: () => Log.info("VoiceOver 2 Finished!"),
    });
  };

  onVoiceoverStopButtonPress = () => {
    Audio.track("demo-voiceover").stop();
  };

  onSound1ButtonPress = () => {
    Audio.discrete("demo-discrete").play(resource("audio/demo/sound1.mp3"), { volume: 0.1 });
  };

  onSound2ButtonPress = () => {
    Audio.discrete("demo-discrete").play(resource("audio/demo/sound2.mp3"), { stereo: -1 });
  };

  onSound3ButtonPress = () => {
    Audio.discrete("demo-discrete").play(resource("audio/demo/sound3.mp3"), { volume: 0.3, stereo: 1 });
  };
}

export default PageComponent;
