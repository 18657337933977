import { PureComponent } from "react";

import Page from "../../../components/page";
import Button from "../../../components/button";
import GPIO from "../../../../helpers/gpio";
import resource from "../../../../helpers/resource";
import Text from "../../../components/text";

class PageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pin1Active: null,
      pin2Active: null,
    };
  }
  render() {
    return (
      <Page className="demo gpio ripple-dashboard" pauseTimeout="reset">
        <Text className="title">GPIO</Text>
        <p className="standard description">
          When hosted in <code>Ripple.ElectronContainer</code> (REC), Ripple&#39;s <code>GPIO</code> helper allows
          interfacing with GPIO boards in a simple manner. Outside of REC, GPIO operations are no-ops. To use this, you
          must first provide appropriate connection information in the <code>gpio</code> setting (in{" "}
          <code>local-config.yml</code>, as the actual values may not be the same on each machine). Ripple will
          automatically connect to the device on launch when connection information is provided and running in REC.
        </p>
        <div className="row blocks">
          <div className="column block">
            <h2 className="standard">Digital Output</h2>
            <div className="column">
              <p className="standard">
                Digital output sets the output of the pin to either high (set) or low (clear). For example, a specific
                board might output 5V in high state and 0V in low state. To test this, connect a LED (with an
                appropriate resistor) to pin 0.
              </p>
              <div className="row">
                <Button className="standard" onClick={this.setPin}>
                  Set Pin 0
                </Button>
                <Button className="standard" onClick={this.clearPin}>
                  Clear Pin 0
                </Button>
              </div>
            </div>
            <h2 className="standard">Digital Input</h2>
            <div className="column">
              <p className="standard">
                Digital input reads the input state of a pin: high (voltage applied) or low (no voltage applied). The{" "}
                <code>GPIO</code> API offers either to treat that state as a toggle switch or as a push button. To test
                this, connect switches to pins 1 and/or 2, subscribe using the buttons below, then toggle the physical
                switches.{" "}
                <span className="important">
                  Note that this demo assumes that the pins are <b>normally high</b> (that they receive voltage when the
                  physical switch is off). Connecting a circuit that results in the pin being <b>normally low</b> (0V)
                  will result in inverted behavior in the demo below. In custom apps, the pin normal state can be
                  specified when subscribing.
                </span>
              </p>
              <p className="standard">
                It is possible to subscribe to the same pin multiple times, say multiple parts of an app need to know
                about the state of a single pin. This can also occur when two GPIO-subscribed components coexist (for
                example during page transitions). It is also possible to subscribe to a single pin as a toggle switch{" "}
                <i>and</i> as a push button simultaneously, as the demo below demonstrates.
              </p>
              <h3 className="standard">Multiple Subscriptions to the Same Pin</h3>
              <div className="row">
                <Button className="standard" onClick={this.toggleSwitchSubscribePin1}>
                  Subscribe (Pin 1)
                </Button>
                <Button className="standard" onClick={this.toggleSwitchUnsubscribePin1}>
                  Unsubscribe (Pin 1)
                </Button>
                {this.renderToggleSwitchState(this.state.pin1Active)}
                {this.renderPushButton()}
              </div>
              <h3 className="standard">Single Toggle Switch Subscription</h3>
              <div className="row">
                <Button className="standard" onClick={this.toggleSwitchSubscribePin2}>
                  Subscribe (Pin 2)
                </Button>
                <Button className="standard" onClick={this.toggleSwitchUnsubscribePin2}>
                  Unsubscribe (Pin 2)
                </Button>
                {this.renderToggleSwitchState(this.state.pin2Active)}
              </div>
            </div>
          </div>
          <div className="column block">
            <h2 className="standard" style={{ marginBottom: 40 }}>
              Reference Circuit
            </h2>
            <img className="reference-circuit" src={resource("images/demo/gpio-test-circuit.png")} />
            <h2 className="standard">Pin Convention</h2>
            <img className="input-output-convention" src={resource("images/demo/gpio-pin-convention.png")} />
            <p>
              By convention, we use pins <code>1</code> through <code>X</code> for input (such as buttons) so that the
              pin numbers correspond to numbered keys on the keyboard. For output, we start with pin <code>0</code>,
              then we take pins from the last one, going back. Following this pattern helps to keep the same type of
              pins together and to stay coherent across GPIO-enabled apps.
            </p>
          </div>
        </div>
      </Page>
    );
  }

  renderToggleSwitchState = (active) => {
    switch (active) {
      case null:
        return <span className="pin-state not-setup">Toggle: --</span>;
      case true:
        return <span className="pin-state on">Toggle: ON</span>;
      case false:
        return <span className="pin-state off">Toggle: OFF</span>;
    }
  };

  renderPushButton = () => {
    return (
      <Button className="push-button" getApi={this.getPushButtonApi}>
        Push Button
      </Button>
    );
  };

  getPushButtonApi = (api) => (this.pushButtonApi = api);

  setPin = () => {
    GPIO.set(0);
  };

  clearPin = () => {
    GPIO.clear(0);
  };

  toggleSwitchSubscribePin1 = () => {
    // Like other global events, each subscription must be unsubscribed before re-subscribing, else the callback (and by
    // extension the page) will leak. Usually just subscribing in `componentDidMount` and unsubscribing in
    // `componentWillUnmount` does the job (and is good practice, as for other global subscriptions). In this case, as
    // we provide buttons this check is necessary to avoid leaking callbacks when pressing the subscribe button multiple
    // times in a row.
    if (this.pin1Subscribed) return;
    this.pin1Subscribed = true;

    this.pin1ToggleSwitchSubscription = GPIO.toggleSwitchSubscribe(1, GPIO.Pin.NORMALLY_HIGH, (state) =>
      this.setState({ pin1Active: state })
    );
    this.pin1PushButtonSubscription = GPIO.pushButtonSubscribe(1, GPIO.Pin.NORMALLY_HIGH, (state) => {
      this.pushButtonApi.performClick();
    });
  };

  toggleSwitchUnsubscribePin1 = () => {
    this.pin1Subscribed = false;
    GPIO.toggleSwitchUnsubscribe(this.pin1ToggleSwitchSubscription);
    GPIO.pushButtonUnsubscribe(this.pin1PushButtonSubscription);
    this.setState({ pin1Sub1Active: null, pin1Sub2Active: null });
  };

  toggleSwitchSubscribePin2 = () => {
    // See comment in handler for pin 1 subscription
    if (this.pin2Subscribed) return;
    this.pin2Subscribed = true;

    this.switch2Subscription = GPIO.toggleSwitchSubscribe(2, GPIO.Pin.NORMALLY_HIGH, (state) =>
      this.setState({ pin2Active: state })
    );
  };

  toggleSwitchUnsubscribePin2 = () => {
    this.pin2Subscribed = false;
    GPIO.toggleSwitchUnsubscribe(this.switch2Subscription);
    this.setState({ pin2Active: null });
  };
}

export default PageComponent;
