import _ from "lodash";
import { Node } from "mediaclient.js";
import Localization from "../helpers/localization";
import Log from "../helpers/log";
import Edits from "../logic/edits";
import MediaInfo from "../logic/info/media-info";
import TextInfo from "../logic/info/text-info";

//
// NOTE: For Ripple to do its job properly, it's important to use the
// appropriate functions to obtain texts and medias:
//
// - Use the "required" functions when the data MUST be present for code that depends
//   on it to run properly (Ripple "fails early" when the data is missing, simplifying diagnosics)
//
// - Use the "wanted" functions for things that SHOULD be present but MAY not be present when doing
//   data entry. Using "wanted" functions logs warnings and display placeholders for missing data, keeping the app functional.
//
// - Use the "optional" functions for data that CAN be present but is optional, and for which the app
//   has an appropriate fallback mechanism in case of missing data.
//

// Recursively inherit things from parent nodes

function inherit(node, selector) {
  const text = selector(node);
  if (text.exists) return text;

  const parent = node.parent;
  if (!parent) return null;

  return inherit(parent, selector);
}

function getSpecifiedOrCurrentLanguage(language) {
  return language || Localization.getCurrentLanguage();
}

/**
 * Obtain the localized metadata for a given semantic from a list of metadatas
 * (where a metadata is anything that has a semantic and a language)
 * */
function getLocalized(metadatas, semantic, language, fallback, checkValidity) {
  const matches = _.filter(metadatas, (m) => m.semantic === semantic);
  if (matches.length === 0) return null;

  // We take the exact language if it's there
  const localized = _.find(matches, (t) => t.language === language);
  if (localized && checkValidity(localized)) return localized;

  // If fallback is disabled, don't try anything else
  if (!fallback) return null;

  // Fallback to neutral language
  const neutral = _.find(matches, (t) => t.language === "zx");
  if (neutral && checkValidity(neutral)) return neutral;

  // Fallback to any language in the order in which they are defined
  return _.find(matches, (m) => checkValidity(m)) || null;
}

/**
 * Obtain the localized Text instance (if any) for the provided arguments.
 * @param {Node} The node to get the text from
 * @param {String} The required text semantic name
 * @param {Language} The language we're looking for
 * @param {boolean=true} Whether to fallback to neutral and other languages or not
 */
function getText(node, semantic, language, fallback = true) {
  if (typeof language === "undefined") throw new Error(`Language was not provided for getText() call!`);
  return getLocalized(node.texts, semantic, language, fallback, (text) => text.value.trim() !== "");
}

/**
 * Obtain the localized media info (if any) for the provided media semantic and <tt>Language</tt>.
 * @param {Node} The node to get the media from
 * @param {String} The required media semantic name
 * @param {Language} The language we're looking for
 * @param {boolean=true} Whether to fallback to neutral and other languages or not
 */
function getMedia(node, semantic, language, fallback = true) {
  if (typeof language === "undefined") throw new Error("Language was not provided in Node getMedia() call");

  const media = getLocalized(node.medias, semantic, language, fallback, (media) => true);
  if (!media) return { media: null, getUrl: (format) => null };

  // It's the user's responsibility to get the URL for the required
  // formats once the media itself has been retrieved.
  const getUrl = (format) => node.addons.buildMediaUrl(media, format);

  return { media, getUrl };
}

// Text

Node.prototype.requiredText = function (semantic, language = null, fallback) {
  const textInfo = this.optionalText(semantic, language, fallback);
  if (!textInfo.exists)
    throw new Error(
      `Required text of semantic '${semantic}' not found on node of type '${this.semantic}' (${this.id})`
    );
  return textInfo;
};

Node.prototype.wantedText = function (semantic, language = null, fallback) {
  const textInfo = this.optionalText(semantic, language, fallback);
  if (!textInfo.exists) {
    Log.warn(`Wanted text of semantic '${semantic}' not found on node of type '${this.semantic}' (${this.id})`);
  }
  return textInfo;
};

Node.prototype.optionalText = function (semanticDescriptor, language = null, fallback) {
  const actualLanguage = getSpecifiedOrCurrentLanguage(language);

  // Allows passing an array of semantics instead of a single semantic, for automatic fallback if non-existent.
  // Makes this:
  //   node.optionalText(["ExternalTitle", "Title"])
  // Equivalent to this:
  //   const externalTitle = itemNode.optionalText("ExternalTitle");
  //   const title = itemNode.wantedText("Title");
  //   const src = externalTitle.exists ? externalTitle : title;
  const semanticsByDescSpecificity = Array.isArray(semanticDescriptor) ? semanticDescriptor : [semanticDescriptor];

  // We return an object that describes the text even
  // if it doesn't exist in the data, which allows
  // components to know about the text they should be displaying
  // even if the text itself is not found in the node.
  const getTextInfo = (text, semantic) =>
    Edits.getTextInfo(this.id, semantic, actualLanguage) || new TextInfo(text, this.id, semantic, actualLanguage);

  for (let i = 0; i < semanticsByDescSpecificity.length; i++) {
    const semantic = semanticsByDescSpecificity[i];
    const text = getText(this, semantic, actualLanguage, fallback);
    if (text) return getTextInfo(text, semantic);

    // If nothing is found by the end of the loop, consider the missing media to be the less specific semantic
    // (most often it is less specific because it is the default semantic, thus more likely to exist)
    if (i === semanticsByDescSpecificity.length - 1) return getTextInfo(text, semantic);
  }
};

Node.prototype.wantedInheritedText = function (semantic, language = null, fallback) {
  const textInfo = inherit(this, (n) => n.optionalText(semantic, language));
  if (!textInfo?.exists) {
    Log.warn(
      `Wanted inherited text of semantic '${semantic}' not found from node of type '${this.semantic}' (${this.id})`
    );
  }
  return textInfo;
};

Node.prototype.optionalInheritedText = function (semantic, language = null, fallback) {
  return inherit(this, (n) => n.optionalText(semantic, language, fallback));
};

// Media

Node.prototype.requiredMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const value = this.optionalMedia(semanticDescriptor, formatDescriptor, language, fallback);
  if (!value.exists)
    throw new Error(
      `Required media of semantic '${semanticDescriptor}' and format ${JSON.stringify(
        formatDescriptor
      )} not found on node of type '${this.semantic}' (${this.id})`
    );
  return value;
};

Node.prototype.wantedMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  const value = this.optionalMedia(semanticDescriptor, formatDescriptor, language, fallback);
  if (!value.exists) {
    Log.warn(
      `Wanted media of semantic '${semanticDescriptor}' and format ${JSON.stringify(
        formatDescriptor
      )} not found on node of type '${this.semantic}' (${this.id})`
    );
  }
  return value;
};

Node.prototype.optionalMedia = function (semanticDescriptor, formatDescriptor, language = null, fallback) {
  if (typeof formatDescriptor !== "string" && typeof formatDescriptor !== "object")
    throw new Error(`Invalid format descriptor ${formatDescriptor}`);

  const actualLanguage = getSpecifiedOrCurrentLanguage(language);

  const getMediaInfo = (semantic) => {
    const { media, getUrl } = getMedia(this, semantic, actualLanguage, fallback);
    const key = `media-info:${this.id}:${media?.id ?? "null"}:${semantic}:${JSON.stringify(
      formatDescriptor
    )}:${actualLanguage}`;

    // We use a cache to provide the same instance for multiple calls given the same combination
    // of arguments. Having a cache like this avoids direct instance comparison, and incidentally
    // unnecessary renders of media components and other subtle issues. The cache is stored in the node
    // itself so that it gets flushed when data changes and nodes are replaced with newer versions.

    // If a MediaInfo instance already exists for this combination of arguments,
    // return the existing instance. We use the node's built-in `storage` object
    // as a cache.
    const existingMediaInfo = this.storage[key];
    if (existingMediaInfo) return existingMediaInfo;

    const newMediaInfo = new MediaInfo(media, this.id, semantic, formatDescriptor, actualLanguage, getUrl);
    this.storage[key] = newMediaInfo;

    return newMediaInfo;
  };

  // Allows passing an array of semantics instead of a single semantic, for automatic fallback if non-existent.
  // Makes this:
  //   node.optionalMedia(["AlbumThumbnail", "Media"], "AlbumThumbnail")
  // Equivalent to this:
  //   const explicitThumbnail = itemNode.optionalMedia("AlbumThumbnail", "AlbumThumbnail");
  //   const implicitThumbnail = itemNode.wantedMedia("Media", "AlbumThumbnail");
  //   const src = explicitThumbnail.exists ? explicitThumbnail : implicitThumbnail;
  const semanticsByDescSpecificity = Array.isArray(semanticDescriptor) ? semanticDescriptor : [semanticDescriptor];
  for (let i = 0; i < semanticsByDescSpecificity.length; i++) {
    const mediaInfo = getMediaInfo(semanticsByDescSpecificity[i]);
    if (mediaInfo.exists) return mediaInfo;

    // If nothing is found by the end of the loop, consider the missing media to be the less specific semantic
    // (most often it is less specific because it is the default semantic, thus more likely to exist)
    if (i === semanticsByDescSpecificity.length - 1) return mediaInfo;
  }
};

Node.prototype.wantedInheritedMedia = function (semantic, formatDescriptor, language = null, fallback) {
  const value = inherit(this, (n) => n.optionalMedia(semantic, formatDescriptor, language, fallback));
  if (!value?.exists) {
    Log.warn(
      `Wanted inherited media of semantic '${semantic}' and format ${JSON.stringify(
        formatDescriptor
      )} not found from node of type '${this.semantic}' (${this.id})`
    );
  }
  return value;
};

Node.prototype.optionalInheritedMedia = function (semantic, formatDescriptor, language = null, fallback) {
  return inherit(this, (n) => n.optionalMedia(semantic, formatDescriptor, language, fallback));
};
