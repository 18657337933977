import Env from "../../helpers/env";

// Media (from least to most permissive)
export default class MediaInfo {
  constructor(
    media = null, // Stays null if there is no backing media (no Media instance in the data)
    nodeId, // [NotNull] There is always a node that contains (or would contain) the media
    semantic, // [NotNull] The semantic that we asked for, regardless of the media existing or not
    formatDescriptor, // [NotNull] The format(Descriptor) that we asked for, regardless of it existing or not
    language, // [NotNull] Because either we are provided a language or we use the current language
    getUrl // [NotNull]
  ) {
    this.media = media;
    this.nodeId = nodeId;
    this.semantic = semantic;
    this.formatDescriptor = formatDescriptor;
    this.language = language;
    this.getUrl = getUrl;
  }

  get exists() {
    return this.media !== null;
  }

  get type() {
    if (!this.exists) return null;
    return this.media.type.name.toLowerCase();
  }

  get format() {
    if (!this.exists) return null;
    // The format descriptor can either be a single format or an object describing which format to pick for each possible
    // media type (use this if the media accepts multiple types on the server).
    // Example: const formatDescriptor = { image: "MyImageFormat", video: "MyVideoFormat" }
    // Picks: MyImageFormat if the media's type is "image", MyVideoFormat if the media's type if "video"
    const format = typeof this.formatDescriptor === "string" ? this.formatDescriptor : this.formatDescriptor[this.type];
    if (typeof format === "undefined") throw new Error(`No format specified for media type ${this.type}`);
    return format;
  }

  get url() {
    const format = this.format;
    if (!format) return null;
    return this.getUrl(format);
  }

  /**
   * If the URL is an RCC local file, return the platform-native file URL instead of the one the webview expects.
   * For now this is mostly used to provide Wikitude with native paths for it to dynamically load files in ARchitect worlds (see Wikitude demo page).
   * Another example would be to open a native (ex: Obj-C/Swift/Java) image viewer by providing the nativeURL to the native code directly.
   */
  get nativeURL() {
    if (!Env.isRCC) return this.url;
    return Env.isIos
      ? this.url?.replace("ripple://localhost/_app_file_/", "file:///")
      : this.url?.replace("http://localhost/_app_file_/", "file:///");
  }

  get description() {
    if (!this.media || (this.media && this.media.language === this.language)) {
      return `Data: ${this.semantic} ${this.language.toUpperCase()}`;
    } else {
      const fallbackLanguage = this.media.language === "zx" ? "*" : this.media.language;
      return `Data: ${this.semantic} ${this.language.toUpperCase()} (${fallbackLanguage.toUpperCase()})`;
    }
  }
}
