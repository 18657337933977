import { memo } from "react";
import { Route, useLocation } from "react-router";
import { Config, RouteTransitioner, useData, useDebug, useRemoteState } from "ripple";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { backPanelColor } from "./common";
import AppOverlay from "./components/app-overlay";
import AppUnderlay from "./components/app-underlay";
import GracePopup from "./components/grace-popup";
import PanoramaPage from "./pages/panorama-page";
import SectionsPage from "./pages/sections-page";
import TransitionPage from "./pages/transition-page";
/* APP_PAGE_IMPORTS_INSERTION_POINT */

const StyledRippleRoot = createGlobalStyle`
  body {
    background-color: black;
  }
  #app .root {
    background-color: black;
  }
`;

const StyledAppRoot = styled.div`
  /* Customize app defaults here (fonts, etc.) */
  color: ${backPanelColor};
  font-family: "Roboto-Regular";
`;

export const AppRootRouteTransitioner = styled(RouteTransitioner)`
  width: 100%;
  height: 100%;
`;

const AppRoot = memo(() => {
  const location = useLocation();
  const debug = useDebug();
  const data = useData();
  const remote = useRemoteState();

  const themeName = Config.theme;
  const themeObject = Config.themes[themeName];
  if (typeof themeObject === "undefined") throw new Error(`No theme named '${Config.theme}'`);

  const theme = {
    debug,
    name: themeName,
    ...themeObject,
  };

  // By default, transition only occurs when the first path component changes.
  // However, this might not make sense in all apps. Feel free to change this
  // to match your needs! For example, if you want a transition to occur every
  // time the URL changes (including the query string), use
  // `location.pathname + location.search` as a key.
  const transitionKey = `location.pathname + location.search`;

  // Do not render pages as long as the data isn't available, as most pages depend on it heavily.
  const protect = (Component) => data && (!Config.ipc.enabled || !!remote) && Component;

  return (
    <ThemeProvider theme={theme}>
      <StyledRippleRoot />
      <StyledAppRoot style={{ width: "100%", height: "100%" }}>
        <AppUnderlay />
        <AppRootRouteTransitioner transitionKey={transitionKey} location={location}>
          <Route path="/start" component={protect(TransitionPage)} />
          <Route path="/transition/:id" component={protect(TransitionPage)} />
          <Route path="/panorama/:id" component={protect(PanoramaPage)} />
          <Route path="/sections/:id" component={protect(SectionsPage)} />
          {/* APP_ROUTES_INSERTION_POINT */}
        </AppRootRouteTransitioner>
        <GracePopup />
        <AppOverlay />
      </StyledAppRoot>
    </ThemeProvider>
  );
});

export default AppRoot;
