export default class Coordinates {
  // Spherical/Cartesian Coordinate Conversion
  // https://en.wikipedia.org/wiki/Spherical_coordinate_system
  // https://blog.nobel-joergensen.com/2010/10/22/spherical-coordinates-in-unity/
  // NOTE: The terminology is based on the three.js camera conventions which is
  // also present in the first image caption for the *Spherical coordinate system* article.

  /**
   * Converts spherical coordinates (azimuths/angles on a sphere)
   * to cartesian coordinates.
   */
  static sphericalToCartesian(
    radius, // Euclidean distance (straight-line distance) from the point to the origin
    theta, // Equator angle in radians around the y (up) axis
    phi // Polar angle in radians from the y (up) axis
  ) {
    const x = radius * Math.cos(phi) * Math.cos(theta);
    const y = radius * Math.sin(phi);
    const z = radius * Math.cos(phi) * Math.sin(theta);
    return { x, y, z };
  }

  /**
   * Converts cartesian coordinates to spherical coordinates of
   * a vector pointing from the origin to the specified point.
   * Refer to `sphericalToCartesian` for the meaning of `theta` and `phi`
   */
  static cartesianToSpherical(x, y, z) {
    if (x === 0) x = Number.MIN_VALUE;

    const radius = Math.sqrt(x * x + y * y + z * z);

    let theta = Math.atan(z / x);
    if (x < 0) theta += Math.PI;

    const phi = Math.asin(y / radius);

    return { radius, theta, phi };
  }
}
