import { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { Config, Strings, Timeout, usePrevious } from "ripple";
import { Background, Countdown, GracePopupTransitionGroup, Popup, PopupButton, PopupButtons, Title } from "./styled";

const GracePopup = memo(() => {
  const grace = useSelector((state) => state.timeout.grace);
  const pathname = useSelector((state) => state.router.location.pathname);

  const [visible, setVisible] = useState(false);
  const previousGrace = usePrevious(grace);
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    const enteringGracePeriod = !previousGrace && grace;
    const onHomepage = pathname === Config.home;
    const wasNotPreviouslyOnHomepage = previousPathname !== Config.home;

    if (enteringGracePeriod && !onHomepage) {
      setVisible(true);
    } else if (onHomepage && wasNotPreviouslyOnHomepage) {
      setVisible(false);
    }
  }, [previousGrace, grace, pathname, previousPathname]);

  const continueButtonPressed = useCallback(() => {
    // The timeout counter will reset itself because
    // there is an interaction when pressing the button,
    // so we only need to hide the overlay.
    setVisible(false);
  }, []);

  const restartButtonPressed = useCallback(() => {
    setVisible(false);
    Timeout.force();
  }, []);

  return (
    <GracePopupTransitionGroup>
      {visible && (
        <CSSTransition key="popup" classNames="fade-out-fade-in" timeout={500}>
          <Background>
            <Popup>
              <Title>{Strings.localized("TimeoutGracePopupTitle")}</Title>
              <Countdown>{grace || 0}</Countdown>
              <PopupButtons>
                <PopupButton onClick={continueButtonPressed}>
                  {Strings.localized("TimeoutGracePopupContinueButton")}
                </PopupButton>
                <PopupButton onClick={restartButtonPressed}>
                  {Strings.localized("TimeoutGracePopupRestartButton")}
                </PopupButton>
              </PopupButtons>
            </Popup>
          </Background>
        </CSSTransition>
      )}
    </GracePopupTransitionGroup>
  );
});

export default GracePopup;
