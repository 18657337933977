import { memo, useState, useCallback } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import Maximizer from "../../../components/maximizer";
import Image from "../../../components/image";
import Button from "../../../components/button";
import VideoPlayer from "../../../components/video-player";
import resource from "../../../../helpers/resource";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Classes from "../../../../helpers/classes";
import { useVideoSharedPlaybackState } from "../../../hooks/specialized/use-video-shared-playback-state";
import Text from "../../../components/text";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const [isImageMaximized, setIsImageMaximized] = useState(false);
  const [isVideoPlayerMaximized, setIsVideoPlayerMaximized] = useState(false);
  const [isAnythingMaximized, setIsAnythingMaximized] = useState(false);
  const videoSharedPlaybackState = useVideoSharedPlaybackState();

  const durationInMaximizedMode = 2000;

  const onMaximizeImage = useCallback(() => {
    setIsImageMaximized(true);
    setTimeout(() => setIsImageMaximized(false), durationInMaximizedMode);
  }, []);

  const onMaximizeVideoPlayer = useCallback(() => {
    setIsVideoPlayerMaximized(true);
    setTimeout(() => setIsVideoPlayerMaximized(false), durationInMaximizedMode);
  }, []);

  const onMaximizeAnything = useCallback(() => {
    setIsAnythingMaximized(true);
    setTimeout(() => setIsAnythingMaximized(false), durationInMaximizedMode);
  }, []);

  return (
    <Page className="demo maximizer ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Maximizer</Text>
          <p className="standard description">
            The <code>Maximizer</code> component is a container that can animate its content to fullscreen with a simple
            toggle. It is useful to implement a simple &quot;view in fullscreen&quot; feature for images and videos, but
            it can bring whatever it contains to fullscreen while maintaining internal layout during the animation.
          </p>
          <div className="cases">
            <div className="case">
              <h2 className="standard">Image</h2>
              <Maximizer maximized={isImageMaximized}>
                {(maximized) => (
                  <div className={Classes.build("maximizer-demo-content-container", { maximized })}>
                    <Image scaling="fill" src={resource("images/demo/video-still.jpg")} />
                  </div>
                )}
              </Maximizer>
              <Button
                className="standard maximize"
                blockInteractionFor={durationInMaximizedMode + 500}
                onClick={onMaximizeImage}
              >
                Maximize
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">VideoPlayer</h2>
              <Maximizer maximized={isVideoPlayerMaximized}>
                {(maximized) => (
                  <div className={Classes.build("maximizer-demo-content-container", { maximized })}>
                    <VideoPlayer
                      scaling="fit"
                      src={resource("video/demo/video.mp4")}
                      sharedPlaybackState={videoSharedPlaybackState}
                    />
                  </div>
                )}
              </Maximizer>
              <Button
                className="standard maximize"
                blockInteractionFor={durationInMaximizedMode + 500}
                onClick={onMaximizeVideoPlayer}
              >
                Maximize
              </Button>
            </div>
            <div className="case">
              <h2 className="standard">Anything</h2>
              <Maximizer maximized={isAnythingMaximized}>
                {(maximized) => (
                  <div className={Classes.build("maximizer-demo-content-container", { maximized })}>
                    <div className="anything">
                      <div className="center" />
                      <div className="top-left" />
                      <div className="top-right" />
                      <div className="bottom-right" />
                      <div className="bottom-left" />
                    </div>
                  </div>
                )}
              </Maximizer>
              <Button
                className="standard maximize"
                blockInteractionFor={durationInMaximizedMode + 500}
                onClick={onMaximizeAnything}
              >
                Maximize
              </Button>
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
