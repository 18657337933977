const Status = {
  LOADING: "LOADING",
  SERVER_UNREACHABLE: "SERVER_UNREACHABLE",
  DATA_REQUESTED: "DATA_REQUESTED",
  DATA_RECEIVED: "DATA_RECEIVED",
  DATA_LOCAL: "DATA_LOCAL",
  DATA_STATIC: "DATA_STATIC",
  DATA_NONE: "DATA_NONE",
  DATA_ERROR: "DATA_ERROR",
  FETCHING_MEDIAS: "FETCHING_MEDIAS",
};

export default Status;
