import { PureComponent } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router";

import Transitioner from "../transitioner";

export default class RouteTransitioner extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.node, // Routes for the internal Switch
    // See `Transitioner` for additional required and optional props
  };

  render() {
    return (
      <Transitioner {...this.props}>
        {/* If we don't provide this.props.location to the Switch, `componentDidMount`
            is called twice for pages. Read this for details:
            https://github.com/reactjs/react-transition-group/issues/79#issuecomment-334915988
            https://github.com/reactjs/react-transition-group/issues/136 */}
        <Switch location={this.props.location}>
          {this.props.children}
          <Route render={this.renderNoRoute} />
        </Switch>
      </Transitioner>
    );
  }

  renderNoRoute = (props) => {
    throw new Error(`No route matches path '${props.location.pathname}'`);
  };
}
