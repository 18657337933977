import DataActions from "../../actions/local/data-actions";

const serverInfoReducer = function (state = null, action) {
  switch (action.type) {
    case DataActions.DATA_UPDATE_SERVERINFO:
      return action.serverInfo;
    default:
      return state;
  }
};

export default serverInfoReducer;
