import PropTypes from "prop-types";
import { memo } from "react";
import Classes from "../../../helpers/classes";

const Box = memo(({ className, style, children, aspectRatio }) => {
  const actualStyle = { ...style };

  if (aspectRatio) {
    if (!/^\d+?:\d+?$/.test(aspectRatio)) throw new Error("Aspect ratio is not in the expected 'X:X' format");
    const components = aspectRatio.split(":");
    const ratio = parseInt(components[1]) / parseInt(components[0]);
    actualStyle.paddingTop = `${ratio * 100}%`;
  }

  return (
    <div
      style={actualStyle}
      className={Classes.build("ripple-box", "debug-show-bounds", { "aspect-ratio": !!aspectRatio }, className)}
    >
      <div className="box-content">{children}</div>
    </div>
  );
});

Box.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  aspectRatio: PropTypes.string, // An aspect ratio in the following format: "16:9", "4:3", etc.
};

export default Box;
