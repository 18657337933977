import _ from "lodash";
import { memo, Children } from "react";
import PropTypes from "prop-types";

import Classes from "../../../helpers/classes";

/**
 * This component renders its children in rows and columns. The way items are laid out depends on the provided layout
 * function. Ripple provides a couple of reusable layout functions, but a custom layout can easily be provided directly
 * inside an app (look at Ripple's built-in layouts for examples).
 *
 * A layout is a function that receives the number of items from the grid and returns an array
 * of numbers of columns per row.
 *
 * The simplest possible layout (and layout usage) would be this:
 *
   ```
   <Grid layout={count => [count]}>...</Grid>
   ```
 *
 * This would lay out all items in a single row.
 *
 * When you need to parameterize the layout, use a layout creator instead:
 *
   ```
   const makeCustomGridLayout = numberOfItemsOnFirstAndLastRows => {
     // Here, return the actual layout function (which captures the values it needs to do its job)
     return count => [
       numberOfItemsOnFirstAndLastRows,
       Math.min(0, count - numberOfItemsOnFirstAndLastRows * 2),
       numberOfItemsOnFirstAndLastRows
     ];
   };

   <Grid layout={makeCustomGridLayout(4)}>...</Grid>
   ```
 *
 * This layout would make the grid put [numberOfItemsOnFirstAndLastRows] items on the first row,
 * [numberOfItemsOnFirstAndLastRows] items on the last row, and the remaining items on the middle row.
 *
 * You can use any of the Ripple-provided layouts or provide a custom app-specific layout function for fancier layouts.
 */
const Grid = memo(({ className, style, children, layout, ...rest }) => {
  const childCount = Children.count(children);
  const childrenBucket = children.slice(0); // Children are picked from this until none remains

  return (
    <div style={style} className={Classes.build("ripple-grid", className)}>
      {_.map(layout(childCount), (columns, index) => {
        const items = childrenBucket.splice(0, columns);
        return (
          <div key={`row-${index}`} className={Classes.build("grid-row", { empty: items.length === 0 })}>
            {items}
          </div>
        );
      })}
    </div>
  );
});

Grid.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
  layout: PropTypes.func.isRequired,
};

Grid.layout = {
  fixedColumns: require("./layouts/fixed-columns").default,
  manual: require("./layouts/manual").default,
  square: require("./layouts/square").default,
};

export default Grid;
