import _ from "lodash";
import Member from "./member";
import Long from "long";

const invalidIdMessage = "The provided member ID is not a number";

const Enum = function (name) {
  this.name = name;
  this.all = [];

  function ensureNoDuplicates(members) {
    const uniques = _.uniqBy(members, (m) => m.id);
    if (uniques.length !== members.length) throw new Error(`Duplicate ID detected in enum '${this.name}'`);
    return members;
  }

  /** Add members to the enum */
  this.addMembers = (members) => {
    if (!Array.isArray(members)) throw new Error("enum.addMembers() requires an array");
    _.every(members, this.ensureMemberIsValid); // Make sure members contain all of the required properties
    this.all = ensureNoDuplicates(this.all.concat(members));
  };

  /** Returns the member that matches the provided ID. Returns a dummy member if there is no match. */
  this.safeFromId = (id) => {
    if (typeof id !== "number") throw new Error(invalidIdMessage);

    try {
      return this.fromId(id);
    } catch (error) {
      return new Member(id, "Unknown");
    }
  };

  /** Returns the member that matches the provided ID. Throws if there is no match. */
  this.fromId = (id) => {
    if (typeof id !== "number") throw new Error(invalidIdMessage);

    const member = _.find(this.all, (m) => m.id === id);
    if (!member) throw new Error(`No known '${this.name}' for ID '${id}'`);
    return member;
  };

  /** Returns the member that matches the provided name. Throws if there is no match. */
  this.fromName = (name) => {
    const member = _.find(this.all, (m) => m.name === name);
    if (!member) throw new Error(`Unknown '${this.name}' member named '${name}'`);
    return member;
  };

  /** Returns the set of members represented by the provided bitmask */
  this.membersFromBitmask = (bitmask) => {
    const ids = this.idsFromBitmask(bitmask);
    return _.map(ids, this.safeFromId);
  };

  /** Returns the ids contained in the provided bitmask */
  this.idsFromBitmask = (bitmask) => {
    const ids = [];
    for (let i = 0; i < 64; i++) {
      if (!Long.fromInt(1).shiftLeft(i).and(bitmask).equals(0)) ids.push(i);
    }
    return ids;
  };

  this.ensureMemberIsValid = (member) => {
    // Override this in enums to validate members (throw if invalid)
    throw new Error(`Member validation is not implemented in enum '${this.name}'`);
  };
};

export default Enum;
