import _ from "lodash";
import { memo } from "react";
import PropTypes from "prop-types";

import Page from "../../../components/page";
import Env from "../../../../helpers/env";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";
import Dropdown from "../../../components/dropdown";

const ALL_ITEMS = _.range(0, 100).map((index) => `Custom Item ${index + 1}`);
const FEW_ITEMS = ALL_ITEMS.slice(0, 5);

const PageComponent = memo(() => {
  return (
    <Page className="demo dropdown ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Dropdown</Text>
          <p className="standard description">
            The dropdown component allows selecting one item from a list of items. It is fully customizable and
            implemented with standard Ripple components like <code>Button</code> and <code>Scroller</code>.
          </p>
          <div className="case">
            <h2>Default</h2>
            <Dropdown items={FEW_ITEMS} />
          </div>
          <div className="case">
            <h2>Basic Styles Without Placeholder</h2>
            <Dropdown className="dropdown-2" items={FEW_ITEMS} initialItem={FEW_ITEMS[0]}>
              {(item) => <div className="custom-item">{item}</div>}
            </Dropdown>
          </div>
          <div className="case">
            <h2>Fancier Styles With Placeholder and Scrolling</h2>
            <Dropdown
              className="dropdown-3"
              items={ALL_ITEMS}
              placeholder={<div className="custom-item placeholder">Pick an item...</div>}
            >
              {(item) => <div className="custom-item">{item}</div>}
            </Dropdown>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

PageComponent.propTypes = {
  data: PropTypes.object,
};

export default PageComponent;
