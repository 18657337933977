import MasterActions from "../../actions/master/master-actions";
import fingerprintReducer from "../master/fingerprint-reducer";

const RootMasterReducerFactory = {
  create: function (reducer) {
    // This is a "manual" `combineReducers` that allows us to receive actions
    // at this level rather than only in sub-reducers.

    // Either delegate the generation of the shared state to the
    // `reducer` or restore the exact provided state.
    let sharedState;
    return (state = {}, action) => {
      switch (action.type) {
        case MasterActions.RESTORE_SHARED_STATE:
          sharedState = action.state;
          break;
        default:
          sharedState = reducer(state.shared, action);
      }

      return {
        shared: sharedState,
        fingerprint: fingerprintReducer(state.fingerprint, action),
      };
    };
  },
};

export default RootMasterReducerFactory;
