import { memo, useCallback } from "react";
import { useParams } from "react-router";
import { Navigator, resource, Strings, useConstant, useData } from "ripple";
import { StandardButtonText, StandardRightButtonIcon } from "../../common";
import Menu from "../../components/menu";
import { BottomRightButton } from "../panorama-page/styled";
import {
  BannerImage,
  Page,
  Section,
  SectionArrow,
  SectionIcon,
  Sections,
  SectionTitle,
  Splitter,
  Title,
  TitleArea,
} from "./styled";

const SectionsPage = memo(() => {
  const { id } = useParams();
  const client = useData((data) => data.root);
  const node = useData((data) => data.requiredNode(id));

  const renderSection = (subchapter) => (
    <Section navigate={{ node: subchapter, type: "subchapter" }}>
      <BannerImage src={subchapter.wantedMedia("Banner", "Banner")} />
      <SectionIcon src={subchapter.wantedMedia("Icon", "PreserveSizeWithAlpha")} />
      <SectionTitle>{subchapter.wantedText("Title")}</SectionTitle>
      <SectionArrow src={resource("images/Symbole_Arrow_01.svg")} />
    </Section>
  );

  const isLastChapter = useConstant(() => !node.nextSibling());
  const onNextClick = useCallback(() => {
    if (isLastChapter) {
      location.href = client.wantedText("BackToSiteLinkUrl").value;
    } else {
      Navigator.navigate({ node: node.nextSibling(), type: "transition" });
    }
  }, [client, isLastChapter, node]);

  return (
    <Page>
      <Sections>{node.children.map(renderSection)}</Sections>
      <TitleArea>
        <Title>{node.wantedText("Title")}</Title>
        <Splitter />
      </TitleArea>
      <Menu />
      <BottomRightButton onClick={onNextClick}>
        <StandardButtonText>{Strings.localized(isLastChapter ? "FinishButton" : "NextButton")}</StandardButtonText>
        <StandardRightButtonIcon src={resource("images/Symbole_Arrow_01.svg")} />
      </BottomRightButton>
    </Page>
  );
});

export default SectionsPage;
