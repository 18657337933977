import _ from "lodash";
import AnalyticsHelper from "../../analytics-helper";

function calculate(preprocessed) {
  if (preprocessed.sessions.length === 0) return 0;

  const bounces = _.map(preprocessed.sessions, (session) => {
    const significativeNavigations = _.filter(session, (e) => e.name === "navigation" && !e.data.root);
    return significativeNavigations.length > 1 ? 0.0 : 1.0;
  });

  return _.mean(bounces);
}

export default function (preprocessed) {
  this.name = "BounceRate";
  this.format = AnalyticsHelper.formatAsPercentage;
  this.value = calculate(preprocessed);
}
