import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Env from "./env";
import Log from "./log";

Env._subscribeToReceiveFromContainer("serialport-connect-error", ({ portName, errorMessage }) =>
  Log.warn(`Failed to connect to serial port '${portName}': ${errorMessage}`)
);

export default class SerialPort {
  /** Connects to the serial port and returns a SerialPort instance to interact with the port.
   * Required for all other SerialPort calls. */
  static get(portName) {
    if (SerialPort._isNotContainedInREC()) return null;
    return new SerialPort(portName);
  }

  static _isNotContainedInREC() {
    if (!Env.isREC) return true;
    return false;
  }

  constructor(portName) {
    SerialPort.portName = portName;
    SerialPort.callbacks = {};

    // No-op if already connected; we'll still receive data events because
    // a connection already exists in the REC serial port bridge.
    Env._sendToContainer("serialport-connect", portName);
  }

  _dataReceived = (data) => {
    _.each(SerialPort.callbacks, (callback) => callback(data));
  };

  readSubscribe = (callback) => {
    const subscriptionId = uuidv4();

    // If this is the first "read" subscriber, setup the real subscription
    if (_.keys(SerialPort.callbacks).length === 0) {
      SerialPort.handler = Env._subscribeToReceiveFromContainer(
        `serialport-data-${SerialPort.portName}`,
        SerialPort._dataReceived
      );
    }

    SerialPort.callbacks[subscriptionId] = callback;

    return subscriptionId;
  };

  readUnsubscribe = (subscriptionId) => {
    delete SerialPort.callbacks[subscriptionId];

    // If this is the last subscriber, remove the real subscription
    if (_.keys(SerialPort.callbacks).length === 0) {
      // Because events are
      Env._unsubscribeToReceiveFromContainer(`serialport-data-${SerialPort.portName}`, SerialPort.handler);
    }
  };
}
