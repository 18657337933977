import { memo, useState } from "react";
import Classes from "../../../../helpers/classes";
import Env from "../../../../helpers/env";
import resource from "../../../../helpers/resource";
import Button from "../../../components/button";
import Page from "../../../components/page";
import Panorama from "../../../components/panorama";
import SafeArea from "../../../components/safe-area";
import Scroller from "../../../components/scroller";
import Text from "../../../components/text";
import DemoBackButton from "../components/demo-back-button";

const PageComponent = memo(() => {
  const [currentImageNumber, setCurrentImageNumber] = useState(0);

  const createOnImageButtonClick = (index) => () => {
    setCurrentImageNumber(index);
  };

  const renderImageButton = (index) => {
    return (
      <Button
        className={Classes.build("standard toggle", { active: currentImageNumber === index })}
        onClick={createOnImageButtonClick(index)}
      >
        Image {index + 1}
      </Button>
    );
  };

  return (
    <Page className="demo panorama ripple-dashboard" pauseTimeout="reset">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Panorama</Text>
          <p className="standard description">A touch-enabled 360˚ viewer with support for images and videos.</p>
          <div className="cases">
            <div className="case">
              <Panorama src={resource(`images/demo/panorama/equirectangular360-${currentImageNumber}.jpg`)} />
              <div className="toggles">
                <div className="toggles">
                  {renderImageButton(0)}
                  {renderImageButton(1)}
                  {renderImageButton(2)}
                </div>
              </div>
            </div>
            <div className="case">
              <Panorama src={resource(`video/demo/equirectangular360.mp4`)} muted />
            </div>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
