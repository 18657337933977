import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, createMemoryHistory } from "history";
import qhistory from "qhistory";
import { parse as parseQueryString, stringify as stringifyQueryObject } from "query-string";
import { applyMiddleware, createStore } from "redux";
import * as createReduxListen from "redux-listen";
import { createLogger } from "redux-logger";
import { createEpicMiddleware } from "redux-observable";
import ReduxThunk from "redux-thunk";
import Config from "../helpers/config";
import Env from "../helpers/env";
import liteLoggingMiddleware from "./middleware/lite-logging-middleware";
import navigationLoggingMiddleware from "./middleware/navigation-logging-middleware";
import RootLocalReducerFactory from "./reducers/roots/root-local-reducer-factory";

window.listenStore = createReduxListen();

const initialState = {};

// We need a different history based on the environment (browser, Electron, etc.)
const environmentSpecificHistory = Env.isContained
  ? (() => {
      // We use memory history when contained because it's more complete than hash history
      // and closer to browser history (especially when it comes to using react-router).
      // With memory history (which is recommended in non-browser contexts), the current location
      // is not persisted after reload because there is no notion of URL as in a browser. Thus, to
      // keep the "reload the current page" behavior which is crucial for development purposes,
      // we must persist the last history entry ourselves. Using `sessionStorage` allows us to reopen
      // the last history entry only on reloads, as `sessionStorage` expires when the app is closed.

      const initialEntry = sessionStorage.getItem("last-history-entry");
      const memoryHistory = createMemoryHistory(initialEntry ? { initialEntries: [initialEntry] } : undefined);

      memoryHistory.listen((location, action) => {
        if (!location) return;
        sessionStorage.setItem("last-history-entry", location.pathname + location.search);
      });

      return memoryHistory;
    })()
  : createBrowserHistory();

// The qhistory package restores react-router 3.X-like query object (instead of a "search" string)
const history = qhistory(
  environmentSpecificHistory,
  (query) => stringifyQueryObject(query, { encode: false }),
  (string) => parseQueryString(string, { decode: false })
);

const internalCreateStore = function (reducer, epic) {
  // Apply middleware to the Redux dispatch mechanism
  // for "advanced" patterns such as asynchronous actions

  const rootLocalReducer = RootLocalReducerFactory.create(reducer, history);

  const epicMiddleware = createEpicMiddleware();

  const middleware = [];
  middleware.push(epicMiddleware); // Watches for incoming actions and pipes them into redux-observable
  middleware.push(routerMiddleware(history)); // Syncs the navigation history with the redux store
  middleware.push(ReduxThunk); // For asynchronous "thunk" actions
  middleware.push(navigationLoggingMiddleware); // Logs navigations without us having to fiddle with routing actions like before!
  middleware.push(window.listenStore.middleware); // Allows us to observe all dispatched actions (used in the `useAction` hook)

  // The logger must be last to log all actions including those generated by
  // other middleware such as redux-observable.

  // A redux-logger customized for performance
  if (Config.dev.localReduxLogger === "full") {
    middleware.push(
      createLogger({
        collapsed: true,
        stateTransformer: (state) => {
          // We remove "data" (often a full node tree downloaded from a Media Server instance)
          // before logging else the logger has a huge impact on app performance!
          const copy = Object.assign({}, state);
          copy["data"] = "[redacted for performance reasons]";
          return copy;
        },
      })
    );
  }

  // Our own ultra-light logging middleware that logs actions and their descriptions
  if (Config.dev.localReduxLogger === "lite") middleware.push(liteLoggingMiddleware("Action [local]"));

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);
  const localStore = createStoreWithMiddleware(rootLocalReducer, initialState);

  epicMiddleware.run(epic); // AFTER store creation

  return { localStore, history };
};

export default { createStore: internalCreateStore };
