import _ from "lodash";
import PersistentQueue from "../logic/persistent-queue";
import Analytics from "./analytics";
import Config from "./config";
import Env from "./env";

export default class Mail {
  static mailQueue = new PersistentQueue("email-send");
  static subscribeQueue = new PersistentQueue("email-subscribe");

  static send(message, options = {}) {
    Analytics.track("mail-send");
    Mail.mailQueue.push({ message, options });
  }

  static subscribe(address, options = {}) {
    Analytics.track("mail-subscribe");
    Mail.subscribeQueue.push({ address, options });
  }

  // Check the queue regularly for new emails to send.
  // Only dequeues another email while there is no ongoing send.
  static _startProcessing() {
    Mail.mailQueue.startProcessing(
      (entry, resolve, reject) => {
        // The `options` argument overrides settings provided in `Config.mail`
        const actualOptions = _.merge({}, Config.mail, entry.options || {});
        if (!actualOptions.id || !actualOptions.key) reject(new Error("Mail: Missing app ID and/or key"));

        return fetch(`${actualOptions.server}/mail/send`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id: actualOptions.id,
            key: actualOptions.key,
            subscribe: entry.options.subscribe,
            message: entry.message,
          }),
        })
          .then((result) => {
            if (result.status === 532 /* Mail backend failed to send */) {
              reject(
                new Error(`Proxy responded but backend failed to send email to ${entry.message.to}, will retry`),
                /* Increment the retry count? */ true
              );
            } else if (result.status !== 200) {
              reject(new Error(`Mail: Failed to send (HTTP ${result.status})`));
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      },
      // Cleanup entry for logging
      (entry) => (entry.message.attachments = ["Attachments removed for logging!"])
    );

    Mail.subscribeQueue.startProcessing((entry, resolve, reject) => {
      const mode = Config.mail.subscribe.mode;
      const actualOptions = _.merge({}, Config.mail, entry.options || {});

      const subscribeOnline = () =>
        fetch(`${actualOptions.server}/mail/subscribe`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            address: entry.address,
            id: actualOptions.id,
            key: actualOptions.key,
            listId: actualOptions.listId,
          }),
        })
          .then((result) => {
            if (result.status !== 200) reject(new Error(`Mail: Failed to subscribe (HTTP ${result.status})`));
            resolve();
          })
          .catch((error) => {
            reject(error);
          });

      const subscribeLocal = () => {
        const data = actualOptions.data ? _.values(actualOptions.data) : [];
        if (!Env.isREC || __DEV__) return Promise.resolve();
        return Env.appendFile(Config.mail.subscribe.file, `${entry.address}${data ? `,${data.join(",")}` : ""}\n`);
      };

      if (mode === "online-and-local") {
        return Promise.all([subscribeOnline(), subscribeLocal()]).then(resolve);
      } else if (mode === "online") {
        return subscribeOnline().then(resolve);
      } else if (mode === "local") {
        return subscribeLocal().then(resolve);
      }
    });
  }
}
