import { Canvas } from "@react-three/fiber";
import PropTypes from "prop-types";
import { memo, useContext } from "react";
import { RippleContext } from "ripple";

/**
 * A `@react-three/fiber` canvas pre-configured with good defaults for FOV,
 * color handling and integrating a few Ripple-specific things.
 */
const ThreeFiberCanvas = memo(({ children, camera = {}, paused, ...rest }) => {
  const ripple = useContext(RippleContext);

  return (
    <Canvas
      // Auto-configures the renderer, textures and colors with sRGBEncoding.
      // See: https://github.com/react-spring/react-three-fiber/issues/344#issue-602249728
      colorManagement
      camera={{ fov: 60, ...camera }}
      invalidateFrameloop={paused}
      {...rest}
    >
      {
        // Manually forwarding the Ripple context is necessary because we're crossing reconciler boundaries
        // https://github.com/react-spring/react-three-fiber/issues/262#issuecomment-568274573
      }
      <RippleContext.Provider value={ripple}>{children}</RippleContext.Provider>
    </Canvas>
  );
});

ThreeFiberCanvas.propTypes = {
  children: PropTypes.node,
  camera: PropTypes.object,
  paused: PropTypes.bool,
};

export default ThreeFiberCanvas;
