import { useEffect } from "react";
import { useLatestRef } from "./internal/use-latest-ref";

/**
 * Runs a callback at the specified interval. The delay can be changed dynamicall.
 * If you want to pause the interval, just set delay to `null`.
 * */
export const useInterval = (callback, delay) => {
  const mostRecentCallbackRef = useLatestRef(callback);

  useEffect(() => {
    if (delay !== null) {
      const mostRecentCallback = (...args) => mostRecentCallbackRef.current(...args);
      const id = setInterval(mostRecentCallback, delay);
      return () => clearInterval(id);
    }
  }, [delay, mostRecentCallbackRef]);
};
