import PropTypes from "prop-types";
import { PureComponent } from "react";
import { connect } from "react-redux";
import Classes from "../../helpers/classes";
import Config from "../../helpers/config";
import InputOutput from "./input-output";

class DevBuildIndicator extends PureComponent {
  static propTypes = {
    active: PropTypes.bool,
  };

  render() {
    return (
      <div className={Classes.build("debug-overlay", "debug-visible", "unclickable")}>
        <div className="info-box">
          <ul>
            <li>
              <b>Build</b>: <span className="value">{Config.buildIdentifier || "(not built by CI)"}</span>
            </li>
          </ul>
        </div>
        <InputOutput />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(DevBuildIndicator);
