import { memo, forwardRef, useCallback, useRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import Classes from "../../../helpers/classes";
import { useMeasure } from "../../hooks/use-measure";
import Rect from "../../../types/rect";

// This is just a marker class to allow type checks with `instanceof`
class CanvasAPI {
  constructor(canvasRef) {
    this.canvasRef = canvasRef;
  }

  get clientWidth() {
    return this.canvasRef.current.clientWidth;
  }

  get clientHeight() {
    return this.canvasRef.current.clientHeight;
  }
}

/**
 * A simple wrapper for the HTML <canvas/> element that auto-sets the canvas' width and height
 * based on its CSS-derived size. This is important because the canvas element must have its width and
 * height attributes set to have its contents scaled properly. The result is a canvas that can be resized at will.
 */
const Canvas = memo(
  forwardRef(({ className, ...rest }, ref) => {
    const canvasRef = useRef(null);
    const [measureRef, { width: viewWidth, height: viewHeight }] = useMeasure();

    // Use this to obtain a "real" image. Slower and async. Appropriate for uploading.
    const takeSnapshot = useCallback(() => {
      return new Promise((resolve) => {
        canvasRef.current.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = function () {
            const base64data = reader.result;
            resolve({
              blob,
              base64Data: base64data.substr(base64data.indexOf(",") + 1),
            }); // We strip the `data:` prefix to only keep the base64 data
          };
          reader.readAsDataURL(blob);
        }, "image/jpeg");
      });
    }, [canvasRef]);

    // Use this for maximum performance (instant draw)
    const drawSnapshotToCanvas = useCallback(
      (targetCanvas) => {
        const targetContext = targetCanvas.getContext("2d");
        const sourceRect = new Rect(0, 0, viewWidth, viewHeight);
        const targetRect = new Rect(0, 0, targetCanvas.clientWidth, targetCanvas.clientHeight);
        const fill = sourceRect.fillIn(targetRect);
        targetContext.drawImage(canvasRef.current, fill.x, fill.y, fill.width, fill.height);
      },
      [canvasRef, viewHeight, viewWidth]
    );

    const getCanvasElement = useCallback(() => canvasRef.current, [canvasRef]);

    // External API
    useImperativeHandle(
      ref,
      () => {
        const api = new CanvasAPI(canvasRef);
        api.getContext = (...args) => canvasRef.current.getContext(...args);
        api.takeSnapshot = takeSnapshot;
        api.drawSnapshotToCanvas = drawSnapshotToCanvas;
        api.getCanvasElement = getCanvasElement;
        return api;
      },
      [drawSnapshotToCanvas, getCanvasElement, takeSnapshot]
    );

    const actualClassName = Classes.build("ripple-canvas", className);
    return (
      <div ref={measureRef} className={actualClassName}>
        <canvas {...rest} ref={canvasRef} width={viewWidth} height={viewHeight} />
      </div>
    );
  })
);

Canvas.propTypes = {
  className: PropTypes.string,
};

export default Canvas;
