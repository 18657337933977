import _ from "lodash";
import AnalyticsServerApi from "./analytics-server-api";
import Config from "../../helpers/config";
import Log from "../../helpers/log";
import LocalSource from "./event-sources/local-source";
import Analytics from "../../helpers/analytics";

export default class AnalyticsUploader {
  constructor(appId, url, username, password) {
    this.appId = appId;
    this.instanceId = Analytics._instanceId;
    this.api = new AnalyticsServerApi(url, username, password);
  }

  async sync() {
    // Either use the date we inferred (the last event that we successfully sent to the server based on request response
    // code) or the latest event date the server tells us he has. In practice, this means the app only asks the server
    // on first sync. It also means that even if our inferred date was wrong for some reason a full sync will always be
    // performed on startup.
    const latestRemoteEventDate = this._inferredLatestRemoteEventDate || (await this._getLatestRemoteEventDate());

    const eventsToUpload = await this._getLocalEventsFromDate(latestRemoteEventDate);

    // If the first event's date is the exact same as the latest remote event date, it's the same event
    // thus we remove it from the list of events to send. This allows the list of events to send to go down
    // to zero length, which helps us avoid saving events if there are none that the server doesn't already have.
    if (eventsToUpload.length >= 1 && eventsToUpload[0].time.getTime() === latestRemoteEventDate.getTime())
      eventsToUpload.shift();

    // Only make the save request if there are some events to send
    if (eventsToUpload.length > 0) {
      Log.info(`Analytics: Uploading ${eventsToUpload.length} events to ${Config.analytics.server.url}`);
      Log.info(
        `Analytics: Latest event on server for ${this.appId}:${this.instanceId} was on ${latestRemoteEventDate}`
      );
      await this._saveEvents(eventsToUpload)
        .then(() => {
          // If the save events request succeeds, we can assume that the server has received
          // events up to the last one we just sent, which allows us to skip asking the server
          // for the date of the last event received from us. This in turn allows zero-cost
          // sync when there is nothing to send, even with a short interval.
          this._inferredLatestRemoteEventDate = new Date(_.last(eventsToUpload).time);
        })
        .catch((error) => {
          Log.error(`Failed to upload analytics events: ${error.message}`);
        });
    }
  }

  async _getLatestRemoteEventDate() {
    return await this.api.getLatestEventDate(this.appId, this.instanceId);
  }

  async _getLocalEventsFromDate(date) {
    return await new LocalSource().getRange(this.appId, date, new Date());
  }

  async _saveEvents(events) {
    return await this.api.save(events);
  }
}
