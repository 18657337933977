import { useEffect, useState } from "react";
import Beacons from "../../helpers/beacons";

/**
 * WARNING: Causes a render approximately each second because the beacon RSSI
 * (among other things) change every time beacons are updated! Watch out!
 * Continuously finds and returns all iBeacons matching the provided UUID, major and minor version.
 */
export const useBeacons = (uuid, major = null, minor = null) => {
  const [beacons, setBeacons] = useState([]);

  useEffect(() => {
    const subscription = Beacons.subscribe(uuid, major, minor, (beacons) => setBeacons(beacons));
    return () => Beacons.unsubscribe(subscription);
  }, [uuid, major, minor]);

  return beacons;
};
