import { useState } from "react";
import { useEffect } from "react";

import Load from "../../helpers/load";

/**
 * Preloads an image and returns its data as a data URL.
 * Useful to avoid flashes when reusing an image multiple times
 * in a single page (for example, in Droppable / DragPreview).
 */
export const useImageData = (url, type) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!url) return;
    Load.imageData(url, type).then((imageData) => setData(imageData));
  }, [url, type]);

  return data;
};
