import Enum from "../core/enum/enum";
import Member from "../core/enum/member";

let Visibility = new Enum("Visibility");

// Must be defined before adding initial members!
Visibility.ensureMemberIsValid = function (member) {
  // Throw on missing properties
};

Visibility.addMembers([
  new Member(0, "Public"),
  new Member(1, "Discovered"),
  new Member(2, "Discoverable"),
  new Member(3, "Private"),
]);

export default Visibility;
