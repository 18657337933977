import _ from "lodash";
import { memo, useCallback, useState } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import Text from "../../../components/text";
import Button from "../../../components/button";
import { Geolocation, Toast } from "../../../../ripple";
import { useGeolocation } from "../../../hooks/use-geolocation";
import Classes from "../../../../helpers/classes";
import { useGeofence } from "../../../hooks/use-geofence";

const LAST_LATITUDE_KEY = "geolocation-demo-last-latitude";
const LAST_LONGITUDE_KEY = "geolocation-demo-last-longitude";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const onCheckIfAllowedClick = useCallback(() => {
    Geolocation.checkIfAllowed((allowed) => {
      if (allowed) {
        Toast.info(`👍 Geolocation Allowed!`);
      } else {
        Toast.error(`❌ Geolocation Not Allowed!`);
      }
    });
  }, []);

  const [updated, setUpdated] = useState(false);

  const animateUpdate = useCallback(() => {
    setUpdated(true);
    setTimeout(() => setUpdated(false), 300);
  }, []);

  const [geolocationString, setGeolocationString] = useState("Geolocation not obtained yet.");
  useGeolocation(
    (geolocation) => {
      animateUpdate();
      setGeolocationString(
        `<b>LAT:</b> ${geolocation.coords.latitude}<br/><b>LNG:</b> ${geolocation.coords.longitude}<br/><b>ALT:</b> ${geolocation.coords.altitude}`
      );
    },
    (error) => {
      animateUpdate();
      setGeolocationString(`Error obtaining geolocation: ${JSON.stringify(error)}`);
    }
  );

  const [latitude, setLatitude] = useState(localStorage.getItem(LAST_LATITUDE_KEY) ?? 45.544244);
  const [longitude, setLongitude] = useState(localStorage.getItem(LAST_LONGITUDE_KEY) ?? -73.655498);
  const [isInGeofence, setIsInGeofence] = useState(false);
  const geofenceMaxDistance = 100;
  useGeofence(
    { latitude, longitude },
    geofenceMaxDistance,
    () => {
      setIsInGeofence(true);
    },
    () => {
      setIsInGeofence(false);
    }
  );

  const onLatitudeChange = useCallback((event) => {
    const value = event.target.value;
    setLatitude(value);
    localStorage.setItem(LAST_LATITUDE_KEY, value);
  }, []);

  const onLongitudeChange = useCallback((event) => {
    const value = event.target.value;
    setLongitude(value);
    localStorage.setItem(LAST_LONGITUDE_KEY, value);
  }, []);

  return (
    <Page className="demo geolocation ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Geolocation</Text>
          <p className="standard description">
            On mobile, you can get live location updates when the user moves and use geofencing to activate or
            deactivate features and triggers when entering or exiting a geofence.
          </p>
          <Button className="standard" onClick={onCheckIfAllowedClick}>
            Check If Allowed
          </Button>
          <h2 className="standard">Live Geolocation Updates</h2>
          <p
            className={Classes.build("geolocation", { updated })}
            dangerouslySetInnerHTML={{ __html: geolocationString }}
          />
          <h2 className="standard">Geofencing</h2>
          <div className="geofencing">
            <input type="text" value={latitude} onChange={onLatitudeChange} />
            <input type="text" value={longitude} onChange={onLongitudeChange} />
          </div>
          <p className="geofencing">
            {isInGeofence
              ? `You are within ${geofenceMaxDistance} meters of the geofence center!`
              : `You are not in the geofence.`}
          </p>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
