import _ from "lodash";

export default class CSV {
  static stringify(rows) {
    return _.reduce(
      rows,
      (acc, row) => {
        acc += row.join(", ") + "\n";
        return acc;
      },
      ""
    );
  }
}
