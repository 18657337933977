import { memo, useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import Sequencer from "sequencer.js";

import Classes from "../../../helpers/classes";
import Audio from "../../../helpers/audio";
import { usePrevious } from "../../hooks/use-previous";
import { StaggerContext } from "../stagger";
import { useStagger } from "../../hooks/specialized/use-stagger";
import { useKeyboardShortcut } from "../../hooks/use-keyboard-shortcut";

const Revealer = memo(
  ({
    className,
    children,
    staggerOptions = {},
    reveal,
    transition,
    disableExitStagger,
    debugKeyEquivalent,
    enterSound,
    exitSound,
    onEnter,
    onExit,
    ...rest
  }) => {
    staggerOptions = { delay: 150, ...staggerOptions };

    const sequencerRef = useRef(new Sequencer());
    const currentStateRef = useRef(reveal);
    const stagger = useStagger({ ...staggerOptions, automatic: false });
    const actualTransition = transition || "fade-in-over";

    const previousReveal = usePrevious(reveal);
    const [transitionClassName, setTransitionClassName] = useState(reveal ? null : `${actualTransition}-enter`);

    const onToggleStaggerShortcut = useCallback(() => stagger.toggle(), [stagger]);
    useKeyboardShortcut("Toggle Revealer Stagger", debugKeyEquivalent, onToggleStaggerShortcut);

    const enter = useCallback(() => {
      const sequencer = sequencerRef.current;
      sequencer.clear({ cancelCurrentTask: false });
      sequencer.doSequence((s) => {
        if (currentStateRef.current === "enter") return;
        s.do(() => (currentStateRef.current = "enter"));
        s.doWaitForRelease((release) => {
          if (enterSound) Audio.discrete("revealers").play(enterSound);
          setTransitionClassName(`${actualTransition}-enter ${actualTransition}-enter-active`);
          stagger.enter({ onComplete: release });
        });
      });
    }, [actualTransition, enterSound, stagger]);

    const exit = useCallback(() => {
      const sequencer = sequencerRef.current;
      sequencer.clear({ cancelCurrentTask: false });

      const runExitTransition = () => {
        if (exitSound) Audio.discrete("revealers").play(exitSound);
        setTransitionClassName(`${actualTransition}-exit ${actualTransition}-exit-active`);
      };

      sequencer.doSequence((s) => {
        if (currentStateRef.current === "exit") return;
        s.do(() => (currentStateRef.current = "exit"));

        if (disableExitStagger) {
          runExitTransition();
          return;
        }

        s.doWaitForRelease((release) => {
          stagger.exit({
            sort: "reverse",
            onComplete: () => {
              runExitTransition();
              release();
            },
          });
        });
      });
    }, [actualTransition, disableExitStagger, stagger, exitSound]);

    useEffect(() => {
      if (!previousReveal && reveal) {
        enter();
        if (onEnter) onEnter();
      } else if (previousReveal && !reveal) {
        exit();
        if (onExit) onExit();
      }
    }, [enter, exit, onEnter, onExit, previousReveal, reveal]);

    return (
      <div className={Classes.build("ripple-revealer", className, transitionClassName, { reveal })}>
        <StaggerContext.Provider value={stagger}>{children}</StaggerContext.Provider>
      </div>
    );
  }
);

Revealer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  staggerOptions: PropTypes.object,
  reveal: PropTypes.bool,
  transition: PropTypes.string,
  disableExitStagger: PropTypes.bool,
  debugKeyEquivalent: PropTypes.string,
  enterSound: PropTypes.string,
  exitSound: PropTypes.string,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
};

Revealer.defaultProps = {
  reveal: true,
  disableExitStagger: false,
};

export default Revealer;
