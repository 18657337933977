import { memo, useCallback } from "react";
import Env from "../../../../helpers/env";
import resource from "../../../../helpers/resource";
import { Image, Wikitude } from "../../../../ripple";
import Button from "../../../components/button";
import Page from "../../../components/page";
import SafeArea from "../../../components/safe-area";
import Scroller from "../../../components/scroller";
import Text from "../../../components/text";
import { useData } from "../../../hooks/use-data";
import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import DemoBackButton from "../components/demo-back-button";

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");
  const client = useData((data) => data.root);
  const map = client.children[0];
  const media = client.requiredMedia("WikitudeModel", "Binary");

  const onOpenARViewClick = useCallback(() => {
    const image = map.requiredMedia("Background", "FullscreenImage");
    const model = client.requiredMedia("WikitudeModel", "Binary");

    Wikitude.openWorld(resource("other/wikitude-demo1/index.html")).then(() => {
      // IMPORTANT: Provide native media URLs (`nativeURL`) for things that will be displayed in the
      // 3D view else Wikitude won't understand the paths! Provide "normal" urls for display in the
      // Wikitude web view (over the 3D view).

      // IMPORTANT: Provide a data URL (base64 image data) for images that will be displayed in the Wikitude web view overlay,
      // because it doesn't have a way to access files stored on the native filesystem like we do in the Cordova web view.

      Wikitude.sendMessage({
        action: "add-image",
        url: image.nativeURL,
        options: {
          translate: { y: -2 },
        },
      });

      Wikitude.sendMessage({
        action: "add-model",
        url: model.nativeURL,
        options: {
          scale: { x: 1, y: 1, z: 1 },
          translate: { y: 2 },
        },
      });
    });
  }, [client, map]);

  return (
    <Page className="demo wikitude ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <Text className="title">Wikitude</Text>
          <p className="standard description">Add AR experiences to an app using the Wikitude helper.</p>
          <Button className="standard" onClick={onOpenARViewClick}>
            Open AR View
          </Button>
          <Image style={{ width: 400, height: 300 }} src={media} />
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
