import Color from "../../../../helpers/internal/color";

// Logic inspired by:
// https://timtaubert.de/blog/2012/10/building-a-live-green-screen-with-getusermedia-and-mediastreams/
// https://github.com/kasperific/HTML5ChromaKey/blob/master/chroma-video.js
export default function (data, offset) {
  const [h, s, l] = Color.rgbToHsl(data[offset], data[offset + 1], data[offset + 2]);

  // Check if we have a somewhat green pixel
  if (h >= 90 && h <= 160 && s >= 25 && s <= 90 && l >= 20 && l <= 75) {
    data[offset + 3] = 0;
  }
}
