import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import useClickOutside from "use-click-outside";
import Classes from "../../../helpers/classes";
import Button from "../button";
import Scroller from "../scroller";

const Dropdown = memo(({ className, style, children, items, initialItem, placeholder, onChange, ...rest }) => {
  const rootRef = useRef(null);

  const [showPicker, setShowPicker] = useState(false);
  const [selection, setSelection] = useState(initialItem);
  const [maxPickerHeight, setMaxPickerHeight] = useState(999999);

  const createOnPickerItemClick = (item) => () => {
    setSelection(item);
    setShowPicker(false);
    onChange?.(item);
  };

  const onDropdownClick = useCallback(() => {
    const viewRect = rootRef.current.getBoundingClientRect();
    const availableVerticalSpace = window.innerHeight - (viewRect.y + viewRect.height);
    setMaxPickerHeight(availableVerticalSpace - 20);
    setShowPicker(!showPicker);
  }, [showPicker]);

  const onOutsideClick = useCallback(() => {
    setShowPicker(false);
  }, []);

  useClickOutside(rootRef, onOutsideClick);

  const actualChildren = children ?? ((anything) => anything);

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div ref={rootRef} style={style} className={Classes.build("ripple-dropdown", className)}>
        <Button className="dropdown-box" onClick={onDropdownClick}>
          <div className="dropdown-item">{(selection && actualChildren(selection)) ?? placeholder}</div>
          <div className="dropdown-arrow"></div>
        </Button>
        <div
          className={Classes.build("dropdown-picker", showPicker ? "visible" : "hidden")}
          style={{ maxHeight: maxPickerHeight }}
        >
          <Scroller className="dropdown-scroller" scrollbarInset={6}>
            {items &&
              items.map((item) => (
                <Button key={item.id ?? item} className="dropdown-item" onClick={createOnPickerItemClick(item)}>
                  {actualChildren(item)}
                </Button>
              ))}
          </Scroller>
        </div>
      </div>
    </OutsideClickHandler>
  );
});

Dropdown.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.func.isRequired,
  items: PropTypes.array,
  initialItem: PropTypes.any,
  placeholder: PropTypes.any,
  onChange: PropTypes.func,
};

Dropdown.defaultProps = {
  items: [],
  placeholder: <div className="dropdown-default-placeholder">[Set placeholder or initial item!]</div>,
};

export default Dropdown;
