import { PureComponent } from "react";
import PropTypes from "prop-types";
import { DropTarget } from "react-dnd";

import Classes from "../../../../helpers/classes";

const target = {
  drop(props, monitor) {
    const item = monitor.getItem();
    const droppableId = item.id;
    const group = item.group;
    if (group === props.group && (!props.accepts || droppableId === props.accepts)) {
      if (props.onDrop) props.onDrop(props.id, droppableId);
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class Dropzone extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node, // [optional] something that is always rendered (the "background" of the dropzone)
    isOver: PropTypes.bool, // Added automatically by react-dnd
    id: PropTypes.any, // The id of the dropzone, used to identify it in callbacks
    accepts: PropTypes.any, // When specified, only accept drops from droppables whose id corresponds to this value
    onDrop: PropTypes.func, // Called when the dropzone accepts a drop
    onOver: PropTypes.func, // Called when a droppable enters or exits the dropzone area
    hasDropped: PropTypes.bool, // Provided externally, set to true to indicate that the Dropzone has received an item
    renderEmptyLayer: PropTypes.func, // Something to render ONLY when the dropzone is empty
    renderOverLayer: PropTypes.func, // Something to render ONLY when dragging a droppable over the dropzone
    renderForegroundLayer: PropTypes.func, // Something to render ONLY when the dropzone has received an item (hasDropped)
    group: PropTypes.string, // Specify a group name to group a bunch of Droppables and Dropzones together (prevents drags across groups)
  };

  static defaultProps = {
    renderEmptyLayer: () => null,
    renderOverLayer: () => null,
    renderForegroundLayer: () => null,
    group: "all",
  };

  getStylesForVisibleIf(isVisible) {
    return {
      visibility: isVisible ? "visible" : "hidden",
      pointerEvents: isVisible ? "auto" : "none",
    };
  }

  render() {
    /* eslint-disable react/prop-types */
    const { connectDropTarget, isOver } = this.props;
    /* eslint-enable react/prop-types */
    return connectDropTarget(
      <div
        className={Classes.build("ripple-dropzone", "debug-show-area", { over: isOver }, this.props.className)}
        style={this.props.style}
      >
        <div className="drop-content">
          <div className="drop-layer">{this.props.children}</div>
          <div className="drop-layer" style={this.getStylesForVisibleIf(!this.props.hasDropped)}>
            {this.props.renderEmptyLayer()}
          </div>
          <div className="drop-layer" style={this.getStylesForVisibleIf(this.props.hasDropped)}>
            {this.props.renderForegroundLayer()}
          </div>
          <div className="drop-layer" style={this.getStylesForVisibleIf(isOver)}>
            {this.props.renderOverLayer()}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOver !== prevProps.isOver && this.props.onOver) this.props.onOver(this.props.isOver);
  }
}

export default DropTarget("droppable", target, collect)(Dropzone);
