import _ from "lodash";
import { memo, useState, useEffect } from "react";

import { useTimeoutPause } from "../../../hooks/use-timeout-pause";
import Env from "../../../../helpers/env";
import Page from "../../../components/page";
import Scroller from "../../../components/scroller";
import DemoBackButton from "../components/demo-back-button";
import SafeArea from "../../../components/safe-area";
import { useBeacons } from "../../../hooks/use-beacons";
import { useClosestBeacon } from "../../../hooks/use-closest-beacon";
import Classes from "../../../../helpers/classes";
import Config from "../../../../helpers/config";

const useMaxLength = (array) => {
  const [max, setMax] = useState(0);
  useEffect(() => {
    const length = array.length;
    if (length > max) setMax(length);
  }, [array, max]);
  return max;
};

const prepareForDisplay = (obj) => {
  const copy = _.cloneDeep(obj);

  copy["id"] = `${copy.major}:${copy.minor}`;
  delete copy.minor;
  delete copy.major;

  delete copy.uuid; // Redundant

  return JSON.stringify(copy, null, 2);
};

const PageComponent = memo(() => {
  useTimeoutPause("Demo", "reset");

  const beaconUuid = Config.demo.beaconsUUID;

  const beacons = useBeacons(beaconUuid);
  const beaconsMaxLength = useMaxLength(beacons);

  const [closestBeaconDebug, setClosestBeaconDebug] = useState([]);
  const closestBeacon = useClosestBeacon(beaconUuid, null, null, (debug) => setClosestBeaconDebug(debug));
  const closestBeaconDebugMaxLength = useMaxLength(closestBeaconDebug);

  const [closestBeaconChanged, setClosestBeaconChanged] = useState(false);

  useEffect(() => {
    setClosestBeaconChanged(true);
    setTimeout(() => setClosestBeaconChanged(false), 300);
  }, [closestBeacon]);

  const renderBeacons = (beacons, { highlightChanged = false, colorWithSignal = false, min = null } = {}) => {
    return (
      <>
        {
          // Render the beacons
          _.map(beacons, (beacon, index) => {
            const additionalProps = {};
            if (colorWithSignal)
              additionalProps.style = {
                backgroundColor: `hsl(${Math.round((1 + beacon.rssi / 100) * 255)}, 100%, 70%)`,
              };
            return (
              <tr key={index} className="message">
                <td
                  {...additionalProps}
                  className={Classes.build({
                    empty: beacons.length === 0,
                    changed: closestBeaconChanged && highlightChanged,
                  })}
                >
                  <pre>{prepareForDisplay(beacon, null, 2)}</pre>
                </td>
              </tr>
            );
          })
        }
        {
          // Render empty placeholders to prevent the page from jumping
          _.map(_.range(0, Math.max(min - beacons.length, 0)), (index) => (
            <tr key={index} className="message">
              <td className={Classes.build({ empty: true })} />
            </tr>
          ))
        }
      </>
    );
  };

  const renderDebug = () => {
    return (
      <>
        {
          // Render the debug infos
          _.map(closestBeaconDebug, (beaconDebugInfo, index) => {
            const info = _.cloneDeep(beaconDebugInfo);
            const closest = info.closest;
            delete info.closest; // We don't want to display it in the debug info directly

            return (
              <tr key={index} className="message">
                <td className={Classes.build({ empty: info.length === 0, closest })}>
                  <pre>{prepareForDisplay(info)}</pre>
                </td>
              </tr>
            );
          })
        }
        {
          // Render empty placeholders to prevent the page from jumping
          _.map(_.range(0, Math.max(closestBeaconDebugMaxLength - closestBeaconDebug.length, 0)), (index) => (
            <tr key={`empty-${index}`} className="message">
              <td className={Classes.build({ empty: true })} />
            </tr>
          ))
        }
      </>
    );
  };

  const renderEmpty = () => {
    return (
      <tr className="message">
        <td className="empty">
          <pre>No Beacon in Range</pre>
        </td>
      </tr>
    );
  };

  return (
    <Page className="demo beacons ripple-dashboard">
      <SafeArea>
        <Scroller
          className="page"
          innerClassName="page"
          startFadeRatio={0.1}
          endFadeRatio={0.04}
          scrollbarSideInset={3}
        >
          <p className="standard description notice">
            <b>Currently listening for beacons with UUID</b>
            <br />
            {beaconUuid}
          </p>

          <div className="tables">
            <table className="demo">
              <thead>
                <tr>
                  <th>Closest Beacon</th>
                </tr>
              </thead>
              <tbody>
                {closestBeacon ? renderBeacons([closestBeacon], { highlightChanged: true }) : renderEmpty()}
              </tbody>
            </table>

            <table className="demo">
              <thead>
                <tr>
                  <th>Closest Beacon - Debug Info</th>
                </tr>
              </thead>
              <tbody>{closestBeaconDebug.length > 0 ? renderDebug() : renderEmpty()}</tbody>
            </table>

            <table className="demo">
              <thead>
                <tr>
                  <th>Beacons in Range</th>
                </tr>
              </thead>
              <tbody>
                {beacons.length > 0
                  ? renderBeacons(beacons, { colorWithSignal: true, min: beaconsMaxLength })
                  : renderEmpty()}
              </tbody>
            </table>
          </div>
        </Scroller>
        {Env.isRCC && <DemoBackButton />}
      </SafeArea>
    </Page>
  );
});

export default PageComponent;
