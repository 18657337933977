import PropTypes from "prop-types";
import { forwardRef, memo, useEffect, useState } from "react";
import Load from "../../../helpers/load";
import MediaInfo from "../../../logic/info/media-info";
import { MediaSrcPropType } from "../../../logic/prop-types";
import Image from "../image";
import MapContent from "./map-content";

/**
 * A map content sub-type that loads an image as its background and sizes
 * itself automatically based on the image's dimensions.
 */
const MapImageContent = memo(
  forwardRef(({ src, children, onLoad, ...rest }, ref) => {
    const [size, setSize] = useState(null);
    const url = src instanceof MediaInfo ? src.url : src;

    // Load the image when the SRC changes
    useEffect(() => {
      if (!url) return;
      Load.image(url).then((image) => setSize({ width: image.width, height: image.height }));
    }, [url]);

    // Render with the size of the loaded image
    return (
      size && (
        <MapContent ref={ref} width={size.width} height={size.height} {...rest}>
          <Image
            src={url}
            style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            fadeIn="always"
            onLoad={onLoad}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {children}
          </div>
        </MapContent>
      )
    );
  })
);

MapImageContent.propTypes = {
  src: MediaSrcPropType,
  children: PropTypes.node,
  onLoad: PropTypes.func,
};

export default MapImageContent;
