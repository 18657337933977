const socketIoClient = require("socket.io-client");

let global_socket;
let global_id = null;

class IpcClient {
  static connect(
    url,
    id,
    { onConnect, onRestoreSharedState, onAction, onUpdateRemoteState, registerCustomEvents = () => {} }
  ) {
    if (this.initialized) {
      if (console) console.warn("Already connected to an IPC server!");
      return;
    }
    this.initialized = true;

    if (console) console.info(`IpcClient: Connecting to ${url} ...`);

    global_id = id;
    global_socket = socketIoClient(url, { transports: ["websocket"] });

    this.registerBuiltinEvents(global_socket, url, onConnect);
    this.registerRestoreSharedStateEvent(global_socket, onRestoreSharedState);
    this.registerUpdateRemoteStateEvent(global_socket, onUpdateRemoteState);
    this.registerActionEvent(global_socket, onAction);

    registerCustomEvents(global_socket);
  }

  static registerRestoreSharedStateEvent(socket, callback) {
    socket.on("restore-shared-state", callback);
  }

  static registerUpdateRemoteStateEvent(socket, callback) {
    socket.on("update-remote-state", callback);
  }

  static registerActionEvent(socket, callback) {
    socket.on("action", callback);
  }

  static registerBuiltinEvents(socket, url, onConnect) {
    socket.on("connect", () => {
      if (console) console.info(`IpcClient: Connected to ${url}`);
      if (onConnect) onConnect();
    });
    socket.on("disconnect", () => {
      if (console) console.info(`IpcClient: Disconnected from ${url}`);
    });
  }

  static sendInitialRemoteState(state) {
    global_socket.emit("initial-remote-state", state);
  }

  static updateRemoteState(state) {
    global_socket.emit("update-remote-state", state);
  }

  static undoLastRemoteStateUpdate() {
    global_socket.emit("undo-remote-state");
  }

  static resetToInitialRemoteState() {
    global_socket.emit("reset-remote-state");
  }

  static clearRemoteStateHistory() {
    global_socket.emit("clear-remote-state-history");
  }

  static dispatch(payload) {
    // Socket.io seems to have a pre-connection emit queue (emits are performed
    // after connecting), so no need to do anything special to ensure emits are
    // not sent in the void if performed too early
    global_socket.emit("action", { id: global_id, payload });
  }
}

module.exports = IpcClient;
