const MasterActions = {
  RESTORE_SHARED_STATE: "@@master/RESTORE_SHARED_STATE",
  restoreSharedState: function (state) {
    return {
      type: MasterActions.RESTORE_SHARED_STATE,
      state,
      description: (action) => JSON.stringify(action.state),
    };
  },
};

export default MasterActions;
