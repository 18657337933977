import { memo, useCallback, useRef, useState } from "react";
import { useParams } from "react-router";
import { Config, Navigator, resource, Strings, useData, useInteraction } from "ripple";
import { StandardButtonText, StandardRightButtonIcon } from "../../common";
import Menu from "../../components/menu";
import {
  Decoration,
  FullscreenVideoPlayer,
  Page,
  SkipButton,
  SkipButtonContainer,
  Splitter,
  Subtitle,
  TextContainer,
  Title,
  VideoNotice,
} from "./styled";

let allowedToPlayVideo = false;

const MainPage = memo(() => {
  const { id } = useParams();
  const node = useData((data) => data.optionalNode(id) ?? data.root.children[0]);

  const [showOverlay, setShowOverlay] = useState(true);

  const onEnd = useCallback(() => {
    if (node.semantic === "Chapitre") {
      Navigator.navigate({ node: node, type: "panorama" });
    } else if (node.semantic === "MultiChapitre") {
      Navigator.navigate({ node: node, type: "sections" });
    }
  }, [node]);

  const onVideoProgress = useCallback((progress, duration) => {
    if (progress > Config.durationBeforeHidingTransitionOverlay / 1000) setShowOverlay(false);
  }, []);

  const videoApiRef = useRef(null);
  const [interacted, setInteracted] = useState(false);
  useInteraction(() => {
    if (allowedToPlayVideo || interacted) return;
    allowedToPlayVideo = true;
    setInteracted(true);
    videoApiRef.current.play();
  });

  const showVideoNotice = !allowedToPlayVideo && !interacted;

  return (
    <Page>
      <FullscreenVideoPlayer
        src={node.wantedMedia("IntroVideo", "FullscreenVideo")}
        onProgress={onVideoProgress}
        onEnd={onEnd}
        autoPlay={allowedToPlayVideo}
        showControls={allowedToPlayVideo || interacted}
        // eslint-disable-next-line react/jsx-no-bind
        getApi={(api) => (videoApiRef.current = api)}
      />
      <Decoration show={showOverlay} src={node.wantedMedia("IntroOverlay", "PreserveSizeWithAlpha")} />
      <TextContainer show={showOverlay}>
        <Subtitle>{node.wantedText("Subtitle")}</Subtitle>
        <Splitter />
        <Title>{node.wantedText("Title")}</Title>
        <VideoNotice show={showVideoNotice}>
          <StandardButtonText>{Strings.localized("VideoNotice")}</StandardButtonText>
          <StandardRightButtonIcon src={resource("images/Symbole_Arrow_01.svg")} />
        </VideoNotice>
      </TextContainer>
      <SkipButtonContainer show={!showVideoNotice}>
        <SkipButton onClick={onEnd}>
          <StandardButtonText>{Strings.localized("SkipButton")}</StandardButtonText>
          <StandardRightButtonIcon src={resource("images/Symbole_Arrow_01.svg")} />
        </SkipButton>
      </SkipButtonContainer>
      <Menu show={!showVideoNotice} />
    </Page>
  );
});

export default MainPage;
