import PropTypes from "prop-types";
import { forwardRef, memo } from "react";
import { MediaSrcPropType } from "../../../logic/prop-types";
import Video from "../video";
import MapContent from "./map-content";

/**
 * A map content sub-type that loads
 */
const MapVideoContent = memo(
  forwardRef(({ src, width, height, children, onLoad, ...rest }, ref) => {
    // Render with the size of the loaded image
    return (
      <MapContent ref={ref} width={width} height={height} {...rest}>
        <Video
          src={src}
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
          autoPlay
          loop
          scaling="fill"
          onLoad={onLoad}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {children}
        </div>
      </MapContent>
    );
  })
);

MapVideoContent.propTypes = {
  src: MediaSrcPropType,
  children: PropTypes.node,
  // We do not support auto-sizing for now!
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onLoad: PropTypes.func,
};

export default MapVideoContent;
