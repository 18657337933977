import _ from "lodash";
import FlagsActions from "../../actions/local/flags-actions";

const defaultState = {
  debug: localStorage.getItem("debug-mode") === "true" ? true : false,
  bounds: localStorage.getItem("bounds-mode") === "true" ? true : false,
  dataAutoReload: false,
  pathBar: false,
  modified: false,
};

const flagsReducer = function (state = defaultState, action) {
  switch (action.type) {
    case FlagsActions.TOGGLE_DEBUG_MODE: {
      const debug = !state.debug;
      localStorage.setItem("debug-mode", debug);
      return _.assign({}, state, { debug });
    }
    case FlagsActions.TOGGLE_BOUNDS_MODE: {
      const newBounds = !state.bounds;
      localStorage.setItem("bounds-mode", newBounds);
      return _.assign({}, state, { bounds: !state.bounds });
    }
    case FlagsActions.TOGGLE_DATA_AUTO_RELOAD:
      return _.assign({}, state, { dataAutoReload: !state.dataAutoReload });
    case FlagsActions.TOGGLE_PATH_BAR:
      return _.assign({}, state, { pathBar: !state.pathBar });
    case FlagsActions.ENABLE_EDITING: {
      return _.assign({}, state, { editing: true });
    }
    case FlagsActions.DISABLE_EDITING: {
      return _.assign({}, state, { editing: false });
    }
    case FlagsActions.UPDATE_MODIFIED: {
      return _.assign({}, state, { modified: action.modified });
    }
    default:
      return state;
  }
};

export default flagsReducer;
